import { Button, CircularProgress } from "@mui/material";
import React from "react";

function ActivateStudent(props) {
  const { loadingForm, setAction, blockStudent, student } = props;

  return (
    <form onSubmit={blockStudent}>
      <h1>Bloquer l&apos;étudiant:&nbsp;{student.user.name}</h1>
      <div className="row">
        {loadingForm ? (
          <CircularProgress size="35px" />
        ) : (
          <>
            <Button type="submit" variant="contained">
              Bloquer
            </Button>
            &nbsp;
            <Button
              type="button"
              onClick={() => {
                setAction("");
              }}
              variant="outlined"
            >
              Annuler
            </Button>
          </>
        )}
      </div>
    </form>
  );
}

export default ActivateStudent;
