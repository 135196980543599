import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../styles/superadmin/Navbar.module.css";

function Navbar(props) {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);

  return (
    <div className={styles.navbar}>
      <div className={styles.logo}>
        <img alt="school-hub logo" src={"/" + "./WhiteLogo.svg"} />
      </div>
      <div className={styles.links}>
        <button
          onClick={() => dispatch({ type: "USER_LOGOUT" })}
          className={styles.button}
        >
          Se déconnecter
        </button>
      </div>
    </div>
  );
}

export default Navbar;
