import { Button, CircularProgress } from "@mui/material";
import React from "react";

function EditDeposit(props) {
  const {
    editDeposit,
    setAction,
    deposit,
    setDeposit,
    resetDeposit,
    loadingForm,
  } = props;
  return (
    <form onSubmit={editDeposit}>
      <div className="labeledInput">
        <label>montant</label>
        <input
          className="defaultInput"
          type="number"
          step="any"
          min={1}
          required
          value={deposit.amount}
          onChange={(e) => setDeposit({ ...deposit, amount: e.target.value })}
        />
      </div>
      <br />
      <div className="labeledInput">
        <label>modalité de paiement</label>
        <input
          className="defaultInput"
          type="text"
          required
          value={deposit.method}
          onChange={(e) => setDeposit({ ...deposit, method: e.target.value })}
        />
      </div>
      <br />
      {loadingForm ? (
        <CircularProgress size="33px" />
      ) : (
        <div className="btnRow">
          <Button type="submit" variant="contained">
            modifier
          </Button>
          &nbsp;
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              setAction("");
              resetDeposit();
            }}
          >
            annuler
          </Button>
        </div>
      )}
    </form>
  );
}

export default EditDeposit;
