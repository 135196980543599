import React, { useEffect, useState } from "react";
import Layout from "./components/Layout";
import axios from "axios";
import { useSnackbar } from "notistack";
import { getError } from "../../config/config.js";
import {
  Button,
  CircularProgress,
  IconButton,
  Dialog,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SettingsIcon from "@mui/icons-material/Settings";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import styles from "../../styles/superadmin/Home.module.css";
import AddEstablishment from "./components/AddEstablishment";
import BlockEstablishment from "./components/BlockEstablishment";
import ActivateEstablishment from "./components/ActivateEstablishment";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import { Link, useParams, useNavigate } from "react-router-dom";
function Home(props) {
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.auth);
  const [establishments, setEstablishments] = useState([]);
  const [establishment, setEstablishment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingApi, setLoadingApi] = useState(false);
  const [action, setAction] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState({
    name: "",
    type: "",
    address: "",
    email: "",
    phone: "",
  });

  const resetEstablishment = () =>
    setFormData({
      name: "",
      type: "",
      address: "",
      email: "",
      phone: "",
    });

  const addEstablishment = async (e) => {
    setLoadingApi(true);
    e.preventDefault();
    try {
      const { data } = await axios.post("/establishment/add", formData);
      enqueueSnackbar(data.message, { variant: "success" });
      resetEstablishment();
      setAction("");
      setLoadingApi(false);
      fetchEstablishments();
    } catch (error) {
      setLoadingApi(false);
    }
  };

  const blockEstablishment = async () => {
    setLoadingApi(true);
    try {
      const { data } = await axios.post("/establishment/block", {
        id: establishment._id,
      });
      enqueueSnackbar(data.message, { variant: "success" });
      setAction("");
      setLoadingApi(false);
      fetchEstablishments();
    } catch (error) {
      setLoadingApi(false);
    }
  };

  const activateEstablishment = async () => {
    setLoadingApi(true);
    try {
      const { data } = await axios.post("/establishment/activate", {
        id: establishment._id,
      });
      enqueueSnackbar(data.message, { variant: "success" });
      setAction("");
      setLoadingApi(false);
      fetchEstablishments();
    } catch (error) {
      setLoadingApi(false);
    }
  };

  const fetchEstablishments = async () => {
    try {
      const { data } = await axios.get("/establishment/getAll");
      setEstablishments(data);
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEstablishments();
  }, []);

  return (
    <>
      <Dialog
        maxWidth="md"
        open={action !== ""}
        onClose={() => {
          setAction("");
          resetEstablishment();
        }}
        fullWidth
      >
        <div
          className={"modal small"}
          style={action === "ADD" ? { maxHeight: "100%", top: "10vh" } : null}
        >
          {action === "ADD" ? (
            <AddEstablishment
              formData={formData}
              setFormData={setFormData}
              addEstablishment={addEstablishment}
              loadingApi={loadingApi}
              resetEstablishment={resetEstablishment}
              setAction={setAction}
            />
          ) : action === "BLOCK" ? (
            <BlockEstablishment
              blockEstablishment={blockEstablishment}
              establishment={establishment}
              setAction={setAction}
              loadingApi={loadingApi}
            />
          ) : action === "ACTIVATE" ? (
            <ActivateEstablishment
              activateEstablishment={activateEstablishment}
              establishment={establishment}
              setAction={setAction}
              loadingApi={loadingApi}
            />
          ) : null}
        </div>
      </Dialog>
      <Layout>
        {loading ? (
          <div className="spinner">
            <CircularProgress />
          </div>
        ) : (
          <div className={styles.container}>
            {establishments.map((establishment) => {
              return (
                <div className={styles.bloc} key={establishment._id}>
                  <div className={styles.establishment}>
                    <div className={styles.controls}>
                      <Tooltip title="modifier">
                        <Link to={`/establishment/${establishment._id}`}>
                          <IconButton>
                            <SettingsIcon color="primary" />
                          </IconButton>
                        </Link>
                      </Tooltip>
                      &nbsp;
                      {establishment.isActive ? (
                        <Tooltip title="désactiver">
                          <IconButton
                            onClick={() => {
                              setEstablishment(establishment);
                              setAction("BLOCK");
                            }}
                          >
                            <BlockIcon color="fourth" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="activer">
                          <IconButton
                            onClick={() => {
                              setEstablishment(establishment);
                              setAction("ACTIVATE");
                            }}
                          >
                            <CheckIcon color="success" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                    <p>{establishment.name}</p>
                    <p>{establishment.type}</p>
                    <p>{establishment.address}</p>
                    <p>{establishment.email}</p>
                    <p>{establishment.phone}</p>
                    <br />
                    <Button
                      onClick={() => {
                        //const establishmentIdToConnect = establishment._id;
                        try {
                          dispatch({
                            type: "USER_LOGIN",
                            payload: {
                              ...userInfo,
                              establishment: establishment,
                            },
                          });

                          window.location.href = `/dashboard`;
                        } catch (error) {
                          console.error(
                            "Erreur lors de la connexion à l'établissement :",
                            error
                          );
                        }
                      }}
                      color="primary"
                      variant="contained"
                      sx={{
                        width: "100%",
                        borderRadius: "7px",
                        alignSelf: "center",
                      }}
                    >
                      se connecter
                    </Button>
                  </div>
                </div>
              );
            })}
            <div className={styles.bloc}>
              <div className={styles.add}>
                <Tooltip title="nouveau établissement">
                  <IconButton onClick={() => setAction("ADD")}>
                    <AddCircleIcon
                      sx={{
                        fontSize: "50px",
                        cursor: "pointer",
                        color: "#3f51b5",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
}

export default Home;
