import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

function EditClassroom(props) {
  const {
    formData,
    setFormData,
    loadingForm,
    setAction,
    editClassroom,
    establishment,
    season,
  } = props;

  return (
    <>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          color: "#283487",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        Modifier la classe
      </Typography>
      <form onSubmit={editClassroom}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Niveau</InputLabel>

              <Select
                value={formData.level}
                type="text"
                label="Niveau"
                variant="outlined"
                fullWidth
                required
                onChange={(e) => {
                  setFormData({ ...formData, level: e.target.value });
                }}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "5px",
                    border: "0.5px solid #283487",
                  },
                }}
              >
                {establishment.type === "ecole-primaire"
                  ? [
                      <MenuItem key="1ère" value="1ère">
                        1ère
                      </MenuItem>,
                      <MenuItem key="2ème" value="2ème">
                        2ème
                      </MenuItem>,
                      <MenuItem key="3ème" value="3ème">
                        3ème
                      </MenuItem>,
                      <MenuItem key="4ème" value="4ème">
                        4ème
                      </MenuItem>,
                      <MenuItem key="5ème" value="5ème">
                        5ème
                      </MenuItem>,
                      <MenuItem key="6ème" value="6ème">
                        6ème
                      </MenuItem>,
                    ]
                  : establishment.type === "collège"
                  ? [
                      <MenuItem key="7ème" value="7ème">
                        7ème
                      </MenuItem>,
                      <MenuItem key="8ème" value="8ème">
                        8ème
                      </MenuItem>,
                      <MenuItem key="9ème" value="9ème">
                        9ème
                      </MenuItem>,
                    ]
                  : establishment.type === "ecole-secondaire"
                  ? [
                      <MenuItem key="1ère" value="1ère">
                        1ère
                      </MenuItem>,
                      <MenuItem key="2ème" value="2ème">
                        2ème
                      </MenuItem>,
                      <MenuItem key="3ème" value="3ème">
                        3ème
                      </MenuItem>,
                      <MenuItem key="4ème" value="4ème">
                        4ème
                      </MenuItem>,
                    ]
                  : null}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Groupe"
              variant="outlined"
              fullWidth
              required
              value={formData.group}
              onChange={(e) => {
                setFormData({ ...formData, group: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Capacité"
              variant="outlined"
              type="number"
              fullWidth
              required
              value={formData.capacity}
              onChange={(e) => {
                setFormData({ ...formData, capacity: e.target.value });
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Frais mensuelle (DT)"
              variant="outlined"
              type="number"
              fullWidth
              min={0}
              required
              value={formData.costs}
              onChange={(e) => {
                setFormData({ ...formData, costs: e.target.value });
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Grid item>
              <Button type="submit" variant="contained" color="primary">
                {loadingForm ? (
                  <CircularProgress size="20px" sx={{ color: "#fff" }} />
                ) : (
                  "Modifier"
                )}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  setFormData({
                    level: "",
                    group: "",
                    capacity: 0,
                    costs: 0,
                    establishment: establishment._id,
                    season: season,
                  });
                  setAction("");
                }}
                variant="outlined"
                color="primary"
              >
                Annuler
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default EditClassroom;
