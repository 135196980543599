import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { CircularProgress, IconButton, Dialog, Tooltip } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import styles from "../../../../../styles/admin/StudentCard.module.css";
import Layout from "../../../components/Layout";
import Menu from "../../components/Menu";
import AddDeposit from "./components/AddDeposit";
import AddRow from "./components/AddRow";
import DeleteDeposit from "./components/DeleteDeposit";
import EditDeposit from "./components/EditDeposit";
import Receipt from "./components/Receipt";

function StudentCard(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const printRef = useRef();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentSeason } = useSelector((state) => state.dashboard);
  const [student, setStudent] = useState({});
  const [deposits, setDeposits] = useState([]);
  const [loadingCard, setLoadingCard] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingDeposits, setLoadingDeposits] = useState(true);
  const [label, setLabel] = useState("");
  const [action, setAction] = useState("");

  const [deposit, setDeposit] = useState({
    establishment: userInfo.establishment._id,
    season: "",
    admin: userInfo._id,
    student: id,
    label: "",
    monthIndex: 0,
    amount: 0,
    method: "",
    multiple: 0,
  });

  const addRow = (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      setDeposits([
        ...deposits,
        {
          label: label,
          deposits: [
            {
              monthIndex: 0,
            },
            {
              monthIndex: 1,
            },
            {
              monthIndex: 2,
            },
            {
              monthIndex: 3,
            },
            {
              monthIndex: 4,
            },
            {
              monthIndex: 5,
            },
            {
              monthIndex: 6,
            },
            {
              monthIndex: 7,
            },
            {
              monthIndex: 8,
            },
            {
              monthIndex: 9,
            },
            {
              monthIndex: 10,
            },
            {
              monthIndex: 11,
            },
          ],
        },
      ]);
      setAction("");
      setLabel("");
      setLoadingForm(false);
    } catch (error) {
      console.log(error);
    }
  };

  const resetDeposit = () => {
    setDeposit({
      establishment: userInfo.establishment._id,
      season: currentSeason._id,
      admin: userInfo._id,
      student: id,
      label: "",
      monthIndex: 0,
      amount: 0,
      method: "",
      multiple: 0,
    });
  };

  const addDeposit = async (e) => {
    e.preventDefault();
    var exists = false;
    const row = deposits.find((d) => d.label === deposit.label);
    for (let i = 0; i < row.deposits.length; i++) {
      if (
        Number(deposit.monthIndex) < Number(row.deposits[i].monthIndex) &&
        Number(row.deposits[i].monthIndex) <
          Number(deposit.multiple) + Number(deposit.monthIndex)
      ) {
        exists = true;
      }
    }
    try {
      if (Number(deposit.multiple) + Number(deposit.monthIndex) > 12) {
        return enqueueSnackbar("ne dépasser pas les mois disponible !", {
          variant: "warning",
        });
      }
      if (row.length > 0 && exists) {
        return enqueueSnackbar("respecter les mois déjà configurer !", {
          variant: "warning",
        });
      }
      const { data } = await axios.post("/deposit/add", deposit);
      enqueueSnackbar(data.message, { variant: "success" });
      setAction("");
      resetDeposit();
      fetchDeposits();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const editDeposit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put("/deposit/edit", deposit);
      enqueueSnackbar(data.message, { variant: "success" });
      setAction("");
      resetDeposit();
      fetchDeposits();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const deleteDeposit = async () => {
    try {
      const { data } = await axios.post(`/deposit/remove/${deposit._id}`);
      enqueueSnackbar(data.message, { variant: "success" });
      setAction("");
      resetDeposit();
      fetchDeposits();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const fetchStudent = async () => {
    try {
      const { data } = await axios.get(`/student/getbyid/${id}`);
      setStudent(data);
      setLoadingCard(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setLoadingCard(false);
    }
  };

  const fetchDeposits = async () => {
    try {
      const { data } = await axios.get(
        `/deposit/getbystudent/${userInfo?.establishment._id}/${currentSeason?._id}/${id}`
      );
      setDeposits(data);
      setLoadingDeposits(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setLoadingDeposits(false);
    }
  };

  const renderDeposits = (row) => {
    var block = [];
    for (let i = 0; i < 12; i++) {
      if (row.deposits.find((d) => d.monthIndex === i)?.amount) {
        block = [
          ...block,
          <Tooltip key={i} title="imprimer reçu">
            <td
              onClick={() => {
                setDeposit(row.deposits.find((d) => d.monthIndex === i));
                setAction("SHOW_RECEIPT");
              }}
              className={styles.cell}
            >
              <div>
                {row.deposits.find((d) => d.monthIndex === i).amount} DT
              </div>
              <div>{row.deposits.find((d) => d.monthIndex === i).method}</div>
              <div className={styles.edit}>
                <div className={styles.icon}>
                  <EditIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeposit(row.deposits.find((d) => d.monthIndex === i));
                      setAction("EDIT_DEPOSIT");
                    }}
                    sx={{ width: "15px", height: "15px", color: "orange" }}
                  />
                </div>
              </div>
              <div className={styles.delete}>
                <div className={styles.icon}>
                  <DeleteOutlineIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeposit(row.deposits.find((d) => d.monthIndex === i));
                      setAction("DELETE_DEPOSIT");
                    }}
                    sx={{ width: "15px", height: "15px", color: "red" }}
                  />
                </div>
              </div>
            </td>
          </Tooltip>,
        ];
      } else {
        block = [
          ...block,
          <td key={i} value={i}>
            <div className={styles.cellContainer}>
              <div className={styles.add}>
                <div className={styles.icon}>
                  <AddCircleOutlineIcon
                    onClick={() => {
                      setDeposit({
                        ...deposit,
                        season: currentSeason._id,
                        label: row.label,
                        monthIndex: i,
                      });
                      setAction("ADD_DEPOSIT");
                    }}
                    sx={{ width: "15px", height: "15px", color: "green" }}
                  />
                </div>
              </div>
            </div>
          </td>,
        ];
      }
    }
    return block;
  };

  useEffect(() => {
    fetchStudent();
  }, []);

  useEffect(() => {
    fetchDeposits();
  }, [currentSeason]);

  return (
    <>
      <Dialog
        open={action !== ""}
        onClose={() => {
          setAction("");
          setLabel("");
          resetDeposit();
        }}
      >
        <div
          className={action === "SHOW_RECEIPT" ? "modal medium" : "modal small"}
          style={
            action === "ADD_DEPOSIT" || action === "EDIT_DEPOSIT"
              ? { maxHeight: "100%" }
              : null
          }
        >
          {action === "ADD_ROW" ? (
            <AddRow
              setAction={setAction}
              label={label}
              setLabel={setLabel}
              addRow={addRow}
              loadingForm={loadingForm}
            />
          ) : action === "ADD_DEPOSIT" ? (
            <AddDeposit
              setAction={setAction}
              deposit={deposit}
              setDeposit={setDeposit}
              resetDeposit={resetDeposit}
              addDeposit={addDeposit}
              loadingForm={loadingForm}
            />
          ) : action === "EDIT_DEPOSIT" ? (
            <EditDeposit
              setAction={setAction}
              deposit={deposit}
              setDeposit={setDeposit}
              resetDeposit={resetDeposit}
              editDeposit={editDeposit}
              loadingForm={loadingForm}
            />
          ) : action === "DELETE_DEPOSIT" ? (
            <DeleteDeposit
              setAction={setAction}
              deposit={deposit}
              setDeposit={setDeposit}
              resetDeposit={resetDeposit}
              deleteDeposit={deleteDeposit}
              loadingForm={loadingForm}
            />
          ) : action === "SHOW_RECEIPT" ? (
            <Receipt
              setAction={setAction}
              deposit={deposit}
              resetDeposit={resetDeposit}
              student={student}
            />
          ) : null}
        </div>
      </Dialog>
      <Layout>
        <Menu />
        <div className={styles.container}>
          {loadingCard ? (
            <div className="spinner">
              <CircularProgress size="30px" />
            </div>
          ) : (
            <section ref={printRef} className={styles.card}>
              <div className={`${styles.print} dontprint`}>
                <ReactToPrint
                  trigger={() => (
                    <LocalPrintshopIcon
                      onClick={() => window.print()}
                      style={{ cursor: "pointer" }}
                      color="primary"
                    />
                  )}
                  content={() => printRef.current}
                />
              </div>
              <div
                style={{ justifyContent: "flex-start" }}
                className={styles.row}
              >
                <img
                  style={{ cursor: "default" }}
                  alt="avatar"
                  src={
                    student.user.avatar !== ""
                      ? student.user.avatar
                      : "/" + "./icons/profile2.svg"
                  }
                />
              </div>
              <div className={styles.row}>
                <div className={styles.col50}>
                  <h1>
                    nom et prénom:&nbsp;<a>{student.user.name}</a>
                  </h1>
                  <h1>
                    date de naissance:&nbsp;<a>{student.birthday}</a>
                  </h1>
                  <h1>
                    sexe:&nbsp;<a>{student.sex}</a>
                  </h1>
                  <h1>
                    adresse:&nbsp;<a>{student.address}</a>
                  </h1>
                </div>
                <div className={styles.col50}>
                  <h1>
                    classe:&nbsp;
                    <a>
                      {student.classroom.level + " " + student.classroom.group}
                    </a>
                  </h1>
                  <h1>
                    inscri le:&nbsp;
                    <a>{moment(student.createdAt).format("yyyy-MM-DD")}</a>
                  </h1>
                  <h1>
                    tarif scolarité par mois:&nbsp;
                    <a>{student.classroom.costs + " dt"}</a>
                  </h1>
                </div>
              </div>
              <div className={styles.hr} />
              <div className={styles.row}>
                <div className={styles.col50}>
                  <h1>
                    nom père:&nbsp;<a>{student.fatherName}</a>
                  </h1>
                  <h1>
                    profession père:&nbsp;<a>{student.fatherProfession}</a>
                  </h1>
                  <h1>
                    téléphone père:&nbsp;<a>{student.fatherPhone}</a>
                  </h1>
                  <h1>
                    nom mère:&nbsp;<a>{student.motherName}</a>
                  </h1>
                  <h1>
                    profession mère:&nbsp;<a>{student.motherProfession}</a>
                  </h1>
                  <h1>
                    téléphone mère:&nbsp;<a>{student.motherPhone}</a>
                  </h1>
                </div>
                <div className={styles.col50}>
                  <h1>
                    nb.frères et soeurs:&nbsp;<a>{student.siblings}</a>
                  </h1>
                  <h1>
                    nb.frères et soeurs etudiants:&nbsp;
                    <a>{student.siblingsStudents}</a>
                  </h1>
                  <h1>
                    situation familiale:&nbsp;<a>{student.maritalStatus}</a>
                  </h1>
                  <h1>
                    nb.frères et soeurs:&nbsp;
                    {student.authorizedCompany.map((name, i) => {
                      return (
                        <>
                          <a key={i}>{name}</a>,&nbsp;
                        </>
                      );
                    })}
                  </h1>
                  <h1>
                    orphelin:&nbsp;<a>{student.orphin ? "oui" : "non"}</a>
                  </h1>
                </div>
              </div>
            </section>
          )}
          <h2>Paiements</h2>
          {loadingDeposits ? (
            <div className="spinner">
              <CircularProgress size="30px" />
            </div>
          ) : (
            <>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>jan</th>
                    <th>fév</th>
                    <th>mar</th>
                    <th>avr</th>
                    <th>mai</th>
                    <th>juin</th>
                    <th>juil</th>
                    <th>août</th>
                    <th>sept</th>
                    <th>oct</th>
                    <th>nov</th>
                    <th>déc</th>
                  </tr>
                </thead>
                <tbody>
                  {deposits.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td
                          style={{ backgroundColor: "#283487", color: "#fff" }}
                        >
                          {row.label}
                        </td>
                        {renderDeposits(row)}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <IconButton color="primary" onClick={() => setAction("ADD_ROW")}>
                <AddCircleOutlineIcon />
              </IconButton>
            </>
          )}
        </div>
      </Layout>
    </>
  );
}

export default StudentCard;
