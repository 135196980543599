import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import {
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Dialog,
  Pagination,
  Select,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getError } from "../../../config/config";
import styles from "../../../styles/admin/Dashboard.module.css";
import Layout from "../components/Layout";
import AddRoom from "./components/AddRoom";
import EditRoom from "./components/EditRoom";
import SettingsIcon from "@mui/icons-material/Settings";

function Rooms(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useSelector((state) => state.auth);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [type, setType] = useState("");
  const [spots, setSpots] = useState(0);
  const { currentSeason } = useSelector((state) => state.dashboard);

  const [formData, setFormData] = useState({
    name: "",
    type: "",
    spots: "",
    available: true,
    establishment: userInfo.establishment._id,
  });
  const [rooms, setRooms] = useState([]);
  const [room, setRoom] = useState(null);
  const [action, setAction] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);

  const fetchRooms = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(`/room/get`, {
        id: userInfo.establishment._id,
        page: page,
        type: type,
        spots: spots,
      });
      setRooms(data.rooms);
      setCount(data.count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const editRoom = async (e) => {
    setLoadingForm(true);
    e.preventDefault();
    try {
      const { data } = await axios.put("/room/edit", formData);
      enqueueSnackbar(data.message, { variant: "success" });
      setFormData({
        name: "",
        type: "",
        spots: "",
        available: true,
        establishment: userInfo.establishment._id,
      });
      fetchRooms();
      setLoadingForm(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  const addRoom = async (e) => {
    setLoadingForm(true);
    e.preventDefault();
    try {
      const { data } = await axios.post("/room/add", formData);
      enqueueSnackbar(data.message, { variant: "success" });
      setFormData({
        name: "",
        type: "",
        spots: "",
        available: true,
        establishment: userInfo.establishment._id,
      });
      fetchRooms();
      setLoadingForm(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  const resetRoom = () => {
    setFormData({
      name: "",
      type: "",
      spots: "",
      available: true,
      establishment: userInfo.establishment._id,
    });
  };

  useEffect(() => {
    if (userInfo && currentSeason) fetchRooms();
  }, [userInfo, page, type, spots, currentSeason]);

  return (
    <Layout>
      <Dialog
        open={action !== ""}
        onClose={() => {
          setAction("");
          resetRoom();
        }}
      >
        <div
          style={{ top: "10vh", maxHeight: "100%" }}
          className={"modal small"}
        >
          {action === "ADD" ? (
            <AddRoom
              loadingForm={loadingForm}
              formData={formData}
              setFormData={setFormData}
              setAction={setAction}
              addRoom={addRoom}
              establishment={userInfo.establishment}
              resetRoom={resetRoom}
            />
          ) : action === "EDIT" ? (
            <EditRoom
              loadingForm={loadingForm}
              formData={formData}
              setFormData={setFormData}
              setAction={setAction}
              editRoom={editRoom}
              room={room}
              establishment={userInfo.establishment}
              resetRoom={resetRoom}
            />
          ) : null}
        </div>
      </Dialog>
      <div className={styles.container}>
        <section className={styles.header}>
          <h1>Liste des salles</h1>
          {currentSeason && (
            <Button
              onClick={() => {
                setAction("ADD");
              }}
              variant="contained"
            >
              ajouter
            </Button>
          )}
        </section>
        {currentSeason && (
          <section className={styles.filter}>
            <div
              style={{ width: "20%", marginRight: "10px" }}
              className="labeledInput"
            >
              <label>Type</label>
              <Select
                value={type}
                required
                onChange={(e) => {
                  setType(e.target.value);
                }}
                sx={{
                  backgroundColor: "rgba(40, 52, 135, 0.2)",
                  padding: "0px",
                  width: "100%",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "5px !important",
                    border: "0.5px solid transparent !important",
                  },
                }}
                size="small"
              >
                <MenuItem value="TP">TP</MenuItem>
                <MenuItem value="SALLE">SALLE</MenuItem>
                <MenuItem value="AMPHI">AMPHI</MenuItem>
              </Select>
            </div>
            <div
              style={{ width: "20%", marginRight: "10px" }}
              className="labeledInput"
            >
              <label>Capacité</label>
              <input
                type="number"
                min={0}
                value={spots}
                onChange={(e) => setSpots(e.target.value)}
                className="grayInput"
              />
            </div>
            {type !== "" || spots !== 0 ? (
              <Button
                onClick={() => {
                  setType("");
                  setSpots(0);
                }}
                size="large"
                variant="outlined"
              >
                <RotateLeftIcon />
              </Button>
            ) : null}
          </section>
        )}
        {loading ? (
          <div className="spinner">
            <CircularProgress />
          </div>
        ) : (
          <div className={styles.tableContainer}>
            <table className="defaultTable">
              <thead>
                <tr>
                  <th>Nom </th>
                  <th>Type</th>
                  <th>Capacité</th>
                  <th>Disponibilité</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {rooms.map((room) => {
                  {
                    return (
                      <tr key={room._id}>
                        <td>{room.name}</td>
                        <td>{room.type}</td>
                        <td>{room.spots}</td>
                        <td>
                          <span
                            className={
                              room.available ? "tag success" : "tag error"
                            }
                          >
                            {room.available ? "oui" : "non"}
                          </span>
                        </td>
                        <td>
                          <IconButton
                            onClick={() => {
                              setRoom(room);
                              setFormData(room);
                              setAction("EDIT");
                            }}
                            color="primary"
                          >
                            <SettingsIcon />
                          </IconButton>
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </div>
        )}
        {currentSeason && (
          <section className={styles.pagination}>
            <Pagination
              onChange={(e, page) => setPage(page)}
              page={page}
              count={count}
            />
          </section>
        )}
      </div>
    </Layout>
  );
}

export default Rooms;
