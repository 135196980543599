import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../styles/teacher/Sidebar.module.css";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import LineAxisIcon from "@mui/icons-material/LineAxis";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { Link, useLocation } from "react-router-dom";
import { io } from "socket.io-client";
import { Badge } from "@mui/material";
import { useSnackbar } from "notistack";
import axios from "axios";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { getError } from "../../../config/config";
import LogoutIcon from "@mui/icons-material/Logout";

function Sidebar(props) {
  const { sidemenu, notifications } = useSelector((state) => state.dashboard);
  const { enqueueSnackbar } = useSnackbar();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);

  const fetchNotifications = async () => {
    try {
      const { data } = await axios.get(`/notification/get/${userInfo._id}`);
      dispatch({ type: "UPDATE_NOTIFICATIONS", payload: data.count });
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    if (userInfo) {
      if (
        userInfo?.user.role === "STUDENT" ||
        userInfo?.user.role === "TEACHER"
      ) {
        var socket = io.connect(process.env.REACT_APP_SERVER_URL);
        // JOIN-ROOM
        socket.emit("join-room", userInfo.user._id);
        // INCOMING-MESSAGE
        socket.on("update-chat", () => {
          // fetchUnreadCountPatient();
        });
        // fetchUnreadCountPatient();
      } else if (userInfo?.user.role === "ADMIN") {
        var socket = io.connect(process.env.REACT_APP_SERVER_URL);
        // JOIN-ROOM
        socket.emit("join-room", userInfo.establishment._id);
        // INCOMING-MESSAGE
        socket.on("update-chat", () => {
          // fetchUnreadCount();
        });
        socket.on("update-demands", () => {
          // fetchCancelsCount();
          // fetchDemandsCount();
        });
        // fetchUnreadCount();
      }
    }
  }, [userInfo]);

  return (
    <div
      className={
        sidemenu ? styles.sidebar : `${styles.sidebar} ${styles.closed}`
      }
    >
      <div className={styles.header}>
        <h1></h1>
        <div className={styles.icon}>
          {sidemenu ? (
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() =>
                dispatch({ type: "TOGGLE_SIDEMENU", payload: false })
              }
            />
          ) : (
            <MenuIcon
              style={{ cursor: "pointer" }}
              onClick={() =>
                dispatch({ type: "TOGGLE_SIDEMENU", payload: true })
              }
            />
          )}
        </div>
        <div className={styles.hr} />
      </div>
      <Link to="/gestion-scolaire">
        <li className={pathname === "/gestion-scolaire" ? styles.active : null}>
          <TextSnippetIcon />
          {sidemenu ? "Gestion-Scolaire" : null}
        </li>
      </Link>
      <Link to="/finance">
        <li className={pathname === "/finance" ? styles.active : null}>
          <AccountBalanceWalletIcon />
          {sidemenu ? "Gestion financière" : null}
        </li>
      </Link>
      <Link to="/notifications">
        <li className={pathname === "/notifications" ? styles.active : null}>
          <Badge
            invisible={notifications === 0}
            badgeContent={notifications}
            color="secondary"
          >
            <NotificationsActiveIcon />
          </Badge>
          &nbsp;&nbsp;&nbsp;{sidemenu ? "notifications" : null}
        </li>
      </Link>
      <Link to="/messenger">
        <li className={pathname === "/messenger" ? styles.active : null}>
          <QuestionAnswerIcon />
          &nbsp;&nbsp;&nbsp;{sidemenu ? "Messenger" : null}
        </li>
      </Link>
      <div
        onClick={() => {
          dispatch({ type: "USER_LOGOUT" });
        }}
        className={styles.button}
      >
        <LogoutIcon />
      </div>
    </div>
  );
}

export default Sidebar;
