import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { CircularProgress, IconButton, Dialog, Tooltip } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import styles from "../../../styles/admin/TeacherCard.module.css";
import Layout from "../components/Layout";
import AddWithdrawal from "./components/AddWithdrawal";
import AddRow from "./components/AddRow";
import DeleteWithdrawal from "./components/DeleteWithdrawal";
import EditWithdrawal from "./components/EditWithdrawal";
import Receipt from "./components/Receipt";

function TeacherCard(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const printRef = useRef();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentSeason } = useSelector((state) => state.dashboard);
  const [teacher, setTeacher] = useState({});
  const [withdrawals, setWithdrawals] = useState([]);
  const [loadingCard, setLoadingCard] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingWithdrawals, setLoadingWithdrawals] = useState(true);
  const [label, setLabel] = useState("");
  const [action, setAction] = useState("");

  const [withdrawal, setWithdrawal] = useState({
    establishment: userInfo.establishment._id,
    season: "",
    admin: userInfo._id,
    teacher: id,
    label: "",
    monthIndex: 0,
    amount: 0,
    method: "",
    multiple: 0,
  });

  const addRow = (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      setWithdrawals([
        ...withdrawals,
        {
          label: label,
          withdrawals: [
            {
              monthIndex: 0,
            },
            {
              monthIndex: 1,
            },
            {
              monthIndex: 2,
            },
            {
              monthIndex: 3,
            },
            {
              monthIndex: 4,
            },
            {
              monthIndex: 5,
            },
            {
              monthIndex: 6,
            },
            {
              monthIndex: 7,
            },
            {
              monthIndex: 8,
            },
            {
              monthIndex: 9,
            },
            {
              monthIndex: 10,
            },
            {
              monthIndex: 11,
            },
          ],
        },
      ]);
      setAction("");
      setLabel("");
      setLoadingForm(false);
    } catch (error) {
      console.log(error);
    }
  };

  const resetWithdrawal = () => {
    setWithdrawal({
      establishment: userInfo.establishment._id,
      season: currentSeason._id,
      admin: userInfo._id,
      teacher: id,
      label: "",
      monthIndex: 0,
      amount: 0,
      method: "",
      multiple: 0,
    });
  };

  const addWithdrawal = async (e) => {
    e.preventDefault();
    var exists = false;
    const row = withdrawals.find((w) => w.label === withdrawal.label);
    for (let i = 0; i < row.withdrawals.length; i++) {
      if (
        Number(withdrawal.monthIndex) < Number(row.withdrawals[i].monthIndex) &&
        Number(row.withdrawals[i].monthIndex) <
          Number(withdrawal.multiple) + Number(withdrawal.monthIndex)
      ) {
        exists = true;
      }
    }
    try {
      if (Number(withdrawal.multiple) + Number(withdrawal.monthIndex) > 12) {
        return enqueueSnackbar("ne dépasser pas les mois disponible !", {
          variant: "warning",
        });
      }
      if (row.length > 0 && exists) {
        return enqueueSnackbar("respecter les mois déjà configurer !", {
          variant: "warning",
        });
      }
      const { data } = await axios.post("/withdrawal/add", withdrawal);
      enqueueSnackbar(data.message, { variant: "success" });
      setAction("");
      resetWithdrawal();
      fetchWithdrawals();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const editWithdrawal = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put("/withdrawal/edit", withdrawal);
      enqueueSnackbar(data.message, { variant: "success" });
      setAction("");
      resetWithdrawal();
      fetchWithdrawals();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const deleteWithdrawal = async () => {
    try {
      const { data } = await axios.post(`/withdrawal/remove/${withdrawal._id}`);
      enqueueSnackbar(data.message, { variant: "success" });
      setAction("");
      resetWithdrawal();
      fetchWithdrawals();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const fetchTeacher = async () => {
    try {
      const { data } = await axios.get(`/teacher/getbyid/${id}`);
      setTeacher(data);
      setLoadingCard(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setLoadingCard(false);
    }
  };

  const fetchWithdrawals = async () => {
    try {
      const { data } = await axios.get(
        `/withdrawal/getbyteacher/${userInfo?.establishment._id}/${currentSeason?._id}/${id}`
      );
      setWithdrawals(data);
      setLoadingWithdrawals(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setLoadingWithdrawals(false);
    }
  };

  const renderWithdrawals = (row) => {
    var block = [];
    for (let i = 0; i < 12; i++) {
      if (row.withdrawals.find((w) => w.monthIndex === i)?.amount) {
        block = [
          ...block,
          <Tooltip key={i} title="imprimer reçu">
            <td
              onClick={() => {
                setWithdrawal(row.withdrawals.find((w) => w.monthIndex === i));
                setAction("SHOW_RECEIPT");
              }}
              className={styles.cell}
            >
              <div>
                {row.withdrawals.find((w) => w.monthIndex === i).amount} DT
              </div>
              <div>
                {row.withdrawals.find((w) => w.monthIndex === i).method}
              </div>
              <div className={styles.edit}>
                <div className={styles.icon}>
                  <EditIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setWithdrawal(
                        row.withdrawals.find((w) => w.monthIndex === i)
                      );
                      setAction("EDIT_WITHDRAWAL");
                    }}
                    sx={{ width: "15px", height: "15px", color: "orange" }}
                  />
                </div>
              </div>
              <div className={styles.delete}>
                <div className={styles.icon}>
                  <DeleteOutlineIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      setWithdrawal(
                        row.withdrawals.find((w) => w.monthIndex === i)
                      );
                      setAction("DELETE_WITHDRAWAL");
                    }}
                    sx={{ width: "15px", height: "15px", color: "red" }}
                  />
                </div>
              </div>
            </td>
          </Tooltip>,
        ];
      } else {
        block = [
          ...block,
          <td key={i} value={i}>
            <div className={styles.cellContainer}>
              <div className={styles.add}>
                <div className={styles.icon}>
                  <AddCircleOutlineIcon
                    onClick={() => {
                      setWithdrawal({
                        ...withdrawal,
                        season: currentSeason._id,
                        label: row.label,
                        monthIndex: i,
                      });
                      setAction("ADD_WITHDRAWAL");
                    }}
                    sx={{ width: "15px", height: "15px", color: "green" }}
                  />
                </div>
              </div>
            </div>
          </td>,
        ];
      }
    }
    return block;
  };

  useEffect(() => {
    fetchTeacher();
  }, []);

  useEffect(() => {
    fetchWithdrawals();
  }, [currentSeason]);

  return (
    <>
      <Dialog
        open={action !== ""}
        onClose={() => {
          setAction("");
          setLabel("");
          resetWithdrawal();
        }}
      >
        <div
          className={action === "SHOW_RECEIPT" ? "modal medium" : "modal small"}
          style={
            action === "ADD_WITHDRAWAL" || action === "EDIT_WITHDRAWAL"
              ? { maxHeight: "100%" }
              : null
          }
        >
          {action === "ADD_ROW" ? (
            <AddRow
              setAction={setAction}
              label={label}
              setLabel={setLabel}
              addRow={addRow}
              loadingForm={loadingForm}
            />
          ) : action === "ADD_WITHDRAWAL" ? (
            <AddWithdrawal
              setAction={setAction}
              withdrawal={withdrawal}
              setWithdrawal={setWithdrawal}
              resetWithdrawal={resetWithdrawal}
              addWithdrawal={addWithdrawal}
              loadingForm={loadingForm}
            />
          ) : action === "EDIT_WITHDRAWAL" ? (
            <EditWithdrawal
              setAction={setAction}
              withdrawal={withdrawal}
              setWithdrawal={setWithdrawal}
              resetWithdrawal={resetWithdrawal}
              editWithdrawal={editWithdrawal}
              loadingForm={loadingForm}
            />
          ) : action === "DELETE_WITHDRAWAL" ? (
            <DeleteWithdrawal
              setAction={setAction}
              withdrawal={withdrawal}
              setWithdrawal={setWithdrawal}
              resetWithdrawal={resetWithdrawal}
              deleteWithdrawal={deleteWithdrawal}
              loadingForm={loadingForm}
            />
          ) : action === "SHOW_RECEIPT" ? (
            <Receipt
              setAction={setAction}
              setWithdrawal={setWithdrawal}
              withdrawal={withdrawal}
              resetWithdrawal={resetWithdrawal}
              teacher={teacher}
            />
          ) : null}
        </div>
      </Dialog>
      <Layout>
        <div className={styles.container}>
          {loadingCard ? (
            <div className="spinner">
              <CircularProgress size="30px" />
            </div>
          ) : (
            <section ref={printRef} className={styles.card}>
              <div className={`${styles.print} dontprint`}>
                <IconButton sx={{ color: "#ff9484" }}>
                  <ReactToPrint
                    trigger={() => (
                      <LocalPrintshopIcon
                        onClick={() => window.print()}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                    content={() => printRef.current}
                  />
                </IconButton>
              </div>
              <div
                style={{ justifyContent: "flex-start" }}
                className={styles.row}
              >
                <img
                  style={{ cursor: "default" }}
                  alt="avatar"
                  src={
                    teacher.user.avatar !== ""
                      ? teacher.user.avatar
                      : "/" + "./icons/profile2.svg"
                  }
                />
              </div>
              <div className={styles.row}>
                <div className={styles.col50}>
                  <h1>
                    Nom et prénom:&nbsp;<a>{teacher.user.name}</a>
                  </h1>
                  <h1>
                    Email:&nbsp;<a>{teacher.user.email}</a>
                  </h1>
                  <h1>
                    Téléphone:&nbsp;<a>{teacher.user.phone}</a>
                  </h1>
                  <h1>
                    Sexe:&nbsp;<a>{teacher.sex}</a>
                  </h1>
                </div>
                <div className={styles.col50}>
                  <h1>
                    Date de prise de mission :&nbsp;
                    <a>{moment(teacher.createdAt).format("YYYY-MM-DD")}</a>
                  </h1>
                  <h1>
                    Taux horaire :&nbsp;<a>{teacher.hourlyRate} DT</a>
                  </h1>
                </div>
              </div>
            </section>
          )}
          <h2>Prestation</h2>
          {loadingWithdrawals ? (
            <div className="spinner">
              <CircularProgress size="30px" />
            </div>
          ) : (
            <>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>jan</th>
                    <th>fév</th>
                    <th>mar</th>
                    <th>avr</th>
                    <th>mai</th>
                    <th>juin</th>
                    <th>juil</th>
                    <th>août</th>
                    <th>sept</th>
                    <th>oct</th>
                    <th>nov</th>
                    <th>déc</th>
                  </tr>
                </thead>
                <tbody>
                  {withdrawals.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td
                          style={{
                            backgroundColor: "#fdcf1b",
                            color: "#000000",
                          }}
                        >
                          {row.label}
                        </td>
                        {renderWithdrawals(row)}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <IconButton
                color="secondary"
                onClick={() => setAction("ADD_ROW")}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </>
          )}
        </div>
      </Layout>
    </>
  );
}

export default TeacherCard;
