import React, { useState } from "react";
import styles from "../../../../styles/admin/Dashboard.module.css";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TodayIcon from "@mui/icons-material/Today";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  CircularProgress,
  IconButton,
  Dialog,
  Tooltip,
} from "@mui/material";
import { useSelector } from "react-redux";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useSnackbar } from "notistack";
import { getError, compressPdf } from "../../../../config/config";
import axios from "axios";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function Menu(props) {
  const { currentClassroom, sidemenu, } = useSelector(
    (state) => state.dashboard
  );
  console.log('currentClassroom', currentClassroom);
  const { userInfo } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("");
  const [formData, setFormData] = useState({
    sender: userInfo.establishment._id,
    senderAccount: userInfo.user._id,
    receiver: currentClassroom._id,
    subject: "",
    message: "",
    documents: [],
  });
  const { subject, message } = formData;

  const send = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const { data } = await axios.post("/notification/send", formData);
      enqueueSnackbar(data.message, { variant: "success" });
      setFormData({
        sender: userInfo.establishment._id,
        senderAccount: userInfo.user._id,
        receiver: currentClassroom._id,
        subject: "",
        message: "",
        documents: [],
      });
      setSelected("");
      setLoading(false);
      setOpen(false);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setFormData({
            sender: userInfo.establishment._id,
            senderAccount: userInfo.user._id,
            receiver: currentClassroom._id,
            subject: "",
            message: "",
            documents: [],
          });
        }}
      >
        <form
          onSubmit={send}
          className="modal small"
          style={{ top: "10vh", maxHeight: "100%" }}
        >
          <h1>
            Notifier la classe:&nbsp;
            {currentClassroom.level + " " + currentClassroom.group}
          </h1>
          <div className="labeledInput">
            <label>Sujet</label>
            <input
              required
              className="defaultInput"
              type="text"
              value={subject}
              onChange={(e) => {
                setFormData({ ...formData, subject: e.target.value });
              }}
            />
          </div>
          <br />
          <div className="labeledInput">
            <label>Message</label>
            <textarea
              rows={5}
              style={{ height: "auto" }}
              required
              className="defaultInput"
              type="text"
              value={message}
              onChange={(e) => {
                setFormData({ ...formData, message: e.target.value });
              }}
            />
          </div>
          <br />
          <div className="labeledInput">
            <label htmlFor="pdf">
              {selected !== "" ? (
                <div
                  style={{
                    margin: "0px",
                    cursor: "pointer",
                  }}
                >
                  {selected.map((s) => {
                    return (
                      <span
                        style={{
                          display: "inline-block",
                          marginRight: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        {s}
                      </span>
                    );
                  })}
                </div>
              ) : (
                <div
                  style={{ margin: "0px", cursor: "pointer" }}
                  className="row"
                >
                  Ajouter document&nbsp;
                  <UploadFileIcon />
                </div>
              )}
            </label>
            <input
              accept="application/pdf, application/doc, application/docx, application/ppt, application/pptx, image/*"
              id="pdf"
              multiple
              hidden
              type="file"
              onChange={async (e) => {
                var files = [];
                var fileNames = [];
                for (let i = 0; i < e.target.files.length; i++) {
                  fileNames = [...fileNames, e.target.files[i].name];
                  files = [...files, await compressPdf(e.target.files[i])];
                }
                setSelected(fileNames);
                setFormData({
                  ...formData,
                  documents: files,
                });
              }}
            />
          </div>
          <br />
          {loading ? (
            <CircularProgress size="35px" />
          ) : (
            <div className="btnRow">
              <Button type="submit" variant="contained">
                Envoyer
              </Button>
              &nbsp;
              <Button
                type="button"
                onClick={() => {
                  setFormData({
                    sender: userInfo.establishment._id,
                    senderAccount: userInfo.user._id,
                    receiver: currentClassroom._id,
                    subject: "",
                    message: "",
                    documents: [],
                  });
                  setSelected("");
                  setOpen(false);
                }}
                variant="outlined"
              >
                Annuler
              </Button>
            </div>
          )}
        </form>
      </Dialog>
      <div
        style={{
          transition: "all 0.3s",
          width: sidemenu ? "calc(80% - 40px)" : "calc(100% - 94px)",
        }}
        className={styles.menu}
      >
        <Tooltip title="liste des classes">
          <IconButton
            onClick={() => navigate("/schooling/classrooms")}
            color="primary"
          >
            <ArrowBackIosIcon />
          </IconButton>
        </Tooltip>
        <Link
          className={
            window.location.pathname === "/schooling/classroom/students"
              ? styles.active
              : null
          }
          to="/schooling/classroom/students"
        >
          <PeopleAltIcon />
          &nbsp;<p>Liste des élèves</p>
        </Link>
        <Link
          className={
            window.location.pathname === "/schooling/classroom/subjects"
              ? styles.active
              : null
          }
          to="/schooling/classroom/subjects"
        >
          <SquareFootIcon />
          &nbsp;<p>Liste des matières</p>
        </Link>
        <Link
          className={
            window.location.pathname === "/schooling/classroom/study-schedule"
              ? styles.active
              : null
          }
          to="/schooling/classroom/study-schedule"
        >
          <CalendarMonthIcon />
          &nbsp;<p>Emploi du temps</p>
        </Link>
        <Link
          className={
            window.location.pathname === "/schooling/classroom/exam-schedule"
              ? styles.active
              : null
          }
          to="/schooling/classroom/exam-schedule"
        >
          <TodayIcon />
          &nbsp;<p>Emploi des examens</p>
        </Link>

        <Tooltip title="notifier la classe">
          <IconButton onClick={() => setOpen(true)} color="primary">
            <NotificationsActiveIcon />
          </IconButton>
        </Tooltip>
      </div>
    </>
  );
}

export default Menu;
