import React, { useState } from "react";
import { useSnackbar } from "notistack";
import axios from "axios";
import { getError } from "../config/config";

function Register(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const { name, phone, email, password, confirmPassword } = formData;

  const submit = async (e) => {
    e.preventDefault();
    try {
      if (password !== confirmPassword)
        return enqueueSnackbar("les mots de passe ne sont pas identiques !", {
          variant: "error",
        });
      const { data } = await axios.post("/register", formData);
      enqueueSnackbar("utilisateur créer", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(getError(error), {
        variant: "error",
      });
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <h1>Register</h1>
      <form
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        onSubmit={submit}
      >
        <label>Nom</label>
        <input
          autoComplete="new-password"
          required
          type="text"
          value={name}
          onChange={(e) => {
            setFormData({ ...formData, name: e.target.value });
          }}
        />
        <label>Phone</label>
        <input
          autoComplete="new-password"
          required
          type="number"
          value={phone}
          onChange={(e) => {
            setFormData({ ...formData, phone: e.target.value });
          }}
        />
        <label>Email</label>
        <input
          autoComplete="new-password"
          required
          type="email"
          value={email}
          onChange={(e) => {
            setFormData({ ...formData, email: e.target.value });
          }}
        />
        <label>Password</label>
        <input
          autoComplete="new-password"
          required
          type="password"
          value={password}
          onChange={(e) => {
            setFormData({ ...formData, password: e.target.value });
          }}
        />
        <label>Confirm password</label>
        <input
          autoComplete="new-password"
          required
          type="password"
          value={confirmPassword}
          onChange={(e) => {
            setFormData({ ...formData, confirmPassword: e.target.value });
          }}
        />
        <br />
        <button type="submit">submit</button>
      </form>
    </div>
  );
}

export default Register;
