import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { compressPdf } from "../../../../../../config/config";
import UploadFileIcon from "@mui/icons-material/UploadFile";

function NotifyStudent(props) {
  const {
    loadingForm,
    student,
    notifyStudent,
    notification,
    selected,
    setSelected,
    setNotification,
    setAction,
    resetNotif,
  } = props;
  return (
    <form onSubmit={notifyStudent}>
      <h1>
        Notifier l&apos;élève:&nbsp;
        {student.user.name}
      </h1>
      <div className="labeledInput">
        <label>Sujet</label>
        <input
          required
          className="defaultInput"
          type="text"
          value={notification.subject}
          onChange={(e) => {
            setNotification({ ...notification, subject: e.target.value });
          }}
        />
      </div>
      <br />
      <div className="labeledInput">
        <label>Message</label>
        <textarea
          rows={5}
          style={{ height: "auto" }}
          required
          className="defaultInput"
          type="text"
          value={notification.message}
          onChange={(e) => {
            setNotification({ ...notification, message: e.target.value });
          }}
        />
      </div>
      <br />
      <div className="labeledInput">
        <label htmlFor="pdf">
          {selected !== "" ? (
            <div
              style={{
                margin: "0px",
                cursor: "pointer",
              }}
            >
              {selected.map((s) => {
                return (
                  <span
                    style={{
                      display: "inline-block",
                      marginRight: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {s}
                  </span>
                );
              })}
            </div>
          ) : (
            <div style={{ margin: "0px", cursor: "pointer" }} className="row">
              Ajouter document&nbsp;
              <UploadFileIcon />
            </div>
          )}
        </label>
        <input
          accept="application/pdf, application/doc, application/docx, application/ppt, application/pptx, image/*"
          id="pdf"
          multiple
          hidden
          type="file"
          onChange={async (e) => {
            var files = [];
            var fileNames = [];
            for (let i = 0; i < e.target.files.length; i++) {
              fileNames = [...fileNames, e.target.files[i].name];
              files = [...files, await compressPdf(e.target.files[i])];
            }
            setSelected(fileNames);
            setNotification({
              ...notification,
              documents: files,
            });
          }}
        />
      </div>
      <br />
      {loadingForm ? (
        <CircularProgress size="35px" />
      ) : (
        <div className="btnRow">
          <Button type="submit" variant="contained">
            Envoyer
          </Button>
          &nbsp;
          <Button
            type="button"
            onClick={() => {
              setNotification({
                receiver: "",
                subject: "",
                message: "",
                documents: [],
              });
              setSelected("");
              resetNotif();
              setAction("");
            }}
            variant="outlined"
          >
            Annuler
          </Button>
        </div>
      )}
    </form>
  );
}

export default NotifyStudent;
