import {
  Button,
  CircularProgress
} from "@mui/material";
import React from "react";

function ActivateStudent(props) {
  const { loadingForm, setAction, activateStudent, student } = props;

  return (
    <form onSubmit={activateStudent}>
      <h1>activer l&apos;élève:&nbsp;{student.user.name}</h1>
      <div className="row">
        {loadingForm ? (
          <CircularProgress size="35px" />
        ) : (
          <>
            <Button type="submit" variant="contained">
              Activer
            </Button>
            &nbsp;
            <Button
              type="button"
              onClick={() => {
                setAction("");
              }}
              variant="outlined"
            >
              Annuler
            </Button>
          </>
        )}
      </div>
    </form>
  );
}

export default ActivateStudent;
