import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Dialog, Skeleton, Tooltip, Button } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { getError } from "../../../../../config/config";
import styles from "../../../../../styles/admin/ExamSchedule.module.css";
import Layout from "../../../components/Layout";
import Menu from "../../components/Menu";
import AddDate from "./components/AddDate";
import AddExam from "./components/AddExam";
import DeleteExam from "./components/DeleteExam";
import DeleteSchedule from "./components/DeleteSchedule";
import EditExam from "./components/EditExam";
import PrintIcon from "@mui/icons-material/Print";
import { FormControlLabel, Switch } from "@mui/material";


function ExamSchedule(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentClassroom } = useSelector((state) => state.dashboard);
  const [date, setDate] = useState("");
  const [action, setAction] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [exam, setExam] = useState({ start: "", end: "" });
  const [isPublished, setIsPublished] = useState(false);

  const [exams, setExams] = useState([]);
  const printRef = useRef(null);


  console.log("exam", exam);

  const handleToggleChange =
    (exam) =>
    async (e) => {
      try {
        const { data } = await axios.put(`/exam/publish/${exam._id}`, {
          isPublished: e.target.checked,
        });
        enqueueSnackbar(data.message, { variant: "success" });
        fetchExams();
      } catch (error) {
        enqueueSnackbar(getError(error), { variant: "error" });
      }
    };






  const addDate = (e) => {
    e.preventDefault();
    const exists = exams.find((ex) => ex.date === date);
    if (exists)
      return enqueueSnackbar("La date est déjà crée !", { variant: "warning" });
    setExams([...exams, { date: date, exams: [] }]);
    setAction("");
    setDate("");
  };

  const addExam = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    const row = exams.find((ex) => ex.date === exam.date);
    const exits = row.exams.find(
      (ex) =>
        ex.timing ===
        `${moment(exam.start).format("hh:mm") +
        " - " +
        moment(exam.end).format("hh:mm")
        }`
    );
    if (exits)
      return (
        enqueueSnackbar("Il y a dèjà un examen dans cette heure !", {
          variant: "warning",
        }),
        setLoadingForm(false)
      );
    if (exam.start > exam.end)
      return (
        enqueueSnackbar(
          "L'heure de début doit être inférieur à l'haure de fin !",
          {
            variant: "warning",
          }
        ),
        setLoadingForm(false)
      );
    try {
      const { data } = await axios.post("/exam/add", exam);
      enqueueSnackbar(data.message, { variant: "success" });
      setExam({ start: "", end: "" });
      fetchExams();
      setAction("");
      setLoadingForm(false);
    } catch (error) {
      setLoadingForm(false);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const editExam = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    const row = exams.find((ex) => ex.date === exam.date);
    const exits = row.exams.find(
      (ex) =>
        ex.timing ===
        `${moment(exam.start).format("hh:mm") +
        " - " +
        moment(exam.end).format("hh:mm")
        }` && ex._id !== exam._id
    );
    if (exits)
      return (
        enqueueSnackbar("Il y a dèjà un examen dans cette heure !", {
          variant: "warning",
        }),
        setLoadingForm(false)
      );
    if (exam.start > exam.end)
      return (
        enqueueSnackbar(
          "L'heure de début doit être inférieur à l'haure de fin !",
          {
            variant: "warning",
          }
        ),
        setLoadingForm(false)
      );
    try {
      const { data } = await axios.put("/exam/edit", exam);
      enqueueSnackbar(data.message, { variant: "success" });
      setExam({ start: "", end: "" });
      fetchExams();
      setAction("");
      setLoadingForm(false);
    } catch (error) {
      setLoadingForm(false);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const deleteExam = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      const { data } = await axios.delete(`/exam/remove/${exam._id}`);
      enqueueSnackbar(data.message, { variant: "success" });
      setExam({ start: "", end: "" });
      fetchExams();
      setAction("");
      setLoadingForm(false);
    } catch (error) {
      setLoadingForm(false);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const deleteSchedule = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      const { data } = await axios.delete(
        `/exam/removeall/${currentClassroom._id}`
      );
      enqueueSnackbar(data.message, { variant: "success" });
      setExam({ start: "", end: "" });
      fetchExams();
      setAction("");
      setLoadingForm(false);
    } catch (error) {
      setLoadingForm(false);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const fetchExams = async () => {
    try {
      const { data } = await axios.get(
        `/exam/get/${currentClassroom._id}/${userInfo.user._id}`
      );
      setExams(data);
      setIsPublished(data[0]?.exams[0]?.isPublished);
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoading(false);
    }
  };

  const fetchSubjects = async () => {
    try {
      const { data } = await axios.get(
        `/subject/getallbyclassroom/${currentClassroom._id}`
      );
      setSubjects(data);
    } catch (error) {

      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const fetchRooms = async () => {
    try {
      const { data } = await axios.get(
        `/room/getall/${userInfo.establishment._id}`
      );
      setRooms(data);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  useEffect(() => {
    fetchExams();
    fetchSubjects();
    fetchRooms();
  }, []);

  return (
    <Layout>
      <Dialog
        open={action !== ""}
        onClose={() => {
          setAction("");
          setDate("");
          setExam({ start: "", end: "" });
        }}
      >
        <div
          style={
            action === "ADD_EXAM" || action === "EDIT_EXAM"
              ? { maxHeight: "100%", top: "20vh" }
              : { maxHeight: "100%" }
          }
          className={"modal small"}
        >
          {action === "ADD_DATE" ? (
            <AddDate
              addDate={addDate}
              setAction={setAction}
              date={date}
              setDate={setDate}
            />
          ) : action === "ADD_EXAM" ? (
            <AddExam
              addExam={addExam}
              setAction={setAction}
              exam={exam}
              setExam={setExam}
              subjects={subjects}
              rooms={rooms}
              loadingForm={loadingForm}
            />
          ) : action === "EDIT_EXAM" ? (
            <EditExam
              editExam={editExam}
              setAction={setAction}
              exam={exam}
              setExam={setExam}
              subjects={subjects}
              rooms={rooms}
              loadingForm={loadingForm}
            />
          ) : action === "DELETE_EXAM" ? (
            <DeleteExam
              deleteExam={deleteExam}
              setAction={setAction}
              exam={exam}
              setExam={setExam}
              loadingForm={loadingForm}
            />
          ) : action === "DELETE_SCHEDULE" ? (
            <DeleteSchedule
              deleteSchedule={deleteSchedule}
              setAction={setAction}
              loadingForm={loadingForm}
            />
          ) : null}
        </div>
      </Dialog>
      <Menu />
      <div className={styles.container}>
        {loading ? (
          <Skeleton variant="rectangular" width="100%">
            <div style={{ height: "calc(100vh - 200px)" }} />
          </Skeleton>
        ) : (
          <div className={styles.tableContainer}>
            <div ref={printRef} className="printBody">
              <table className="scheduleTable">
                <thead>
                  <tr>
                    <th
                      style={{
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "-2px",
                          left: "0px",
                        }}
                        className="dontprint"
                      >
                        <ReactToPrint
                          trigger={() => (
                            <IconButton variant="contained">
                              <PrintIcon color="primary" />
                            </IconButton>
                          )}
                          content={() => printRef.current}
                        />
                      </div>
                      date
                    </th>
                    <th>Examens</th>
                    {exams.length > 0 ? (
                      <span className={styles.reset}>
                        <Tooltip
                          sx={{ color: "red" }}
                          title="supprimer l'emploi"
                        >
                          <IconButton
                            size="small"
                            onClick={() => {
                              setAction("DELETE_SCHEDULE");
                            }}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </span>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  {exams.map((row) => (
                    <tr key={row.date}>
                      <td
                        style={{ width: "20%", backgroundColor: "#f5f3f3" }}
                      >
                        <p>{moment(row.date).format("dddd")}</p>
                        <p>{moment(row.date).format("DD-MM-yyyy")}</p>
                      </td>
                      <td style={{ width: "80%" }}>
                        {row?.exams?.map((ex, i) => (
                          <div key={i} className={styles.row}>
                            <div className={styles.col}>
                              <p>{ex.timing}</p>
                            </div>
                            <div className={styles.col}>
                              <p>
                                Matière:{" "}
                                {ex?.subject?.name ? ex.subject.name : "aucune"}
                              </p>

                              <p>
                                Salle:{" "}
                                {ex?.room?.name ? ex.room.name : "aucune "}
                              </p>
                            </div>
                            <div style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100px",
                              padding: "5px",
                            }}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={ex.isPublished}
                                    onChange={
                                      handleToggleChange(ex)


                                    }
                                    color="primary"
                                  />
                                }
                                label={ex.isPublished ? "Publié" : "Non publié"}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "50px",
                              
                                padding: "5px",
                              }}


                            >
                              <div className={styles.edit}>
                                <Tooltip
                                  color="secondary"
                                  title="Modifier l'examen"
                                >
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      setExam({
                                        ...ex,
                                        start: moment(
                                          ex.timing.split(" - ")[0],
                                          "hh:mm"
                                        ),
                                        end: moment(
                                          ex.timing.split(" - ")[1],
                                          "hh:mm"
                                        ),
                                      });
                                      setAction("EDIT_EXAM");
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                              </div>
                              <div className={styles.delete}>
                                <Tooltip
                                  sx={{ color: "red" }}
                                  title="Supprimer l'examen"
                                >
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      setExam(ex);
                                      setAction("DELETE_EXAM");
                                    }}
                                  >
                                    <DeleteOutlineIcon />
                                  </IconButton>
                                </Tooltip>
                              </div>

                            </div>
                          </div>
                        ))}
                        <div className={styles.add}>
                          <Tooltip
                            sx={{ color: "green" }}
                            title="Ajouter un examen"
                          >
                            <IconButton
                              size="small"
                              onClick={() => {
                                setExam({
                                  ...exam,
                                  date: row.date,
                                  classroom: currentClassroom._id,
                                });
                                setAction("ADD_EXAM");
                              }}
                            >
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div style={{ width: "20%", textAlign: "center", padding: "10px" }}>
              <Tooltip title="Ajouter une date">
                <IconButton
                  size="small"
                  onClick={() => {
                    setAction("ADD_DATE");
                  }}
                >
                  <AddCircleOutlineIcon color="primary" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default ExamSchedule;
