import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { getMonth } from "../../../../config/config";

function DeleteWithdrawal(props) {
  const {
    deleteWithdrawal,
    resetWithdrawal,
    setAction,
    loadingForm,
    withdrawal,
  } = props;
  return (
    <>
      <h1 style={{ textAlign: "left", lineHeight: "25px" }}>
        Supprimer ce paiement du mois "{getMonth(withdrawal.monthIndex)}" avec
        le montant "{withdrawal.amount} DT" ?
      </h1>
      {loadingForm ? (
        <CircularProgress size="33px" />
      ) : (
        <div className="btnRow">
          <Button
            variant="contained"
            onClick={() => {
              deleteWithdrawal();
            }}
          >
            supprimer
          </Button>
          &nbsp;
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              setAction("");
              resetWithdrawal();
            }}
          >
            annuler
          </Button>
        </div>
      )}
    </>
  );
}

export default DeleteWithdrawal;
