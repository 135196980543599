import {
  Button,
  CircularProgress,
  Modal,
  Select,
  MenuItem,
  Tooltip,
  IconButton,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getError } from "../../../config/config";
import styles from "../../../styles/admin/Dashboard.module.css";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import Layout from "../components/Layout";
import AddClassroom from "./components/AddClassroom";
import EditClassroom from "./components/EditClassroom";
import SettingsIcon from "@mui/icons-material/Settings";
import InputIcon from "@mui/icons-material/Input";
import { Link } from "react-router-dom";
import { Dialog } from "@mui/material";
function Classrooms(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentSeason } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    level: "",
    group: "",
    capacity: 0,
    costs: 0,
    establishment: userInfo.establishment._id,
    season: currentSeason?._id,
  });
  const [classRooms, setClassrooms] = useState([]);
  const [classroom, setClassroom] = useState(null);
  const [action, setAction] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [level, setLevel] = useState("");
  const [group, setGroup] = useState("");

  const fetchClassrooms = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `/classroom/get/${userInfo.establishment._id}/${currentSeason?._id}`
      );
      setClassrooms(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const addClassroom = async (e) => {
    setLoadingForm(true);
    e.preventDefault();
    try {
      const { data } = await axios.post("/classroom/add", formData);
      enqueueSnackbar(data.message, { variant: "success" });
      setFormData({
        level: "",
        group: "",
        capacity: 0,
        costs: 0,
        establishment: userInfo.establishment._id,
        season: currentSeason?._id,
      });
      fetchClassrooms();
      setLoadingForm(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  const editClassroom = async (e) => {
    setLoadingForm(true);
    e.preventDefault();
    try {
      const { data } = await axios.put("/classroom/edit", formData);
      enqueueSnackbar(data.message, { variant: "success" });
      setFormData({
        level: "",
        group: "",
        capacity: 0,
        costs: 0,
        establishment: userInfo.establishment._id,
        season: currentSeason?._id,
      });
      fetchClassrooms();
      setLoadingForm(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  useEffect(() => {
    if (userInfo && currentSeason) fetchClassrooms();
  }, [userInfo, currentSeason]);

  return (
    <Layout>
      <Dialog
        maxWidth="md"
        open={action !== ""}
        onClose={() => {
          setAction("");
          setFormData({
            level: "",
            group: "",
            capacity: 0,
            costs: 0,
            establishment: userInfo.establishment._id,
            season: currentSeason._id,
          });
        }}
      >
        <div
          style={{ maxHeight: "100vh" }}
          className={
            action === "ADD" || action === "EDIT" ? "modal small" : "modal"
          }
        >
          {action === "ADD" ? (
            <AddClassroom
              loadingForm={loadingForm}
              formData={formData}
              setFormData={setFormData}
              setAction={setAction}
              addClassroom={addClassroom}
              establishment={userInfo.establishment}
              season={currentSeason._id}
            />
          ) : action === "EDIT" ? (
            <EditClassroom
              loadingForm={loadingForm}
              formData={formData}
              setFormData={setFormData}
              setAction={setAction}
              classroom={classroom}
              editClassroom={editClassroom}
              establishment={userInfo.establishment}
              season={currentSeason._id}
            />
          ) : null}
        </div>
      </Dialog>
      <div className={styles.container}>
        <section className={styles.header}>
          <h1>Liste des classes</h1>
          {currentSeason && (
            <Button
              onClick={() => {
                setAction("ADD");
              }}
              variant="contained"
            >
              Ajouter
            </Button>
          )}
        </section>
        {currentSeason && (
          <section className={styles.filter}>
            <div
              style={{ maxWidth: "300px", marginRight: "10px" }}
              className="labeledInput"
            >
              <label>Niveau</label>
              <Select
                value={level}
                type="text"
                required
                onChange={(e) => {
                  setLevel(e.target.value);
                }}
                className="grayInput"
                sx={{
                  padding: "0px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "0.5px solid transparent !important",
                  },
                }}
                size="small"
              >
                {userInfo.establishment.type === "ecole-primaire"
                  ? [
                      <MenuItem key="1ère" value="1ère">
                        1ère
                      </MenuItem>,
                      <MenuItem key="2ème" value="2ème">
                        2ème
                      </MenuItem>,
                      <MenuItem key="3ème" value="3ème">
                        3ème
                      </MenuItem>,
                      <MenuItem key="4ème" value="4ème">
                        4ème
                      </MenuItem>,
                      <MenuItem key="5ème" value="5ème">
                        5ème
                      </MenuItem>,
                      <MenuItem key="6ème" value="6ème">
                        6ème
                      </MenuItem>,
                    ]
                  : userInfo.establishment.type === "collège"
                  ? [
                      <MenuItem key="7ème" value="7ème">
                        7ème
                      </MenuItem>,
                      <MenuItem key="8ème" value="8ème">
                        8ème
                      </MenuItem>,
                      <MenuItem key="9ème" value="9ème">
                        9ème
                      </MenuItem>,
                    ]
                  : userInfo.establishment.type === "ecole-secondaire"
                  ? [
                      <MenuItem key="1ère" value="1ère">
                        1ère
                      </MenuItem>,
                      <MenuItem key="2ème" value="2ème">
                        2ème
                      </MenuItem>,
                      <MenuItem key="3ème" value="3ème">
                        3ème
                      </MenuItem>,
                      <MenuItem key="4ème" value="4ème">
                        4ème
                      </MenuItem>,
                    ]
                  : null}
              </Select>
            </div>
            <div
              style={{ maxWidth: "300px", marginRight: "10px" }}
              className="labeledInput"
            >
              <label>Groupe</label>
              <input
                value={group}
                onChange={(e) => setGroup(e.target.value)}
                className="grayInput"
              />
            </div>
            {level !== "" || group !== "" ? (
              <Button
                onClick={() => {
                  setLevel("");
                  setGroup("");
                }}
                size="large"
                variant="outlined"
              >
                <RotateLeftIcon />
              </Button>
            ) : null}
          </section>
        )}
        {loading ? (
          <div className="spinner">
            <CircularProgress />
          </div>
        ) : (
          <div
            style={{ height: "calc(100vh - 280px)" }}
            className={styles.tableContainer}
          >
            <table className="defaultTable">
              <thead>
                <tr>
                  <th>Niveau</th>
                  <th>Groupe</th>
                  <th>Capacité</th>
                  <th>Frais</th>
                  <th>Étudiants</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {classRooms
                  .filter((x) => (level !== "" ? x.level.includes(level) : x))
                  .filter((x) => (group !== "" ? x.group.includes(group) : x))
                  .map((classroom) => {
                    return (
                      <tr key={classroom._id}>
                        <td>{classroom.level}</td>
                        <td>{classroom.group}</td>
                        <td>{classroom.capacity}</td>
                        <td>{classroom.costs} DT</td>
                        <td>{classroom.students}</td>
                        <td>
                          <Tooltip title="modifier">
                            <IconButton
                              onClick={() => {
                                setClassroom(classroom);
                                setFormData(classroom);
                                setAction("EDIT");
                              }}
                              sx={{ color: "#283487" }}
                            >
                              <SettingsIcon />
                            </IconButton>
                          </Tooltip>
                          &nbsp;
                          <Link to="/schooling/classroom/students">
                            <Tooltip title="configurer">
                              <IconButton
                                onClick={() => {
                                  dispatch({
                                    type: "SET_CURRENT_CLASSROOM",
                                    payload: classroom,
                                  });
                                }}
                                sx={{ color: "#0058A5" }}
                              >
                                <InputIcon />
                              </IconButton>
                            </Tooltip>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default Classrooms;
