import styles from "../../../../styles/student/Dashboard.module.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TodayIcon from "@mui/icons-material/Today";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import { Link} from "react-router-dom";
import { useSelector } from "react-redux";

function Menu(props) {
  const { sidemenu } = useSelector(
    (state) => state.dashboard
  );


  return (
    <>
      <div
        style={{
          transition: "all 0.3s",
          width: sidemenu ? "calc(80% - 40px)" : "calc(100% - 94px)",
        }}
        className={styles.menu}
      >
        <Link
          className={
            window.location.pathname === "/classroom/subjects"
              ? styles.active
              : null
          }
          to="/classroom/subjects"
        >
          <SquareFootIcon />
          &nbsp;<p className={styles.option}>Liste des matières</p>
        </Link>
        <Link
          className={
            window.location.pathname === "/classroom/study-schedule"
              ? styles.active
              : null
          }
          to="/classroom/study-schedule"
        >
          <CalendarMonthIcon />
          &nbsp;<p className={styles.option}>Emploi de temps</p>
        </Link>
        <Link
          className={
            window.location.pathname === "/classroom/exam-schedule"
              ? styles.active
              : null
          }
          to="/classroom/exam-schedule"
        >
          <TodayIcon />
          &nbsp;<p className={styles.option}>Emploi des examen</p>
        </Link>
        <Link
          className={
            window.location.pathname === "/notes"
              ? styles.active
              : null
          }
          to="/notes"
        >
          <TodayIcon />
          &nbsp;<p className={styles.option}>Relevés des notes</p>
        </Link>
      </div>
    </>
  );
}

export default Menu;
