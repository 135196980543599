import { IconButton, MenuItem, Select } from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { getError } from "../../../../../config/config";
import styles from "../../../../../styles/admin/ExamSchedule.module.css";
import Layout from "../../../components/Layout";
import Menu from "../../components/Menu";
import PrintIcon from "@mui/icons-material/Print";

function MyNotes(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useSelector((state) => state.auth);
  const currentClassroom  = userInfo.classroom;
  const { currentSeason } = useSelector((state) => state.dashboard);
  const [subjects, setSubjects] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalCoefficient, setTotalCoefficient] = useState(0);
  const [moyenne, setMoyenne] = useState(0);
  const [notes, setNotes] = useState([]);
  const [period, setPeriod] = useState(
    currentSeason?.type === "trimestrielle" ? "trimestre 1" : "semestre 1"
  );
  const printRef = useRef(null);
  const dispatch = useDispatch();

  const fetchSubjects = async () => {
    try {
      const { data } = await axios.get(
        `/subject/getallbyclassroom/${currentClassroom}`
      );
      setSubjects(data);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const fetchNotes = async () => {
    try {
      const { data } = await axios.get(
        `/note/get/${userInfo?._id}?season=${currentSeason?._id}&period=${period}`
      );
      setNotes(data.notes);
      setTotal(data.total);
      setTotalCoefficient(data.totalCoefficient);
      setMoyenne(data.moyenne);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const fetchNotifications = async () => {
    try {
      const { data } = await axios.get(`/notification/get/${userInfo?._id}`);
      dispatch({ type: "UPDATE_NOTIFICATIONS", payload: data.count });
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    fetchSubjects();
    fetchNotes();
  }, [period]);

  return (
    <Layout>
      <Menu />
      <div className={styles.container}>
        {currentSeason?.type === "trimestrielle" && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              padding: "10px",
            }}
          >
            <Select
              required
              value={period}
              onChange={(e) => {
                setPeriod(e.target.value);
              }}
              size="small"
              sx={{
                backgroundColor: "transparent",
                height: "40px",
                margin: "auto 0",
                width: "20%",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "5px !important",
                  border: "0.5px solid #283487 !important",
                },
              }}
            >
              <MenuItem
                style={{
                  marginBottom: "5px",
                }}
                value={"trimestre 1"}
              >
                trimestre 1
              </MenuItem>
              <MenuItem
                style={{
                  marginBottom: "5px",
                }}
                value={"trimestre 2"}
              >
                trimestre 2
              </MenuItem>
              <MenuItem
                style={{
                  marginBottom: "5px",
                }}
                value={"trimestre 3"}
              >
                trimester 3
              </MenuItem>
            </Select>
            {total > 0 && (
              <div>
                <p>Total: {total}</p>
                <p>Coefficient: {totalCoefficient}</p>
                <p>Moyenne: {moyenne.toFixed(2)}</p>
              </div>
            )}
          </div>
        )}
        {currentSeason?.type === "semestrielle" && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              padding: "10px",
            }}
          >
            <Select
              required
              value={period}
              onChange={(e) => {
                setPeriod(e.target.value);
              }}
              size="small"
              sx={{
                backgroundColor: "transparent",
                height: "40px",
                margin: "auto 0",
                width: "20%",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "5px !important",
                  border: "0.5px solid #283487 !important",
                },
              }}
            >
              <MenuItem
                style={{
                  marginBottom: "5px",
                }}
                value={"semestre 1"}
              >
                semestre 1
              </MenuItem>
              <MenuItem
                style={{
                  marginBottom: "5px",
                }}
                value={"semestre 2"}
              >
                semestre 2
              </MenuItem>
            </Select>
            {total > 0 && (
              <div>
                <p>Total: {total}</p>
                <p>Coefficient: {totalCoefficient}</p>
                <p>Moyenne: {moyenne.toFixed(2)}</p>
              </div>
            )}
          </div>
        )}
        <div className={styles.tableContainer}>
          <div ref={printRef} className="printBody">
            <table className="scheduleTable">
              <thead>
                <tr>
                  <th
                    style={{
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "-2px",
                        left: "0px",
                      }}
                      className="dontprint"
                    >
                      <ReactToPrint
                        trigger={() => (
                          <IconButton variant="contained">
                            <PrintIcon color="primary" />
                          </IconButton>
                        )}
                        content={() => printRef.current}
                      />
                    </div>
                    Matiéres
                  </th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
                {subjects.map((row) => {
                  return (
                    <tr key={row._id}>
                      <td style={{ width: "20%", backgroundColor: "#f5f3f3" }}>
                        <p>Matiére: {row.name}</p>
                        <p style={{ textTransform: "none" }}>
                          Enseignant(e): {row.teacher.user.name}
                        </p>
                        <p>Coefficient: {row.coefficient}</p>
                      </td>
                      <td style={{ width: "60%" }}>
                        {notes.map(
                          (note) =>
                            note.subject === row._id &&
                            note.user === userInfo._id && (
                              <div key={note._id} className={styles.row2}>
                                <div className={styles.col}>
                                  <p>Type: {note.type}</p>
                                  <p>Coefficient: {note.coefficient}</p>
                                </div>
                                <div className={styles.col}>
                                  <p>{note.note}</p>
                                </div>
                              </div>
                            )
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default MyNotes;
