import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { CircularProgress, Tooltip, Modal } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import styles from "../../../../../styles/admin/StudentCard.module.css";
import Layout from "../../../components/Layout";
import Receipt from "../../../../admin/schooling/classroom/students/components/Receipt";
import { getError } from "../../../../../config/config";


function ProfileCard(props) {
  const { enqueueSnackbar } = useSnackbar();
  const printRef = useRef();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentSeason } = useSelector((state) => state.dashboard);
  const [student, setStudent] = useState({});
  const [loadingCard, setLoadingCard] = useState(true);
  const [action, setAction] = useState("");
  const dispatch = useDispatch();
  const [deposit, setDeposit] = useState({
    establishment: userInfo?.establishment?._id,
    season: "",
    student: userInfo?._id,
    label: "",
    monthIndex: 0,
    amount: 0,
    method: "",
    multiple: 0,
  });

  const fetchStudent = async () => {
    try {
      const { data } = await axios.get(`/student/getbyid/${userInfo?._id}`);
      setStudent(data);
      setLoadingCard(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setLoadingCard(false);
    }
  };
  
  const fetchNotifications = async () => {
    try {
      const { data } = await axios.get(`/notification/get/${userInfo?._id}`);
      dispatch({ type: "UPDATE_NOTIFICATIONS", payload: data.count });
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);
    const resetDeposit = () => {
      setDeposit({
        establishment: userInfo?.establishment?._id,
        season: currentSeason?._id,
        student: userInfo?._id,
        label: "",
        monthIndex: 0,
        amount: 0,
        method: "",
        multiple: 0,
      });
    };

  useEffect(() => {
    fetchStudent();
  }, [currentSeason]);

  return (
    <>
      <Modal
        open={action !== ""}
        onClose={() => {
            setAction("");
            resetDeposit();
        }}
      >
        <div
          className={action === "SHOW_RECEIPT" ? "modal medium" : "modal small"}
        >
          {action === "SHOW_RECEIPT" ? (
            <Receipt
              setAction={setAction}
              deposit={deposit}
              resetDeposit={resetDeposit}
              student={student}
            />
          ) : null}
        </div>
      </Modal>
      <Layout>
        <div className={styles.container}>
          {loadingCard ? (
            <div className="spinner">
              <CircularProgress size="30px" />
            </div>
          ) : (
            <section ref={printRef} className={styles.card}>
              <div className={`${styles.print} dontprint`}>
                <ReactToPrint
                  trigger={() => (
                    <LocalPrintshopIcon
                      onClick={() => window.print()}
                      style={{ cursor: "pointer" }}
                      color="primary"
                    />
                  )}
                  content={() => printRef.current}
                />
              </div>
              <div
                style={{ justifyContent: "flex-start" }}
                className={styles.row}
              >
                <img
                  style={{ cursor: "default" }}
                  alt="avatar"
                  src={
                    student.user.avatar !== ""
                      ? student.user.avatar
                      : "/icons/profile2.svg"
                  }
                />
              </div>
              <div className={styles.row}>
                <div className={styles.col50}>
                  <h1>
                    nom et prénom:&nbsp;<a>{student.user.name}</a>
                  </h1>
                  <h1>
                    date de naissance:&nbsp;<a>{student.birthday}</a>
                  </h1>
                  <h1>
                    sexe:&nbsp;<a>{student.sex}</a>
                  </h1>
                  <h1>
                    adresse:&nbsp;<a>{student.address}</a>
                  </h1>
                </div>
                <div className={styles.col50}>
                  <h1>
                    classe:&nbsp;
                    <a>
                      {student?.classroom?.level + " " + student?.classroom?.group}
                    </a>
                  </h1>
                  <h1>
                    inscri le:&nbsp;
                    <a>{moment(student.createdAt).format("yyyy-MM-DD")}</a>
                  </h1>
                  <h1>
                    tarif scolarité par mois:&nbsp;
                    <a>{student?.classroom?.costs + " dt"}</a>
                  </h1>
                </div>
              </div>
              <div className={styles.hr} />
              <div className={styles.row}>
                <div className={styles.col50}>
                  <h1>
                    nom père:&nbsp;<a>{student.fatherName}</a>
                  </h1>
                  <h1>
                    profession père:&nbsp;<a>{student.fatherProfession}</a>
                  </h1>
                  <h1>
                    téléphone père:&nbsp;<a>{student.fatherPhone}</a>
                  </h1>
                  <h1>
                    nom mère:&nbsp;<a>{student.motherName}</a>
                  </h1>
                  <h1>
                    profession mère:&nbsp;<a>{student.motherProfession}</a>
                  </h1>
                  <h1>
                    téléphone mère:&nbsp;<a>{student.motherPhone}</a>
                  </h1>
                </div>
                <div className={styles.col50}>
                  <h1>
                    nb.frères et soeurs:&nbsp;<a>{student.siblings}</a>
                  </h1>
                  <h1>
                    nb.frères et soeurs etudiants:&nbsp;
                    <a>{student.siblingsStudents}</a>
                  </h1>
                  <h1>
                    situation familiale:&nbsp;<a>{student.maritalStatus}</a>
                  </h1>
                  <h1>
                    nb.frères et soeurs:&nbsp;
                    {student.authorizedCompany.map((name, i) => {
                      return (
                        <>
                          <a key={i}>{name}</a>,&nbsp;
                        </>
                      );
                    })}
                  </h1>
                  <h1>
                    orphelin:&nbsp;<a>{student.orphin ? "oui" : "non"}</a>
                  </h1>
                </div>
              </div>
            </section>
          )}
        </div>
      </Layout>
    </>
  );
}

export default ProfileCard;
