import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../styles/teacher/Navbar.module.css";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";

function Navbar(props) {
  const dispatch = useDispatch();
  const { currentSeason } = useSelector((state) => state.dashboard);
  const { userInfo } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const getSeasons = async () => {
    try {
      const { data } = await axios.get(
        `/season/getAll/${userInfo.establishment._id}`
      );
      if (data.length > 0 && currentSeason === null)
        dispatch({
          type: "SET_CURRENT_SEASON",
          payload: data[0],
        });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userInfo) if (currentSeason) setLoading(false);
    getSeasons();
  }, [userInfo]);

  return (
    <>
      <div className={styles.navbar}>
        <div className={styles.logo}>
          <Link to="/">
            <img alt="school-hub logo" src={"/" + "./WhiteLogo.svg"} />
          </Link>
        </div>
        <div className={styles.links}>
          <div className={styles.select}>
            {loading ? (
              <CircularProgress size="30px" sx={{ color: "white" }} />
            ) : (
              <>
                {window.location.pathname.includes(
                  "/schooling/classroom/"
                ) ? null : (
                  <>
                    <label className={styles.label}>Année scolaire</label>
                    <span className={styles.year}>{currentSeason.year}</span>
                  </>
                )}
              </>
            )}
          </div>
          <div onClick={() => setOpen(!open)} className={styles.profile}>
            <span className={styles.name}>{userInfo.user.name}</span>
            <img
              className={styles.image}
              alt={userInfo.user.name}
              src={
                userInfo.user.avatar && userInfo.user.avatar !== ""
                  ? userInfo.user.avatar
                  : "/icons/teacher.png"
              }
            />
            <Link to="/profile">
              <img
                className={styles.conditionalImage}
                alt={userInfo.user.name}
                src={
                  userInfo.user.avatar && userInfo.user.avatar !== ""
                    ? userInfo.user.avatar
                    : "/icons/teacher.png"
                }
              />
            </Link>
            {open && (
              <div className={styles.dropdown}>
                <Link
                  to="/profile"
                  onClick={() => setOpen(false)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                    color: "white",
                  }}
                >
                  <PersonIcon />
                  <span className={styles.profileSpan}>Voir Profile</span>
                </Link>
                <button
                  onClick={() => {
                    dispatch({ type: "USER_LOGOUT" });
                    setOpen(false);
                  }}
                  className={styles.button}
                >
                  <LogoutIcon />
                  <span className={styles.logout}>se déconnecter</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
