import { Button, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import { sendMessage } from "../../../config/config";

function Messenger(props) {
  const { receiver, setAction } = props;
  const { userInfo } = useSelector((state) => state.auth);
  const [text, setText] = useState("");
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const send = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const data = await sendMessage(
        receiver._id,
        userInfo?.establishment._id,
        userInfo?.user._id,
        receiver._id,
        text
      );
      const socket = io();
      socket.emit("UPDATE_CHAT", receiver._id);
      enqueueSnackbar(data.message, { variant: "success" });
      setLoading(false);
      setAction("");
    } catch (error) {
      console.log(error);
      enqueueSnackbar(error.message, { variant: "error" });
      setLoading(false);
    }
  };

  return (
    <form style={{ width: "100%" }} onSubmit={send}>
      <h1>Contacter:&nbsp;{receiver.name}</h1>
      <div className="labeledInput">
        <label>Message</label>
        <textarea
          style={{ height: "100%" }}
          className="defaultInput"
          required
          value={text}
          rows={4}
          onChange={(e) => setText(e.target.value)}
        />
        <br />
        {loading ? (
          <div className="btnHolder">
            <CircularProgress size="32px" />
          </div>
        ) : (
          <div className="btnHolder">
            <Button
              type="submit"
              variant={text.trim().length === 0 ? "disabled" : "contained"}
            >
              envoyer
            </Button>
            &nbsp; &nbsp;
            <Button
              type="button"
              onClick={() => setAction("")}
              variant="outlined"
            >
              annuler
            </Button>
          </div>
        )}
      </div>
    </form>
  );
}

export default Messenger;
