import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../styles/student/Sidebar.module.css";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import LineAxisIcon from "@mui/icons-material/LineAxis";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { Link, useLocation } from "react-router-dom";
import { io } from "socket.io-client";
import { Badge } from "@mui/material";
import { useSnackbar } from "notistack";
import LogoutIcon from "@mui/icons-material/Logout";

import axios from "axios";
import { getError } from "../../../config/config";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

function Sidebar(props) {
  const { sidemenu, notifications } = useSelector((state) => state.dashboard);
  const { userInfo } = useSelector((state) => state.auth);
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const fetchNotifications = async () => {
    try {
      const { data } = await axios.get(`/notification/get/${userInfo._id}`);
      dispatch({ type: "UPDATE_NOTIFICATIONS", payload: data.count });
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    if (userInfo) {
      if (
        userInfo?.user.role === "STUDENT" ||
        userInfo?.user.role === "TEACHER"
      ) {
        var socket = io.connect(process.env.REACT_APP_SERVER_URL);
        // JOIN-ROOM
        socket.emit("join-room", userInfo.user._id);
        // INCOMING-MESSAGE
        socket.on("update-chat", () => {
          // fetchUnreadCountPatient();
        });
        // fetchUnreadCountPatient();
      } else if (userInfo?.user.role === "ADMIN") {
        var socket = io.connect(process.env.REACT_APP_SERVER_URL);
        // JOIN-ROOM
        socket.emit("join-room", userInfo.establishment._id);
        // INCOMING-MESSAGE
        socket.on("update-chat", () => {
          // fetchUnreadCount();
        });
        socket.on("update-demands", () => {
          // fetchCancelsCount();
          // fetchDemandsCount();
        });
        // fetchUnreadCount();
      }
    }
  }, [userInfo]);

  return (
    <div
      className={
        sidemenu ? styles.sidebar : `${styles.sidebar} ${styles.closed}`
      }
    >
      <div className={styles.header}>
        <h1></h1>
        <div className={styles.icon}>
          {sidemenu ? (
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() =>
                dispatch({ type: "TOGGLE_SIDEMENU", payload: false })
              }
            />
          ) : (
            <MenuIcon
              style={{ cursor: "pointer" }}
              onClick={() =>
                dispatch({ type: "TOGGLE_SIDEMENU", payload: true })
              }
            />
          )}
        </div>
        <div className={styles.hr} />
      </div>
      <Link to="/classroom/subjects">
        <li
          className={
            pathname.includes("classroom/study-schedule") ||
            pathname.includes("classroom/subjects") ||
            pathname.includes("classroom/exam-schedule") ||
            pathname.includes("notes")
              ? styles.active
              : null
          }
        >
          <TextSnippetIcon className={styles.MenuIcons} />
          <span className={styles.MenuItem}>
            {" "}
            &nbsp;&nbsp;&nbsp;{sidemenu ? "Gestion scolaire" : null}
          </span>
        </li>
      </Link>
      <Link to="/finance">
        <li className={pathname === "/finance" ? styles.active : null}>
          <AccountBalanceWalletIcon className={styles.MenuIcons} />

          <span className={styles.MenuItem}>
            &nbsp;&nbsp;&nbsp;{sidemenu ? "Gestion financière" : null}
          </span>
        </li>
      </Link>
      <Link to="/notifications">
        <li className={pathname === "/notifications" ? styles.active : null}>
          <Badge
            invisible={notifications === 0}
            badgeContent={notifications}
            color="secondary"
          >
            <NotificationsActiveIcon className={styles.MenuIcons} />
          </Badge>
          <span className={styles.MenuItem}>
            &nbsp;&nbsp;&nbsp;{sidemenu ? "Notifications" : null}
          </span>
        </li>
      </Link>
      <Link to="/messenger">
        <li className={pathname === "/messenger" ? styles.active : null}>
          <QuestionAnswerIcon className={styles.MenuIcons} />
          <span className={styles.MenuItem}>
            &nbsp;&nbsp;&nbsp;{sidemenu ? "Messenger" : null}
          </span>
        </li>
      </Link>
      <div
        onClick={() => {
          dispatch({ type: "USER_LOGOUT" });
        }}
        className={styles.button}
      >
        <LogoutIcon className={styles.MenuIcons} />
      </div>
    </div>
  );
}

export default Sidebar;
