import {
  Button,
  CircularProgress,
  IconButton,
  Dialog,
  Pagination,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getError } from "../../../../../config/config";
import styles from "../../../../../styles/admin/Dashboard.module.css";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import Layout from "../../../components/Layout";
import SettingsIcon from "@mui/icons-material/Settings";
import Menu from "../../components/Menu";

import Add from "./components/Add";
import Edit from "./components/Edit";

function ClassroomSubjects(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentClassroom } = useSelector((state) => state.dashboard);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [name, setName] = useState("");
  const [action, setAction] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [formData, setFormData] = useState({
    classroom: currentClassroom._id,
    name: "",
    coefficient: "",
    teacher: "",
  });

  const resetForm = () => {
    setFormData({
      classroom: currentClassroom._id,
      name: "",
      coefficient: "",
      teacher: "",
    });
  };

  const addSubject = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      const { data } = await axios.post("/subject/add", formData);
      enqueueSnackbar(data.message, { variant: "success" });
      resetForm();
      setLoadingForm(false);
      fetchSubjects();
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  const editSubject = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      const { data } = await axios.put("/subject/edit", formData);
      enqueueSnackbar(data.message, { variant: "success" });
      resetForm();
      setLoadingForm(false);
      fetchSubjects();
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  const fetchSubjects = async () => {
    try {
      const { data } = await axios.post(`/subject/getbyclassroom`, {
        classroom: currentClassroom._id,
        page: page,
        name: name,
      });
      setSubjects(data.subjects);
      setCount(data.count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const fetchTeachers = async () => {
    try {
      const { data } = await axios.get(
        `/teacher/getbyestablishment/${userInfo.establishment._id}`
      );
      setTeachers(data);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  useEffect(() => {
    fetchSubjects();
    fetchTeachers();
  }, [page, name]);

  return (
    <Layout>
      <Dialog
        maxWidth="md"
        open={action !== ""}
        onClose={() => {
          setAction("");
          resetForm();
        }}
      >
        <div
          className={"modal small"}
          style={{ top: "10vh", maxHeight: "100%" }}
        >
          {action === "ADD" ? (
            <Add
              formData={formData}
              loadingForm={loadingForm}
              resetForm={resetForm}
              setFormData={setFormData}
              setAction={setAction}
              teachers={teachers}
              addSubject={addSubject}
            />
          ) : action === "EDIT" ? (
            <Edit
              formData={formData}
              loadingForm={loadingForm}
              resetForm={resetForm}
              setFormData={setFormData}
              setAction={setAction}
              teachers={teachers}
              addSubject={editSubject}
            />
          ) : null}
        </div>
      </Dialog>
      <Menu />
      <div style={{ marginTop: "50px" }} className={styles.container}>
        <section className={styles.header}>
          <Typography variant="h4" component="h1" gutterBottom>
            Liste des matières
            <Typography
              style={{ color: "#0058A5" }}
              variant="body1"
              gutterBottom
            >
              Niveau : {currentClassroom.level} - Groupe :{" "}
              {currentClassroom.group}
            </Typography>
          </Typography>

          <Button
            onClick={() => {
              setAction("ADD");
            }}
            variant={loading ? "disabled" : "contained"}
          >
            ajouter
          </Button>
        </section>
        <section className={styles.filter}>
          <div
            style={{ width: "20%", marginRight: "10px" }}
            className="labeledInput"
          >
            <label>Nom</label>
            <input
              pattern="[A-Za-z]"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="grayInput"
            />
          </div>
          {name !== "" ? (
            <Button
              onClick={() => {
                setName("");
              }}
              size="large"
              variant="outlined"
            >
              <RotateLeftIcon />
            </Button>
          ) : null}
        </section>
        {loading ? (
          <div className="spinner">
            <CircularProgress />
          </div>
        ) : (
          <div
            style={{ maxHeight: "calc(100vh - 401px)" }}
            className={styles.tableContainer}
          >
            <table className="defaultTable">
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>Coefficient</th>
                  <th>Enseignant</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {subjects.map((subject) => {
                  return (
                    <tr key={subject._id}>
                      <td>{subject?.name}</td>
                      <td>{subject?.coefficient}</td>
                      <td>
                        {subject?.teacher?.user?.name
                          ? subject.teacher.user.name
                          : "Non affecté"}
                      </td>
                      <td>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setFormData(subject);
                            setAction("EDIT");
                          }}
                          variant={loading ? "disabled" : "contained"}
                        >
                          <SettingsIcon />
                        </IconButton>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        <section className={styles.pagination}>
          <Pagination
            onChange={(e, page) => setPage(page)}
            page={page}
            count={count}
          />
        </section>
      </div>
    </Layout>
  );
}

export default ClassroomSubjects;
