import { Button, TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import React from "react";

function AddRow(props) {
  const { addRow, setAction, start, end, setStart, setEnd } = props;

  return (
    <form onSubmit={addRow}>
      <h1>Ajouter un timing</h1>
      <div className="row">
        <div className="labeledInput">
          <label>Heure de début</label>
          <TimePicker
            inputFormat="hh:mm"
            ampm={false}
            value={start}
            onChange={(val) => {
              setStart(moment(val, "hh:mm"));
            }}
            renderInput={(params) => (
              <TextField
                required
                size="small"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "5px !important",
                    border: "1px solid #1d255e !important",
                  },
                }}
                {...params}
              />
            )}
          />
        </div>
        &nbsp; &nbsp; &nbsp;
        <div className="labeledInput">
          <label>Heure de fin</label>
          <TimePicker
            inputFormat="hh:mm"
            ampm={false}
            value={end}
            onChange={(val) => {
              setEnd(moment(val, "hh:mm"));
            }}
            renderInput={(params) => (
              <TextField
                required
                size="small"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "5px !important",
                    border: "1px solid #1d255e !important",
                  },
                }}
                {...params}
              />
            )}
          />
        </div>
      </div>
      <div className="btnRow">
        <Button variant="contained" type="submit">
          ajouter
        </Button>
        &nbsp;
        <Button
          variant="outlined"
          type="button"
          onClick={() => {
            setAction("");
            setStart("");
            setEnd("");
          }}
        >
          annuler
        </Button>
      </div>
    </form>
  );
}

export default AddRow;
