import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import React from "react";

function AddNote(props) {
  const { addNote, note, setNote, setAction, loadingForm } =
    props;

  return (
    <form onSubmit={addNote}>
      <h1>Ajouter une note</h1>
      <div className="labeledInput">
        <label>Type</label>
        <TextField
          required
          size="small"
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: "5px !important",
              border: "1px solid #1d255e !important",
            },
          }}
          onChange={(e) => setNote({ ...note, type: e.target.value })}
        />
      </div>
      <br />
      <div className="labeledInput">
        <label>Note</label>
        <TextField
          required
          size="small"
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: "5px !important",
              border: "1px solid #1d255e !important",
            },
          }}
          onChange={(e) => setNote({ ...note, note: e.target.value })}
        />
      </div>
      <br />
      <div className="labeledInput">
        <label>Coefficient</label>
        <TextField
          required
          size="small"
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: "5px !important",
              border: "1px solid #1d255e !important",
            },
          }}
          onChange={(e) => setNote({ ...note, coefficient: e.target.value })}
        />
      </div>
      <br />
      {loadingForm ? (
        <CircularProgress size="32px" />
      ) : (
        <div className="btnRow">
          <Button variant="contained" type="submit">
            ajouter
          </Button>
          &nbsp;
          <Button
            variant="outlined"
            type="button"
            onClick={() => {
              setNote({});
              setAction("");
            }}
          >
            annuler
          </Button>
        </div>
      )}
    </form>
  );
}

export default AddNote;
