import { Button, CircularProgress } from "@mui/material";
import React from "react";

function EditWithdrawal(props) {
  const {
    editWithdrawal,
    setAction,
    withdrawal,
    setWithdrawal,
    resetWithdrawal,
    loadingForm,
  } = props;
  return (
    <form onSubmit={editWithdrawal}>
      <div className="labeledInput">
        <label>Montant</label>
        <input
          className="defaultInput"
          type="number"
          step="any"
          min={1}
          required
          value={withdrawal.amount}
          onChange={(e) =>
            setWithdrawal({ ...withdrawal, amount: e.target.value })
          }
        />
      </div>
      <br />
      <div className="labeledInput">
        <label>Mode de paiement</label>
        <input
          className="defaultInput"
          type="text"
          required
          value={withdrawal.method}
          onChange={(e) =>
            setWithdrawal({ ...withdrawal, method: e.target.value })
          }
        />
      </div>
      <br />
      {loadingForm ? (
        <CircularProgress size="33px" />
      ) : (
        <div className="btnRow">
          <Button type="submit" variant="contained">
            modifier
          </Button>
          &nbsp;
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              setAction("");
              resetWithdrawal();
            }}
          >
            annuler
          </Button>
        </div>
      )}
    </form>
  );
}

export default EditWithdrawal;
