import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Dialog, Skeleton, Tooltip } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { getError } from "../../../../../config/config";
import styles from "../../../../../styles/admin/Schedule.module.css";
import Layout from "../../../components/Layout";
import Menu from "../../components/Menu";
import AddRow from "./components/AddRow";
import AddSession from "./components/AddSession";
import DeleteSession from "./components/DeleteSession";
import EditSession from "./components/EditSession";
import PrintIcon from "@mui/icons-material/Print";
import Button from "@mui/material/Button";

function StudySchedule(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentClassroom } = useSelector((state) => state.dashboard);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [action, setAction] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [session, setSession] = useState({
    dayIndex: 0,
    classroom: currentClassroom._id,
    timing: "",
    subject: "",
    teacher: "",
    room: "",
  });
  const [sessions, setSessions] = useState([]);
  const printRef = useRef(null);
  const addRow = (e) => {
    e.preventDefault();
    if (moment(end).isBefore(moment(start))) {
      return enqueueSnackbar(
        "l'heure de début doit toujours être supérieure à l'heure de début !",
        { variant: "warning" }
      );
    }
    setSessions([
      ...sessions,
      {
        timing: `${
          moment(start).format("hh:mm") + " - " + moment(end).format("hh:mm")
        }`,
        sessions: [
          {
            dayIndex: 0,
          },
          {
            dayIndex: 1,
          },
          {
            dayIndex: 2,
          },
          {
            dayIndex: 3,
          },
          {
            dayIndex: 4,
          },
          {
            dayIndex: 5,
          },
        ],
      },
    ]);
    setAction("");
    setStart("");
    setEnd("");
  };

  const resetSession = () => {
    setSession({
      dayIndex: 0,
      classroom: currentClassroom._id,
      timing: "",
      subject: "",
      room: "",
    });
  };

  const addSession = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      const res = await axios.post(`session/add`, session);
      console.log(res);
      fetchSessions();
      resetSession();
      setAction("");
      setLoadingForm(false);
      if (res.data.ok === false) {
        enqueueSnackbar(res.data.message, { variant: "error" });
      } else {
        enqueueSnackbar(res.data.message, { variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setLoadingForm(false);
    }
  };

  const publishSession = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      const res = await axios.put(`/session/publish/${currentClassroom._id}`);
      console.log(res);
      fetchSessions();
      setLoadingForm(false);
      if (res.data) {
        enqueueSnackbar(res.data.message, { variant: "success" });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setLoadingForm(false);
    }
  };

  const editSession = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      const { data } = await axios.put(`session/edit`, session);
      fetchSessions();
      resetSession();
      setAction("");
      setLoadingForm(false);
      enqueueSnackbar(data.message, { variant: "success" });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setLoadingForm(false);
    }
  };

  const deleteSession = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      const { data } = await axios.delete(`session/remove/${session._id}`);
      fetchSessions();
      resetSession();
      setAction("");
      setLoadingForm(false);
      enqueueSnackbar(data.message, { variant: "success" });
    } catch (error) {
      setLoadingForm(false);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const renderRow = (row) => {
    let block = [
      <td key={row.timing} style={{ backgroundColor: "#F5F3F3" }}>
        {row.timing}
      </td>,
    ];
    for (let i = 0; i < 6; i++) {
      if (row.sessions.find((s) => s.dayIndex === i)?.room) {
        block = [
          ...block,
          <Tooltip
            key={row.timing + i}
            title={
              !row.sessions.find((s) => s.dayIndex === i)?.room.available
                ? "salle non disponible"
                : null
            }
          >
            <td
              style={
                !row.sessions.find((s) => s.dayIndex === i)?.room.available
                  ? { backgroundColor: "#fff0f0" }
                  : null
              }
              className={styles.cell}
            >
              <p>
                Matière:&nbsp;
                {row.sessions.find((s) => s.dayIndex === i)?.subject?.name ||
                  "Non spécifié"}
              </p>
              <p>
                Salle:&nbsp;
                {row.sessions.find((s) => s.dayIndex === i)?.room?.name ||
                  "Non spécifié"}
              </p>
              <p>
                Enseignant
                {row.sessions.find((s) => s.dayIndex === i)?.subject?.teacher
                  .sex === "Féminin"
                  ? "e"
                  : ""}
                :&nbsp;
                {row.sessions.find((s) => s.dayIndex === i)?.subject?.teacher
                  .user.name || "Non spécifié"}
              </p>
              <div className={styles.edit}>
                <div
                  onClick={() => {
                    setSession(row.sessions.find((s) => s.dayIndex === i));
                    setAction("EDIT_SESSION");
                  }}
                  className={styles.icon}
                >
                  <Tooltip title="modifier la séance">
                    <EditIcon
                      sx={{ width: "15px", height: "15px", color: "orange" }}
                    />
                  </Tooltip>
                </div>
              </div>
              <div className={styles.delete}>
                <div
                  onClick={() => {
                    setSession(row.sessions.find((s) => s.dayIndex === i));
                    setAction("DELETE_SESSION");
                  }}
                  className={styles.icon}
                >
                  <Tooltip title="Supprimer la séance">
                    <DeleteOutlineIcon
                      sx={{ width: "15px", height: "15px", color: "red" }}
                    />
                  </Tooltip>
                </div>
              </div>
            </td>
          </Tooltip>,
        ];
      } else {
        block = [
          ...block,
          <td key={i}>
            <div className={styles.cellContainer}>
              <div className={styles.add}>
                <div
                  onClick={() => {
                    setSession({
                      ...session,
                      dayIndex: i,
                      timing: row.timing,
                      classroom: currentClassroom._id,
                    });
                    setAction("ADD_SESSION");
                  }}
                  className={styles.icon}
                >
                  <Tooltip sx={{ color: "green" }} title="Ajouter une séance">
                    <AddCircleOutlineIcon />
                  </Tooltip>
                </div>
              </div>
            </div>
          </td>,
        ];
      }
    }

    return block;
  };

  const fetchSessions = async () => {
    try {
      const { data } =
        await axios.get(`session/get/${currentClassroom._id}/${userInfo.user._id}
    
      
      `);

      if (data !== null) setSessions(data);
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setLoading(false);
    }
  };

  const fetchSubjects = async () => {
    try {
      const { data } = await axios.get(
        `/subject/getallbyclassroom/${currentClassroom._id}`
      );
      setSubjects(data);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const fetchRooms = async () => {
    try {
      const { data } = await axios.get(
        `/room/getall/${userInfo.establishment._id}`
      );
      setRooms(data);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  useEffect(() => {
    fetchSessions();
    fetchSubjects();
    fetchRooms();
  }, []);

  return (
    <Layout>
      <Dialog
        open={action !== ""}
        onClose={() => {
          setAction("");
          setStart("");
          setEnd("");
          resetSession();
        }}
      >
        <div style={{ maxHeight: "100%" }} className={"modal small"}>
          {action === "ADD_ROW" ? (
            <AddRow
              addRow={addRow}
              setAction={setAction}
              start={start}
              end={end}
              setStart={setStart}
              setEnd={setEnd}
            />
          ) : action === "ADD_SESSION" ? (
            <AddSession
              setAction={setAction}
              addSession={addSession}
              session={session}
              setSession={setSession}
              subjects={subjects}
              rooms={rooms}
              loadingForm={loadingForm}
              resetSession={resetSession}
            />
          ) : action === "EDIT_SESSION" ? (
            <EditSession
              setAction={setAction}
              editSession={editSession}
              session={session}
              setSession={setSession}
              subjects={subjects}
              rooms={rooms}
              loadingForm={loadingForm}
              resetSession={resetSession}
            />
          ) : action === "DELETE_SESSION" ? (
            <DeleteSession
              setAction={setAction}
              deleteSession={deleteSession}
              session={session}
              loadingForm={loadingForm}
              resetSession={resetSession}
            />
          ) : null}
        </div>
      </Dialog>
      <Menu />

      <div className={styles.container}>
        <Button
          variant="contained"
          style={{
            margin: "10px 0 ",
            color: "white",
          }}
          onClick={publishSession}
        >
          Publier
        </Button>
        {loading ? (
          <Skeleton variant="rectangular" width="100%">
            <div style={{ height: "calc(100vh - 200px)" }} />
          </Skeleton>
        ) : (
          <div className={styles.tableContainer}>
            <div ref={printRef} className="printBody">
              <table className="scheduleTable">
                <thead>
                  <tr>
                    <th>
                      <div className="dontprint">
                        <ReactToPrint
                          trigger={() => (
                            <IconButton variant="contained">
                              <PrintIcon color="primary" />
                            </IconButton>
                          )}
                          content={() => printRef.current}
                        />
                      </div>
                    </th>
                    <th>Lundi</th>
                    <th>Mardi</th>
                    <th>Mercredi</th>
                    <th>Jeudi</th>
                    <th>Vendredi</th>
                    <th>Samedi</th>
                  </tr>
                </thead>
                <tbody>
                  {sessions.map((row, index) => {
                    return <tr key={index}>{renderRow(row)}</tr>;
                  })}
                </tbody>
              </table>
            </div>
            <div style={{ width: "10%", textAlign: "center", padding: "10px" }}>
              <Tooltip title="Ajouter une ligne">
                <IconButton
                  onClick={() => {
                    setAction("ADD_ROW");
                  }}
                >
                  <AddCircleOutlineIcon color="primary" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default StudySchedule;
