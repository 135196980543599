import React from 'react'
import Layout from '../components/Layout'


const Stat = () => {
  return (
    <Layout>
          Stat
    </Layout>

  )
}

export default Stat