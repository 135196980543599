import { Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
// SUPER-ADMIN
import SuperAdminHome from "./pages/superadmin/Home";
import SuperAdminEstablishment from "./pages/superadmin/Establishment";
// ADMIN
import AdminHome from "./pages/admin/Home";
import Classrooms from "./pages/admin/schooling/Classrooms";
import Students from "./pages/admin/students/Students";
import Teachers from "./pages/admin/teachers/Teachers";
import Rooms from "./pages/admin/rooms/Rooms";
import Finance from "./pages/admin/finance/finance";
import Messenger from "./pages/admin/messenger/Messenger";
import Notifications from "./pages/admin/notifications/Notifications";
// SCHOOLING
import ClassroomStudents from "./pages/admin/schooling/classroom/students/ClassroomStudents";
import ClassroomSubjects from "./pages/admin/schooling/classroom/subjects/ClassroomSubjects";
import ClassroomSubjectsStudent from "./pages/student/schooling/classroom/subjects/ClassroomSubjects";
import StudentCard from "./pages/admin/schooling/classroom/students/StudentCard";
import StudentNotes from "./pages/admin/schooling/classroom/students/StudentNotes";
import TeacherCard from "./pages/admin/teachers/TeacherCard";
import StudySchedule from "./pages/admin/schooling/classroom/study-schedule/StudySchedule";
import ExamSchedule from "./pages/admin/schooling/classroom/exam-schedule/ExamSchedule";
// TEACHER
import TeacherHome from "./pages/teacher/Home";

import Emploi from "./pages/teacher/schooling/Emploi";
import TeacherNotification from "./pages/teacher/notifications/TeacherNotification";
import FinanceTeacher from "./pages/teacher/finance/TeacherFinance";
import Profile from "./pages/teacher/Profile/Profile";
import Stat from "./pages/teacher/statistique/Stat";
// STUDENT
import StudentHome from "./pages/student/Home";
// DEV
import Register from "./pages/Register";
import StudyScheduleStudent from "./pages/student/schooling/classroom/study-schedule/StudySchedule";
import ExamScheduleStudent from "./pages/student/schooling/classroom/exam-schedule/ExamSchedule";
import ProfileCard from "./pages/student/schooling/classroom/students/ProfileCard";
import StudentMessenger from "./pages/student/messenger/Messenger";
import StudentNotifications from "./pages/student/notifications/StudentNotifications";
import MyNotes from "./pages/student/schooling/classroom/notes/MyNotes";
import WorkSchedule from "./pages/admin/teachers/work-schedule/WorkSchedule";
import StudentFinance from "./pages/student/finance/StudentFinance";
import TeacherFinance from "./pages/teacher/finance/TeacherFinance";

export const superAdminRoutes = (
  <>
    <Route path="/" element={<SuperAdminHome />} />
    <Route path="/establishment/:id" element={<SuperAdminEstablishment />} />
    <Route path="*" element={<Navigate to="/" replace />} />
    <Route path="/dashboard" element={<AdminHome />} />
    <Route path="/schooling/classrooms" element={<Classrooms />} />
    <Route path="/students" element={<Students />} />
    <Route path="/students/card/:id" element={<StudentCard />} />
    <Route path="/students/notes/:id" element={<StudentNotes />} />
    <Route path="/teachers/card/:id" element={<TeacherCard />} />
    <Route path="/teachers/workschedule/:id" element={<WorkSchedule />} />
    <Route path="/teachers" element={<Teachers />} />
    <Route path="/rooms" element={<Rooms />} />
    <Route path="/finance" element={<Finance />} />
    <Route path="/messenger" element={<Messenger />} />
    <Route path="/notifications" element={<Notifications />} />
    {/* SCHOOLING */}
    <Route
      path="/schooling/classroom/students"
      element={<ClassroomStudents />}
    />
    <Route
      path="/schooling/classroom/subjects"
      element={<ClassroomSubjects />}
    />
    <Route
      path="/schooling/classroom/study-schedule"
      element={<StudySchedule />}
    />
    <Route
      path="/schooling/classroom/exam-schedule"
      element={<ExamSchedule />}
    />
  </>
);
export const adminRoutes = (
  <>
    <Route path="/" element={<AdminHome />} />
    <Route path="/schooling/classrooms" element={<Classrooms />} />
    <Route path="/students" element={<Students />} />
    <Route path="/students/card/:id" element={<StudentCard />} />
    <Route path="/students/notes/:id" element={<StudentNotes />} />
    <Route path="/teachers/card/:id" element={<TeacherCard />} />
    <Route path="/teachers/workschedule/:id" element={<WorkSchedule />} />
    <Route path="/teachers" element={<Teachers />} />
    <Route path="/rooms" element={<Rooms />} />
    <Route path="/finance" element={<Finance />} />
    <Route path="/messenger" element={<Messenger />} />
    <Route path="/notifications" element={<Notifications />} />
    {/* SCHOOLING */}
    <Route
      path="/schooling/classroom/students"
      element={<ClassroomStudents />}
    />
    <Route
      path="/schooling/classroom/subjects"
      element={<ClassroomSubjects />}
    />
    <Route
      path="/schooling/classroom/study-schedule"
      element={<StudySchedule />}
    />
    <Route
      path="/schooling/classroom/exam-schedule"
      element={<ExamSchedule />}
    />
    <Route path="*" element={<Navigate to="/" replace />} />
  </>
);

export const teacherRoutes = (
  <>
    <Route path="/" element={<TeacherHome />} />
    <Route path="/messenger" element={<Messenger />} />
    <Route path="/notifications" element={<TeacherNotification />} />
    <Route path="/finance-teacher" element={<FinanceTeacher />} />
    <Route path="/profile" element={<Profile />} />
    <Route path="/finance" element={<TeacherFinance />} />
    <Route path="/stat" element={<Stat />} />
    <Route path="/gestion-scolaire" element={<Emploi />} />
    <Route path="*" element={<Navigate to="/" replace />} />
  </>
);

export const studentRoutes = (
  <>
    <Route path="/" element={<StudentHome />} />
    <Route path="/profile" element={<ProfileCard />} />
    <Route path="/classroom/subjects" element={<ClassroomSubjectsStudent />} />
    <Route
      path="/classroom/study-schedule"
      element={<StudyScheduleStudent />}
    />
    <Route path="/classroom/exam-schedule" element={<ExamScheduleStudent />} />
    <Route path="/notes" element={<MyNotes />} />
    <Route path="/finance" element={<StudentFinance />} />
    <Route path="/notifications" element={<StudentNotifications />} />
    <Route path="/messenger" element={<StudentMessenger />} />
    <Route path="*" element={<Navigate to="/" replace />} />
  </>
);

export const disconnectedRoutes = (
  <>
    <Route path="/" element={<Home />} />
    <Route path="/super-admin-register" element={<Register />} />
    <Route path="*" element={<Navigate to="/" replace />} />
  </>
);
