import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, MenuItem, Dialog, Select, Tooltip } from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { getError } from "../../../../../config/config";
import styles from "../../../../../styles/admin/ExamSchedule.module.css";
import Layout from "../../../components/Layout";
import Menu from "../../components/Menu";
import PrintIcon from "@mui/icons-material/Print";
import AddNote from "./components/AddNote";
import { useParams } from "react-router-dom";
import EditNote from "./components/EditNote";
import DeleteNote from "./components/DeleteNote";

function StudentNotes(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { currentClassroom } = useSelector((state) => state.dashboard);
  const { currentSeason } = useSelector((state) => state.dashboard);
  const [action, setAction] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);
  const [period, setPeriod] = useState(
    currentSeason?.type === "trimestrielle" ? "trimestre 1" : "semestre 1"
  );
  const [total, setTotal] = useState();
  const [totalCoefficient, setTotalCoefficient] = useState();
  const [moyenne, setMoyenne] = useState();
  const [note, setNote] = useState({});
  const [notes, setNotes] = useState([]);
  const printRef = useRef(null);
  const { id } = useParams();

  const addNote = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post("/note/add", note);
      enqueueSnackbar(data.message, { variant: "success" });
      setNote({ type: "", note: 0 });
      fetchNotes();
      setAction("");
      setLoadingForm(false);
    } catch (error) {
      setLoadingForm(false);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const editNote = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put("/note/edit", note);
      enqueueSnackbar(data.message, { variant: "success" });
      setNote({});
      fetchNotes();
      setAction("");
      setLoadingForm(false);
    } catch (error) {
      setLoadingForm(false);
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const deleteNote = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.delete(`/note/remove/${note._id}`);
      enqueueSnackbar(data.message, { variant: "success" });
      setNote({});
      fetchNotes();
      setAction("");
      setLoadingForm(false);
    } catch (error) {
      setLoadingForm(false);
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const fetchSubjects = async () => {
    try {
      const { data } = await axios.get(
        `/subject/getallbyclassroom/${currentClassroom._id}`
      );
      setSubjects(data);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const fetchNotes = async () => {
    try {
      const { data } = await axios.get(
        `/note/get/${id}?season=${currentSeason._id}&period=${period}`
      );
      setNotes(data.notes);
      setTotal(data.total);
      setTotalCoefficient(data.totalCoefficient);
      setMoyenne(data.moyenne);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  useEffect(() => {
    fetchSubjects();
    fetchNotes();
  }, [period]);

  return (
    <Layout>
      <Dialog
        open={action !== ""}
        onClose={() => {
          setAction("");
        }}
      >
        <div
          style={
            action === "ADD_NOTE" || action === "EDIT_NOTE"
              ? { maxHeight: "100%", top: "20vh" }
              : { maxHeight: "100%" }
          }
          className={"modal small"}
        >
          {action === "ADD_NOTE" ? (
            <AddNote
              addNote={addNote}
              setAction={setAction}
              note={note}
              setNote={setNote}
              loadingForm={loadingForm}
            />
          ) : action === "EDIT_NOTE" ? (
            <EditNote
              editNote={editNote}
              setAction={setAction}
              note={note}
              setNote={setNote}
              loadingForm={loadingForm}
            />
          ) : action === "DELETE_NOTE" ? (
            <DeleteNote
              deleteNote={deleteNote}
              setAction={setAction}
              note={note}
              setNote={setNote}
              loadingForm={loadingForm}
            />
          ) : null}
        </div>
      </Dialog>
      <Menu />
      <div className={styles.container}>
        {currentSeason?.type === "trimestrielle" && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              padding: "10px",
            }}
          >
            <Select
              required
              value={period}
              onChange={(e) => {
                setPeriod(e.target.value);
              }}
              size="small"
              sx={{
                backgroundColor: "transparent",
                height: "40px",
                margin: "auto 0",
                width: "20%",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "5px !important",
                  border: "0.5px solid #283487 !important",
                },
              }}
            >
              <MenuItem
                style={{
                  marginBottom: "5px",
                }}
                value={"trimestre 1"}
              >
                trimestre 1
              </MenuItem>
              <MenuItem
                style={{
                  marginBottom: "5px",
                }}
                value={"trimestre 2"}
              >
                trimestre 2
              </MenuItem>
              <MenuItem
                style={{
                  marginBottom: "5px",
                }}
                value={"trimestre 3"}
              >
                trimester 3
              </MenuItem>
            </Select>
            {total > 0 && (
              <div>
                <p>Total: {total}</p>
                <p>Coefficient: {totalCoefficient}</p>
                <p>Moyenne: {moyenne.toFixed(2)}</p>
              </div>
            )}
          </div>
        )}
        {currentSeason?.type === "semestrielle" && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              padding: "10px",
            }}
          >
            <Select
              required
              value={period}
              onChange={(e) => {
                setPeriod(e.target.value);
              }}
              size="small"
              sx={{
                backgroundColor: "transparent",
                width: "20%",
                height: "40px",
                margin: "auto 0",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "5px !important",
                  border: "0.5px solid #283487 !important",
                },
              }}
            >
              <MenuItem
                style={{
                  marginBottom: "5px",
                }}
                value={"semestre 1"}
              >
                semestre 1
              </MenuItem>
              <MenuItem
                style={{
                  marginBottom: "5px",
                }}
                value={"semestre 2"}
              >
                semestre 2
              </MenuItem>
            </Select>
            {total > 0 && (
              <div>
                <p>Total: {total}</p>
                <p>Coefficient: {totalCoefficient}</p>
                <p>Moyenne: {moyenne.toFixed(2)}</p>
              </div>
            )}
          </div>
        )}
        <div className={styles.tableContainer}>
          <div ref={printRef} className="printBody">
            <table className="scheduleTable">
              <thead>
                <tr>
                  <th
                    style={{
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "-2px",
                        left: "0px",
                      }}
                      className="dontprint"
                    >
                      <ReactToPrint
                        trigger={() => (
                          <IconButton variant="contained">
                            <PrintIcon color="primary" />
                          </IconButton>
                        )}
                        content={() => printRef.current}
                      />
                    </div>
                    Matiéres
                  </th>
                  <th>Notes</th>
                  {/* {exams.length > 0 ? (
                      <span className={styles.reset}>
                        <Tooltip
                          sx={{ color: "red" }}
                          title="supprimer toutes les notes"
                        >
                          <IconButton
                            size="small"
                            onClick={() => {
                              setAction("DELETE_SCHEDULE");
                            }}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </span>
                    ) : null} */}
                </tr>
              </thead>
              <tbody>
                {subjects.map((row) => {
                  return (
                    <tr key={row._id}>
                      <td style={{ width: "20%", backgroundColor: "#f5f3f3" }}>
                        <p>Matiére: {row.name}</p>
                        <p style={{ textTransform: "none" }}>
                          Enseignant(e): {row.teacher.user.name}
                        </p>
                        <p>Coefficient: {row.coefficient}</p>
                      </td>
                      <td style={{ width: "60%" }}>
                        {notes.map(
                          (note) =>
                            note.subject === row._id &&
                            note.user === id && (
                              <div key={note._id} className={styles.row}>
                                <div className={styles.col}>
                                  <p>Type: {note.type}</p>
                                  <p>Coefficient: {note.coefficient}</p>
                                </div>
                                <div className={styles.col}>
                                  <p>{note.note}</p>
                                </div>
                                <div className={styles.edit}>
                                  <Tooltip
                                    color="secondary"
                                    title="modifier la note"
                                  >
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        setNote({
                                          ...note,
                                          user: id,
                                          subject: row._id,
                                        });
                                        setAction("EDIT_NOTE");
                                      }}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                                <div className={styles.delete}>
                                  <Tooltip
                                    sx={{ color: "red" }}
                                    title="supprimer la note"
                                  >
                                    <IconButton
                                      size="small"
                                      onClick={() => {
                                        setNote(note);
                                        setAction("DELETE_NOTE");
                                      }}
                                    >
                                      <DeleteOutlineIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </div>
                            )
                        )}

                        <div className={styles.add}>
                          <Tooltip
                            sx={{ color: "green" }}
                            title="ajouter une note"
                          >
                            <IconButton
                              size="small"
                              onClick={() => {
                                setNote({
                                  ...note,
                                  user: id,
                                  subject: row._id,
                                  season: currentSeason._id,
                                  period: period,
                                });
                                setAction("ADD_NOTE");
                              }}
                            >
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default StudentNotes;
