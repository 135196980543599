import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

function Layout(props) {
  const { sidemenu } = useSelector((state) => state.dashboard);
  const { pathname } = useLocation();
  return (
    <>
      <Navbar />
      <div
        style={{
          width: "100%",
          display: "flex",
          marginTop: "80px",
        }}
      >
        <div
          style={{
            position: "fixed",
            top: "80px",
            height: "100vh",
            transition: "all 0.3s",
            width: sidemenu ? "20%" : "50px",
          }}
        >
          <Sidebar />
        </div>
        <div
          style={
            pathname === "/messenger"
              ? {
                  padding: "0px",
                  marginTop: "-80px",
                  transition: "all 0.3s",
                  width: sidemenu ? "80%" : "calc(100% - 50px)",
                  marginLeft: sidemenu ? "20%" : "50px",
                }
              : {
                  padding: "20px",
                  minHeight: "calc(100vh - 80px)",
                  transition: "all 0.3s",
                  width: sidemenu ? "80%" : "calc(100% - 50px)",
                  marginLeft: sidemenu ? "20%" : "50px",
                }
          }
        >
          {props.children}
        </div>
      </div>
    </>
  );
}

export default Layout;
