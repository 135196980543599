import FindInPageIcon from "@mui/icons-material/FindInPage";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Button,
  CircularProgress,
  IconButton,
  Dialog,
  Pagination,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getError } from "../../../../../config/config";
import styles from "../../../../../styles/admin/Dashboard.module.css";
import Layout from "../../../components/Layout";
import Menu from "../../components/Menu";
import AddStudent from "./components/AddStudent";
import EditStudent from "./components/EditStudent";
import NotifyStudent from "./components/NotifyStudent";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import Messenger from "../../../components/Messenger";
import DescriptionIcon from "@mui/icons-material/Description";

function ClassroomStudents(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentClassroom } = useSelector((state) => state.dashboard);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [totalStudents, setTotalStudents] = useState(0);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [selected, setSelected] = useState("");
  const [students, setStudents] = useState([]);
  const [student, setStudent] = useState({});
  const [action, setAction] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    establishment: userInfo.establishment._id,
    classroom: currentClassroom._id,
    avatar: "",
    name: "",
    email: "",
    password: "",
    phone: "",
    address: "",
    sex: "",
    birthday: "",
    fatherName: "",
    MotherName: "",
    orphin: false,
    maritalStatus: "",
    fatherPhone: "",
    motherPhone: "",
    motherProfession: "",
    fatherProfession: "",
    siblings: 0,
    siblingsStudents: 0,
    authorizedCompany: [],
  });

  const [notification, setNotification] = useState({
    sender:  userInfo.establishment._id,
    senderAccount: userInfo.user._id,
    receiver: student._id,
    subject: "",
    message: "",
    documents: [],
  });

  const fetchStudents = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(`/student/getbyclassroom`, {
        id: userInfo.establishment._id,
        classroom: currentClassroom._id,
        page: page,
        phone: phone,
        name: name,
      });
      setStudents(data.students);
      setCount(data.count);
      setTotalStudents(data.totalStudents);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const addStudent = async (e) => {
    setLoadingForm(true);
    e.preventDefault();
    try {
      const { data } = await axios.post("/student/add", formData);
      enqueueSnackbar(data.message, { variant: "success" });
      setFormData({
        establishment: userInfo.establishment._id,
        classroom: currentClassroom._id,
        avatar: "",
        name: "",
        email: "",
        password: "",
        phone: "",
        address: "",
        sex: "",
        birthday: "",
        fatherName: "",
        MotherName: "",
        orphin: false,
        maritalStatus: "",
        fatherPhone: "",
        motherPhone: "",
        motherProfession: "",
        fatherProfession: "",
        siblings: 0,
        siblingsStudents: 0,
        authorizedCompany: [],
      });
      fetchStudents();
      setLoadingForm(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  const editStudent = async (e) => {
    setLoadingForm(true);
    e.preventDefault();
    try {
      const { data } = await axios.put("/student/edit", formData);
      enqueueSnackbar(data.message, { variant: "success" });
      setFormData({
        establishment: userInfo.establishment._id,
        classroom: currentClassroom._id,
        avatar: "",
        name: "",
        email: "",
        password: "",
        phone: "",
        address: "",
        sex: "",
        birthday: "",
        fatherName: "",
        MotherName: "",
        orphin: false,
        maritalStatus: "",
        fatherPhone: "",
        motherPhone: "",
        motherProfession: "",
        fatherProfession: "",
        siblings: 0,
        siblingsStudents: 0,
        authorizedCompany: [],
      });
      fetchStudents();
      setLoadingForm(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  const notifyStudent = async (e) => {
    setLoadingForm(true);
    e.preventDefault();
    try {
     
      const { data } = await axios.post("/notification/send", notification);
      enqueueSnackbar(data.message, { variant: "success" });
      setNotification({
        sender:  userInfo.establishment._id,
        senderAccount: userInfo.user._id,
        receiver: "",
        subject: "",
        message: "",
        documents: [],
      });
      setSelected("");
      setLoadingForm(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  const resetNotif = () => {
    setNotification({
      sender:  userInfo.establishment._id,
      senderAccount: userInfo.user._id,
      receiver: student.user._id,
      subject: "",
      message: "",
      documents: [],
    });
  };

  useEffect(() => {
    if (userInfo && currentClassroom) fetchStudents();
  }, [currentClassroom, userInfo, page, name, phone]);

  return (
    <Layout>
      <Dialog
        maxWidth="lg"
        open={action !== ""}
        onClose={() => {
          setFormData({
            establishment: userInfo.establishment._id,
            classroom: currentClassroom._id,
            avatar: "",
            name: "",
            email: "",
            password: "",
            phone: "",
            address: "",
            sex: "",
            birthday: "",
            fatherName: "",
            motherName: "",
            orphin: false,
            maritalStatus: "",
            fatherPhone: "",
            motherPhone: "",
            motherProfession: "",
            fatherProfession: "",
            siblings: 0,
            siblingsStudents: 0,
            authorizedCompany: [],
          });
          setAction("");
          resetNotif();
        }}
      >
        <div
          className={
            action === "ADD" || action === "EDIT"
              ? "modal large"
              : "modal small"
          }
          style={
            action === "NOTIFY" ? { maxHeight: "100%", top: "10vh" } : null
          }
        >
          {action === "ADD" ? (
            <AddStudent
              loadingForm={loadingForm}
              formData={formData}
              setFormData={setFormData}
              setAction={setAction}
              addStudent={addStudent}
              establishment={userInfo.establishment}
              classroom={currentClassroom._id}
            />
          ) : action === "EDIT" ? (
            <EditStudent
              loadingForm={loadingForm}
              formData={formData}
              setFormData={setFormData}
              setAction={setAction}
              student={student}
              editStudent={editStudent}
              establishment={userInfo.establishment}
              classroom={currentClassroom._id}
            />
          ) : action === "NOTIFY" ? (
            <NotifyStudent
              loadingForm={loadingForm}
              setAction={setAction}
              notification={notification}
              setNotification={setNotification}
              notifyStudent={notifyStudent}
              student={student}
              selected={selected}
              setSelected={setSelected}
              resetNotif={resetNotif}
            />
          ) : action === "MESSAGE" ? (
            <Messenger setAction={setAction} receiver={student.user._id} />
          ) : null}
        </div>
      </Dialog>
      <Menu />
      <div style={{ marginTop: "50px" }} className={styles.container}>
        <section className={styles.header}>
          <Typography variant="h4" component="h1" gutterBottom>
            Liste des élèves
            <Typography
              style={{ color: "#0058A5" }}
              variant="body1"
              gutterBottom
            >
              Niveau : {currentClassroom.level} - Groupe :{" "}
              {currentClassroom.group}
            </Typography>
          </Typography>

          {totalStudents >= currentClassroom.capacity ? (
            <p style={{ color: "red" }}>
              La classe est complète, veuillez augmenter la limite pour pouvoir
              ajouter des élèves !
            </p>
          ) : (
            <Button
              onClick={() => {
                setAction("ADD");
              }}
              variant={loading ? "disabled" : "contained"}
            >
              ajouter
            </Button>
          )}
        </section>
        <section className={styles.filter}>
          <div
            style={{ width: "20%", marginRight: "10px" }}
            className="labeledInput"
          >
            <label>Nom / Prénom</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="grayInput"
            />
          </div>
          <div
            style={{ width: "20%", marginRight: "10px" }}
            className="labeledInput"
          >
            <label>Téléphone</label>
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="grayInput"
            />
          </div>
          {name !== "" || phone !== "" ? (
            <Button
              onClick={() => {
                setName("");
                setPhone("");
              }}
              size="large"
              variant="outlined"
            >
              <RotateLeftIcon />
            </Button>
          ) : null}
        </section>
        {loading ? (
          <div className="spinner">
            <CircularProgress />
          </div>
        ) : (
          <div
            style={{ maxHeight: "calc(100vh - 401px)" }}
            className={styles.tableContainer}
          >
            <table className="defaultTable">
              <thead>
                <tr>
                  <th>Nom et prénom</th>
                  <th>Date de naissance</th>
                  <th>Email</th>
                  <th>Téléphone</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {students.map((student) => {
                  return (
                    <tr key={student._id}>
                      <td>{student.user.name}</td>
                      <td>{student.birthday}</td>
                      <td>{student.user.email}</td>
                      <td>{student.user.phone}</td>
                      <td style={{ width: "25%" }}>
                        <Tooltip title="modifier">
                          <IconButton
                            onClick={() => {
                              setStudent(student);
                              setFormData({
                                ...student,
                                ...student.user,
                                _id: student._id,
                              });
                              setAction("EDIT");
                            }}
                            sx={{ color: "#0058A5" }}
                          >
                            <SettingsIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="envoyer message">
                          <IconButton
                            onClick={() => {
                              setStudent(student);
                              setAction("MESSAGE");
                            }}
                            sx={{ color: "#0077B4" }}
                          >
                            <LocalPostOfficeIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="fiche">
                          <IconButton
                            onClick={() => {
                              navigate(`/students/card/${student._id}`);
                            }}
                            sx={{ color: "#0095B6" }}
                          >
                            <FindInPageIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Relevés des notes">
                          <IconButton
                            onClick={() => {
                              navigate(`/students/notes/${student._id}`);
                            }}
                            sx={{ color: "#00B0B0" }}
                          >
                            <DescriptionIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="notifier">
                          <IconButton
                            onClick={() => {
                              setStudent(student);
                              setNotification({
                                ...notification,
                                receiver: student.user._id,
                              });
                              setAction("NOTIFY");
                            }}
                            sx={{ color: "#5DC9AB" }}
                          >
                            <NotificationsActiveIcon />
                          </IconButton>
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        <section className={styles.pagination}>
          <Pagination
            onChange={(e, page) => setPage(page)}
            page={page}
            count={count}
          />
        </section>
      </div>
    </Layout>
  );
}

export default ClassroomStudents;
