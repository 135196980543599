import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../styles/admin/Sidebar.module.css";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import LineAxisIcon from "@mui/icons-material/LineAxis";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { Link, useLocation } from "react-router-dom";
import { io } from "socket.io-client";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
function Sidebar(props) {
  const { sidemenu } = useSelector((state) => state.dashboard);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (userInfo) {
      if (
        userInfo?.user.role === "STUDENT" ||
        userInfo?.user.role === "TEACHER"
      ) {
        var socket = io.connect(process.env.REACT_APP_SERVER_URL);
        // JOIN-ROOM
        socket.emit("join-room", userInfo.user._id);
        // INCOMING-MESSAGE
        socket.on("update-chat", () => {
          // fetchUnreadCountPatient();
        });
        // fetchUnreadCountPatient();
      } else if (userInfo?.user.role === "ADMIN") {
        var socket = io.connect(process.env.REACT_APP_SERVER_URL);
        // JOIN-ROOM
        socket.emit("join-room", userInfo.establishment._id);
        // INCOMING-MESSAGE
        socket.on("update-chat", () => {
          // fetchUnreadCount();
        });
        socket.on("update-demands", () => {
          // fetchCancelsCount();
          // fetchDemandsCount();
        });
        // fetchUnreadCount();
      }
    }
  }, [userInfo]);

  return (
    <div
      className={
        sidemenu ? styles.sidebar : `${styles.sidebar} ${styles.closed}`
      }
    >
      <div className={styles.header}>
        <h1></h1>
        <div className={styles.icon}>
          {sidemenu ? (
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() =>
                dispatch({ type: "TOGGLE_SIDEMENU", payload: false })
              }
            />
          ) : (
            <MenuIcon
              style={{ cursor: "pointer" }}
              onClick={() =>
                dispatch({ type: "TOGGLE_SIDEMENU", payload: true })
              }
            />
          )}
        </div>
        <div className={styles.hr} />
      </div>
      <Link to="/dashboard">
        <li className={pathname === "/dashboard" ? styles.active : null}>
          <LineAxisIcon />
          {sidemenu ? "Statistiques" : null}
        </li>
      </Link>
      <Link to="/schooling/classrooms">
        <li
          className={
            pathname.includes("/schooling") ||
              pathname.includes("students/card")
              ? styles.active
              : null
          }
        >
          <TextSnippetIcon />
          {sidemenu ? "Classes" : null}
        </li>
      </Link>
      <Link to="/students">
        <li className={pathname === "/students" ? styles.active : null}>
          <PeopleAltIcon />
          {sidemenu ? "Etudiants" : null}
        </li>
      </Link>
      <Link to="/teachers">
        <li
          className={
            pathname === "/teachers" || pathname.includes("teachers/card")
              ? styles.active
              : null
          }
        >
          <BusinessCenterIcon />
          {sidemenu ? "Enseignants" : null}
        </li>
      </Link>
      <Link to="/rooms">
        <li className={pathname === "/rooms" ? styles.active : null}>
          <MeetingRoomIcon />
          {sidemenu ? "Salles" : null}
        </li>
      </Link>
      <Link to="/finance">
        <li className={pathname === "/finance" ? styles.active : null}>
          <AccountBalanceWalletIcon />
          {sidemenu ? "Gestion financière" : null}
        </li>
      </Link>
      <Link to="/notifications">
        <li className={pathname === "/notifications" ? styles.active : null}>
        <NotificationsActiveIcon className={styles.MenuIcons} />
          {sidemenu ? "Notifications" : null}

        </li>
      </Link>
      <Link to="/messenger">
        <li className={pathname === "/messenger" ? styles.active : null}>
          <QuestionAnswerIcon />
          {sidemenu ? "Messenger" : null}
        </li>
      </Link>
    </div>
  );
}

export default Sidebar;
