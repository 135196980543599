import React from "react";
import Layout from "../teacher/components/Layout";

function Home(props) {
  return <div>

      <Layout />

    
    </div>;
}

export default Home;
