import { Button } from "@mui/material";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import styles from "../../../../../../styles/admin/StudentCard.module.css";
import moment from "moment";
import { getMonth } from "../../../../../../config/config";
import ReactToPrint from "react-to-print";

function Receipt(props) {
  const printRef = useRef();
  const { setAction, resetDeposit, deposit, student } = props;
  const { currentSeason } = useSelector((state) => state.dashboard);
  const { userInfo } = useSelector((state) => state.auth);
  return (
    <div ref={printRef} className={styles.receipt}>
      <br />
      <br />
      <h1>
        {userInfo.establishment.type}&nbsp;
        {userInfo.establishment.name}
      </h1>
      <h2>
        Adresse: <a>{userInfo.establishment.address}</a>
      </h2>
      <h2>
        Email: <a>{userInfo.establishment.email}</a>
      </h2>
      <h2>
        Téléphone: <a>{userInfo.establishment.phone}</a>
      </h2>
      <h2>
        Année scolaire: <a>{currentSeason.year}</a>
      </h2>
      <br />
      <h1 style={{ textAlign: "center" }}>
        {deposit.label} Pour le mois "{getMonth(deposit.monthIndex)}"
      </h1>
      <br />
      <div className={styles.hr} />
      <h2>
        élève: <a>{student.user.name}</a>
      </h2>
      <h2>
        classe:{" "}
        <a>
          {student.classroom.level}-{student.classroom.group}
        </a>
      </h2>
      <h2>
        Montant: <a>{deposit.amount} DT</a>
      </h2>
      <h2>
        Modalité de paiement: <a>{deposit.method}</a>
      </h2>
      <h2>
        Date de paiement:{" "}
        <a>{moment(deposit.createdAt).format("DD-MM-yyyy")}</a>
      </h2>
      <div className={styles.hr} />
      <br />
      <h2 style={{ textAlign: "right" }}>
        <a>Agent: "{deposit.admin.user.name}"</a>
      </h2>
      <h2 style={{ textAlign: "right" }}>
        <a>..................................................</a>
      </h2>
      <h2 style={{ textAlign: "right" }}>
        <a>
          {userInfo.establishment.address} le {moment().format("DD-MM-yyyy")}
        </a>
      </h2>
      <div className="btnRow">
        <div className={`dontprint`}>
          <ReactToPrint
            trigger={() => <Button variant="contained">imprimer</Button>}
            content={() => printRef.current}
          />
          &nbsp;
          <Button
            onClick={() => {
              resetDeposit();
              setAction("");
            }}
            variant="outlined"
          >
            annuler
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Receipt;
