import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Routes } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import axios from "axios";
import { getUrl } from "./config/config";
import { useSelector } from "react-redux";
import {
  disconnectedRoutes,
  studentRoutes,
  teacherRoutes,
  superAdminRoutes,
  adminRoutes,
} from "./routes";
import AOS from "aos";
import "aos/dist/aos.css";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
function App() {
  AOS.init({
    offset: 120, // offset (in px) from the original trigger point
    delay: 100, // values from 0 to 3000, with step 50ms
    duration: 600, // values from 0 to 3000, with step 50ms
    easing: "ease", // default easing for AOS animations
    once: false,
  });
  const { userInfo } = useSelector((state) => state.auth);
  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = getUrl();
  axios.defaults.headers.common["Authorization"] = userInfo?.token;

  const theme = createTheme({
    palette: {
      primary: {
        main: "#283487",
      },
      secondary: {
        main: "#FDCF1B",
      },
      third: {
        main: "#06CB90",
      },
      fourth: {
        main: "#cb0664",
      },
    },
  });
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <Routes>
            {userInfo?.user?.role === "SUPER-ADMIN"
              ? superAdminRoutes
              : userInfo?.user?.role === "ADMIN"
              ? adminRoutes
              : userInfo?.user?.role === "STUDENT"
              ? studentRoutes
              : userInfo?.user?.role === "TEACHER"
              ? teacherRoutes
              : disconnectedRoutes}
          </Routes>
        </ThemeProvider>
      </LocalizationProvider>
    </SnackbarProvider>
  );
}

export default App;
