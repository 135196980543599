import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "../../styles/student/Home.module.css";
import Layout from "./components/Layout";
import { getError } from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

function Home(props) {
  const { userInfo } = useSelector((state) => state.auth);
  const { currentSeason } = useSelector((state) => state.dashboard);
  const [count, setCount] = useState({ students: 0, teachers: 0, classes: 0 });
  const [wallet, setWallet] = useState({ deposits: 0, withdrawals: 0 });
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const fetchNotifications = async () => {
    try {
      const { data } = await axios.get(`/notification/get/${userInfo._id}`);
      dispatch({ type: "UPDATE_NOTIFICATIONS", payload: data.count });
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <Layout>
      <div className={styles.container}>
        <h1>Bienvenue {userInfo.user.name} !</h1>
        <div className={styles.stats}>
          <span>Bientôt, les statistiques seront disponibles</span>
        </div>
      </div>
    </Layout>
  );
}

export default Home;
