import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../styles/admin/Navbar.module.css";
import axios from "axios";
import { useSnackbar } from "notistack";
import { getError } from "../../../config/config.js";
import {
  Button,
  CircularProgress,
  MenuItem,
  Dialog,
  Select,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Link } from "react-router-dom";

function Navbar(props) {
  const dispatch = useDispatch();
  const { currentSeason } = useSelector((state) => state.dashboard);
  const { userInfo } = useSelector((state) => state.auth);
  const [seasons, setSeasons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({
    year: "",
    type: "",
    establishment: userInfo?.establishment._id,
  });
  const { year, type } = formData;

  const getSeasons = async () => {
    try {
      const { data } = await axios.get(
        `/season/getAll/${userInfo.establishment._id}`
      );
      setSeasons(data);
      if (data.length > 0 && currentSeason === null)
        dispatch({
          type: "SET_CURRENT_SEASON",
          payload: data[0],
        });
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoading(false);
    }
  };

  const addSeason = async (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      const { data } = await axios.post(`/season/add`, formData);
      setLoadingForm(false);
      setOpenForm(false);
      enqueueSnackbar(data.message, { variant: "success" });
      setFormData({
        year: "",
        type: "",
        establishment: userInfo?.establishment._id,
      });
      getSeasons();
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  useEffect(() => {
    if (userInfo) if (currentSeason) setLoading(false);
    getSeasons();
  }, [userInfo, currentSeason]);

  return (
    <>
      <Dialog open={openForm} onClose={() => setOpenForm(false)}>
        <div style={{ top: "20vh", maxHeight: "60vh" }} className="modal small">
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              color: "#283487",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            Ajouter une année scolaire
          </Typography>
          <form onSubmit={addSeason}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Année"
                  required
                  fullWidth
                  pattern="\d{4}-\d{4}"
                  value={year}
                  onChange={(e) =>
                    setFormData({ ...formData, year: e.target.value })
                  }
                  type="text"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel>Type</InputLabel>
                  <Select
                    label="Type"
                    required
                    value={type}
                    onChange={(e) => {
                      setFormData({ ...formData, type: e.target.value });
                    }}
                    sx={{
                      backgroundColor: "transparent",
                      width: "100%",
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "5px !important",
                        border: "0.5px solid #283487 !important",
                      },
                    }}
                  >
                    <MenuItem
                      style={{
                        marginBottom: "5px",
                      }}
                      value={"trimestrielle"}
                    >
                      Trimestrielle
                    </MenuItem>
                    <MenuItem
                      style={{
                        marginBottom: "5px",
                      }}
                      value={"semestrielle"}
                    >
                      Semestrielle
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} container justifyContent="flex-end">
                {loadingForm ? (
                  <CircularProgress size="35px" />
                ) : (
                  <>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={() => props.activateMember()}
                    >
                      Ajouter
                    </Button>
                    <Button
                      type="button"
                      onClick={() => setOpenForm(false)}
                      variant="outlined"
                      style={{ marginLeft: "10px" }}
                    >
                      Annuler
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          </form>
        </div>
      </Dialog>
      <div className={styles.navbar}>
        <div className={styles.logo}>
          <Link to="/">
            <img alt="school-hub logo" src={"/" + "./WhiteLogo.svg"} />
          </Link>
        </div>
        <div className={styles.links}>
          <div className={styles.select}>
            {loading ? (
              <CircularProgress size="30px" sx={{ color: "white" }} />
            ) : (
              <>
                {window.location.pathname.includes(
                  "/schooling/classroom/"
                ) ? null : (
                  <>
                    <label>Année Scolaire</label>
                    <Select
                      required
                      value={currentSeason?._id}
                      onChange={(e) => {
                        dispatch({
                          type: "SET_CURRENT_SEASON",
                          payload: seasons.find(
                            (s) => s._id === e.target.value
                          ),
                        });
                      }}
                      size="small"
                      sx={{
                        fontWeight: "600",
                        color: "#283487",
                        height: "35px",
                        backgroundColor: "#D2DAFFBF",
                        width: "100%",
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "5px !important",
                          border: "0.5px solid transparent !important",
                        },
                      }}
                    >
                      {seasons.map((season) => {
                        return (
                          <MenuItem
                            style={{
                              marginBottom: "5px",
                            }}
                            key={season._id}
                            value={season._id}
                          >
                            {season.year}
                          </MenuItem>
                        );
                      })}
                      <div style={{ display: "flex", padding: "0px 14px" }}>
                        <Button
                          onClick={() => setOpenForm(true)}
                          size="small"
                          variant="outlined"
                        >
                          ajouter
                        </Button>
                      </div>
                    </Select>
                  </>
                )}
              </>
            )}
          </div>
         
          <button
            onClick={() => dispatch({ type: "USER_LOGOUT" })}
            className={styles.button}
          >
            Se déconnecter
          </button>
        </div>
      </div>
    </>
  );
}

export default Navbar;
