import React, { useEffect, useRef, useState } from "react";
import styles from "../../../styles/admin/Messenger.module.css";
import { Button, IconButton, Skeleton, TextField } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SendIcon from "@mui/icons-material/Send";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import { io } from "socket.io-client";
import { getError } from "../../../config/config";
import Layout from "../components/Layout";

function Messenger() {
  const [loading, setLoading] = useState(true);
  const [chat, setChat] = useState([]);
  const [text, setText] = useState("");
  const [loadingRoom, setLoadingRoom] = useState(false);
  const [recipient, setRecipient] = useState(null);
  const bottomRef = useRef(null);
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();


  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView();
  };

 
  const fetchRoom = async (id) => {
    setLoadingRoom(true);
    setRecipient(id);
    try {
      const { data } = await axios.get(`/messages/get/${id}`);
      setChat(data);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    } finally {
      setLoadingRoom(false);
    }
  };

  // Send a new message
  const send = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/message/send", {
        sender: userInfo?.user._id, 
        senderModel: "User",
        recipient: userInfo.establishment._id, 
        recipientModel: "Establishment",
        message: text,
      });
      setText("");
      setChat((prevChat) => [
        ...prevChat,
        {
          sender: userInfo?.user._id,
          senderModel: "User",
          recipient: userInfo.establishment._id,
          recipientModel: "Establishment",
          message: text,
        },
      ]);
    } catch (error) {
      enqueueSnackbar(error.response.data.error || "Error sending message", { variant: "error" });
    }
  };

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SERVER_URL);

    socket.on('connect', () => {
      console.log('Connected to WebSocket server');
      socket.emit('JOIN', { id: userInfo?.user._id, type: 'User' });
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket server');
    });

    socket.on('NEW_MESSAGE', (newMessage) => {
      console.log('New message received:', newMessage);
      if (newMessage.recipient === userInfo?.user._id) {
        setChat((prevChat) => [...prevChat, newMessage]);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [userInfo]);

  
  useEffect(() => {
    if (userInfo.establishment?._id) {
      fetchRoom(userInfo.establishment._id);
    }
  }, [userInfo]);

  
  useEffect(() => {
    scrollToBottom();
  }, [chat]);

  return (
    <Layout>
      <div className={styles.row}>
        <div className={styles.sideMenu}>
          <div className={styles.filter}>
            <input
              type="text"
              placeholder="Recherche..."
              value={userInfo.establishment?.name || "Establishment"}
              readOnly
              className="grayInput"
            />
          </div>
          <section className={styles.rooms}>
            <div
              key={userInfo.establishment?._id}
              className={styles.room}
              onClick={() => fetchRoom(userInfo.establishment?._id)}
            >
              <div className={styles.avatar}>
                <img
                  alt={userInfo.establishment?.name}
                  src={userInfo.establishment?.avatar || "/icons/profile2.svg"}
                />
              </div>
              <div className={styles.text}>
                <p>{userInfo.establishment?.name}</p>
              </div>
            </div>
          </section>
        </div>
        <div className={styles.chat}>
          {!loadingRoom && chat.length === 0 ? (
            <div className={styles.placeholder}>
              <img
                alt={userInfo.user?.name}
                src={userInfo.user?.avatar || "/icons/profile2.svg"}
              />
              <p>Bienvenue, {userInfo.user?.name} !</p>
              <p>Veuillez sélectionner un chat pour démarrer la messagerie.</p>
            </div>
          ) : (
            <>
              <div className={styles.chatHeader}>
                <div className={styles.avatar}>
                  <img
                    alt={userInfo.establishment?.name}
                    src={userInfo.establishment?.avatar || "/icons/profile2.svg"}
                  />
                  &nbsp;<p>{userInfo.establishment?.name}</p>
                </div>
                <a href={`tel:${userInfo.establishment?.phone}`}>
                  <IconButton sx={{ color: "#73EB9C" }}>
                  <div className={styles.avatar}>
                  <img
                    alt="phone"
                    src="/icons/phone.svg"
                    
                  />
                  </div>
                  </IconButton>
                </a>
              </div>
              <div className={styles.body}>
              {chat?.map((message) => (
                  <div key={message._id}>
                    {message?.senderModel === "User" ? (
                      <div
                        key={message._id}
                        className={`${styles.messageRow} ${styles.right}`}
                      >
                        <div className={`${styles.message} ${styles.me}`}>
                          {message?.message}
                        </div>
                      </div>
                    ) : (
                      <div
                        key={message._id}
                        className={`${styles.messageRow} ${styles.left}`}
                      >
                        <div className={`${styles.message} ${styles.him}`}>
                          {message?.message}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                <div ref={bottomRef} />
              </div>
              <div className={styles.chatFooter}>
                <form onSubmit={send} style={{ display: "flex", alignItems: "center", width: "100%" }}>
                  <TextField
                    id="outlined-basic"
                    label="Écrire un message..."
                    variant="outlined"
                    value={text}
                    style={{ width: "100%" }}
                    onChange={(e) => setText(e.target.value)}
                  />
                  <IconButton type="submit">
                    <SendIcon />
                  </IconButton>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default Messenger;
