import {
  Autocomplete,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import styles from "../../../../../../styles/admin/Dashboard.module.css";
import { compressImage } from "../../../../../../config/config";
import { TagsInput } from "react-tag-input-component";

function EditStudent(props) {
  const {
    formData,
    setFormData,
    loadingForm,
    setAction,
    editStudent,
    student,
    establishment,
    classroom,
  } = props;
  const [passwordType, setPasswordType] = useState("password");

  return (
    <form onSubmit={editStudent}>
      <h1>Modifier l&apos;étudiant:&nbsp;{student.user.name}</h1>
      <div className="row">
        <label className={styles.avatar} htmlFor="avatar">
          <img
            alt="avatar"
            src={
              formData.avatar !== ""
                ? formData.avatar
                : "/" + "./icons/profile2.svg"
            }
          />
        </label>
        <input
          id="avatar"
          hidden
          type="file"
          accept="image/*"
          onChange={async (e) =>
            setFormData({
              ...formData,
              avatar: await compressImage(e.target.files[0]),
            })
          }
        />
      </div>
      <div style={{ alignItems: "flex-start" }} className="row">
        <div className="col50">
          <div className="labeledInput">
            <label>Nom et prénom</label>
            <input
              required
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              type="text"
              className="defaultInput"
            />
          </div>

          <div className="labeledInput">
            <label>Date de naissance</label>
            <DesktopDatePicker
              required
              value={moment(formData.birthday, "yyyy-MM-DD")}
              inputFormat="yyyy-MM-DD"
              renderInput={(params) => (
                <TextField
                  className="defaultInput"
                  required
                  size="small"
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "5px !important",
                      border: "1px solid #283487 !important",
                    },
                  }}
                  {...params}
                />
              )}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  birthday: moment(e).format("yyyy-MM-DD"),
                });
              }}
            />
          </div>

          <div className="labeledInput">
            <label>Adresse</label>
            <input
              required
              value={formData.address}
              onChange={(e) =>
                setFormData({ ...formData, address: e.target.value })
              }
              type="text"
              className="defaultInput"
            />
          </div>

          <div className="labeledInput">
            <label>Sexe</label>
            <Select
              value={formData.sex}
              required
              onChange={(e) => {
                setFormData({ ...formData, sex: e.target.value });
              }}
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "5px !important",
                  border: "1px solid #283487 !important",
                },
              }}
              size="small"
            >
              <MenuItem value="Masculin">Masculin</MenuItem>
              <MenuItem value="Féminin">Féminin</MenuItem>
            </Select>
          </div>

          <div className="labeledInput">
            <label>Nom et prénom du père</label>
            <input
              value={formData.fatherName}
              onChange={(e) =>
                setFormData({ ...formData, fatherName: e.target.value })
              }
              type="text"
              className="defaultInput"
            />
          </div>

          <div className="labeledInput">
            <label>Profession du père</label>
            <input
              value={formData.fatherProfession}
              onChange={(e) =>
                setFormData({ ...formData, fatherProfession: e.target.value })
              }
              type="text"
              className="defaultInput"
            />
          </div>
        </div>
        <div className="col50">
          <div className="labeledInput">
            <label>Téléphone du père</label>
            <input
              value={formData.fatherPhone}
              onChange={(e) =>
                setFormData({ ...formData, fatherPhone: e.target.value })
              }
              type="number"
              className="defaultInput"
            />
          </div>

          <div className="labeledInput">
            <label>État civil</label>
            <input
              value={formData.maritalStatus}
              onChange={(e) =>
                setFormData({ ...formData, maritalStatus: e.target.value })
              }
              type="text"
              className="defaultInput"
            />
          </div>

          <div className="labeledInput">
            <label>Orphelin</label>
            <div className="defaultInput inputRow">
              <input
                onChange={(e) => {
                  setFormData({ ...formData, orphin: true });
                }}
                type="radio"
                checked={formData.orphin}
                id="admin"
                name="RADIO"
                required
              />
              <label htmlFor="admin">oui</label>
              &nbsp; &nbsp; &nbsp;
              <input
                onChange={(e) => {
                  setFormData({ ...formData, orphin: false });
                }}
                type="radio"
                checked={!formData.orphin}
                id="doctor"
                name="RADIO"
                required
              />
              <label htmlFor="doctor">non</label>
            </div>
          </div>
          <div className="labeledInput">
            <label>Nom et prénom de la mère</label>
            <input
              value={formData.motherName}
              onChange={(e) =>
                setFormData({ ...formData, motherName: e.target.value })
              }
              type="text"
              className="defaultInput"
            />
          </div>

          <div className="labeledInput">
            <label>Profession de la mère</label>
            <input
              value={formData.motherProfession}
              onChange={(e) =>
                setFormData({ ...formData, motherProfession: e.target.value })
              }
              type="text"
              className="defaultInput"
            />
          </div>

          <div className="labeledInput">
            <label>Téléphone de la mère</label>
            <input
              value={formData.motherPhone}
              onChange={(e) =>
                setFormData({ ...formData, motherPhone: e.target.value })
              }
              type="number"
              className="defaultInput"
            />
          </div>
        </div>
        <div className="col50">
          <div className="labeledInput">
            <label>Frères et sœurs</label>
            <input
              value={formData.siblings}
              onChange={(e) =>
                setFormData({ ...formData, siblings: e.target.value })
              }
              type="number"
              className="defaultInput"
            />
          </div>

          <div className="labeledInput">
            <label>Frères et sœurs étudiants</label>
            <input
              value={formData.siblingsStudents}
              onChange={(e) =>
                setFormData({ ...formData, siblingsStudents: e.target.value })
              }
              type="number"
              className="defaultInput"
            />
          </div>

          <div className="labeledInput">
            <label>Accompagnateur autorisé</label>
            <TagsInput
              className="defaultInput"
              value={formData.authorizedCompany}
              onChange={(val) => {
                setFormData({ ...formData, authorizedCompany: val });
              }}
              placeHolder="Nom et prénom"
            />
          </div>

          <div className="labeledInput">
            <label>Téléphone</label>
            <input
              required
              value={formData.phone}
              onChange={(e) =>
                setFormData({ ...formData, phone: e.target.value })
              }
              type="number"
              className="defaultInput"
            />
          </div>

          <div className="labeledInput">
            <label>Email</label>
            <input
              autoComplete="new-password"
              required
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              type="email"
              className="defaultInput"
            />
          </div>

          <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
            <div className="labeledInput">
              <label>Mot de passe</label>
              <input
                autoComplete="new-password"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                type={passwordType}
                className="defaultInput"
              />
            </div>
            <IconButton
              style={{ marginTop: "20px", marginLeft: "-50px" }}
              onClick={() => {
                if (passwordType === "password") {
                  setPasswordType("text");
                } else {
                  setPasswordType("password");
                }
              }}
            >
              {passwordType === "password" ? (
                <RemoveRedEyeIcon color="primary" />
              ) : (
                <VisibilityOffIcon color="primary" />
              )}
            </IconButton>
          </div>
        </div>
      </div>
      <div className="row">
        {loadingForm ? (
          <CircularProgress size="35px" />
        ) : (
          <>
            <Button type="submit" variant="contained">
              Modifier
            </Button>
            &nbsp;
            <Button
              type="button"
              onClick={() => {
                setFormData({
                  establishment: establishment._id,
                  classroom: classroom,
                  avatar: "",
                  name: "",
                  email: "",
                  password: "",
                  phone: "",
                  address: "",
                  sex: "",
                  birthday: "",
                  fatherName: "",
                  MotherName: "",
                  orphin: false,
                  maritalStatus: "",
                  fatherPhone: "",
                  motherPhone: "",
                  motherProfession: "",
                  fatherProfession: "",
                  siblings: 0,
                  siblingsStudents: 0,
                  authorizedCompany: [],
                });
                setAction("");
              }}
              variant="outlined"
            >
              Annuler
            </Button>
          </>
        )}
      </div>
    </form>
  );
}

export default EditStudent;
