import { Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";

function AddDeposit(props) {
  const [multiple, setMultiple] = useState(false);
  const {
    addDeposit,
    setAction,
    deposit,
    setDeposit,
    resetDeposit,
    loadingForm,
  } = props;
  return (
    <form onSubmit={addDeposit}>
      <div className="labeledInput">
        <label>montant</label>
        <input
          className="defaultInput"
          type="number"
          step="any"
          min={1}
          required
          value={deposit.amount}
          onChange={(e) => setDeposit({ ...deposit, amount: e.target.value })}
        />
      </div>
      <br />
      <div className="labeledInput">
        <label>modalité de paiement</label>
        <input
          className="defaultInput"
          type="text"
          required
          value={deposit.method}
          onChange={(e) => setDeposit({ ...deposit, method: e.target.value })}
        />
      </div>
      <br />
      <div className="labeledInput">
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <label>multiple ?</label>
          <input
            style={{ marginBottom: "7px", cursor: "pointer" }}
            type="checkbox"
            checked={multiple}
            onChange={(e) => setMultiple(e.target.checked)}
          />
        </div>
        {multiple ? (
          <input
            className="defaultInput"
            type="number"
            min={1}
            required
            value={deposit.multiple}
            onChange={(e) =>
              setDeposit({ ...deposit, multiple: e.target.value })
            }
          />
        ) : null}
      </div>

      <br />
      {loadingForm ? (
        <CircularProgress size="33px" />
      ) : (
        <div className="btnRow">
          <Button type="submit" variant="contained">
            ajouter
          </Button>
          &nbsp;
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              setAction("");
              resetDeposit();
            }}
          >
            annuler
          </Button>
        </div>
      )}
    </form>
  );
}

export default AddDeposit;
