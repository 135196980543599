import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { CircularProgress, IconButton, Dialog } from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getMonth } from "../../../config/config";
import styles from "../../../styles/admin/Finance.module.css";
import Layout from "../components/Layout";
import AddRow from "./components/AddRow";
import AddWithdrawal from "./components/AddWithdrawal";
import DeleteWithdrawal from "./components/DeleteWithdrawal";
import EditWithdrawal from "./components/EditWithdrawal";
import PieChart from "./components/PieChart";
import Wallet from "./components/Wallet";

function Finance(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentSeason } = useSelector((state) => state.dashboard);
  const [withdrawals, setWithdrawals] = useState([]);
  const [fournitures, setFournitures] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingWithdrawals, setLoadingWithdrawals] = useState(true);
  const [loadingFournitures, setLoadingFournitures] = useState(true);
  const [label, setLabel] = useState("");
  const [action, setAction] = useState("");

  const [withdrawal, setWithdrawal] = useState({
    establishment: userInfo.establishment._id,
    season: "",
    admin: userInfo._id,
    label: "",
    object: "",
    date: "",
    monthIndex: 0,
    amount: 0,
    method: "",
    multiple: 0,
  });

  const addRow = (e) => {
    e.preventDefault();
    setLoadingForm(true);
    try {
      setWithdrawals([
        ...withdrawals,
        {
          label: label,
          withdrawals: [
            {
              monthIndex: 0,
            },
            {
              monthIndex: 1,
            },
            {
              monthIndex: 2,
            },
            {
              monthIndex: 3,
            },
            {
              monthIndex: 4,
            },
            {
              monthIndex: 5,
            },
            {
              monthIndex: 6,
            },
            {
              monthIndex: 7,
            },
            {
              monthIndex: 8,
            },
            {
              monthIndex: 9,
            },
            {
              monthIndex: 10,
            },
            {
              monthIndex: 11,
            },
          ],
        },
      ]);
      setAction("");
      setLabel("");
      setLoadingForm(false);
    } catch (error) {
      console.log(error);
    }
  };

  const resetWithdrawal = () => {
    setWithdrawal({
      establishment: userInfo.establishment._id,
      season: currentSeason?._id,
      admin: userInfo._id,
      label: "",
      object: "",
      date: "",
      monthIndex: 0,
      amount: 0,
      method: "",
      multiple: 0,
    });
  };

  const addWithdrawal = async (e) => {
    e.preventDefault();
    try {
      if (withdrawal.label !== "fourniture") {
        var exists = false;
        const row = withdrawals.find((w) => w.label === withdrawal.label);
        for (let i = 0; i < row.withdrawals.length; i++) {
          if (
            Number(withdrawal.monthIndex) <
              Number(row.withdrawals[i].monthIndex) &&
            Number(row.withdrawals[i].monthIndex) <
              Number(withdrawal.multiple) + Number(withdrawal.monthIndex)
          ) {
            exists = true;
          }
        }

        if (Number(withdrawal.multiple) + Number(withdrawal.monthIndex) > 12) {
          return enqueueSnackbar("ne dépasser pas les mois disponible !", {
            variant: "warning",
          });
        }
        if (row.length > 0 && exists) {
          return enqueueSnackbar("respecter les mois déjà configurer !", {
            variant: "warning",
          });
        }
      }
      const { data } = await axios.post("/withdrawal/add", withdrawal);
      enqueueSnackbar(data.message, { variant: "success" });
      setAction("");
      resetWithdrawal();

      if (withdrawal.label === "fourniture") {
        fetchFournitures();
      } else {
        fetchWithdrawals();
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const editWithdrawal = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.put("/withdrawal/edit", withdrawal);
      enqueueSnackbar(data.message, { variant: "success" });
      setAction("");
      resetWithdrawal();
      if (withdrawal.label === "fourniture") {
        fetchFournitures();
      } else {
        fetchWithdrawals();
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const deleteWithdrawal = async () => {
    try {
      const { data } = await axios.post(`/withdrawal/remove/${withdrawal._id}`);
      enqueueSnackbar(data.message, { variant: "success" });
      setAction("");
      resetWithdrawal();
      if (withdrawal.label === "fourniture") {
        fetchFournitures();
      } else {
        fetchWithdrawals();
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const fetchWithdrawals = async () => {
    try {
      const { data } = await axios.get(
        `/withdrawal/getwithdrawals/${userInfo?.establishment._id}/${currentSeason?._id}`
      );
      setWithdrawals(data);
      setLoadingWithdrawals(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setLoadingWithdrawals(false);
    }
  };

  const fetchFournitures = async () => {
    try {
      const { data } = await axios.get(
        `/withdrawal/getfournitures/${userInfo?.establishment._id}/${currentSeason?._id}`
      );
      setFournitures(data);
      setLoadingFournitures(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setLoadingFournitures(false);
    }
  };

  const renderWithdrawals = (row) => {
    var block = [];
    for (let i = 0; i < 12; i++) {
      if (row.withdrawals.find((w) => w.monthIndex === i)?.amount) {
        block = [
          ...block,
          <td key={i} style={{ cursor: "auto" }} className={styles.cell}>
            <div>
              {row.withdrawals.find((w) => w.monthIndex === i).amount}&nbsp;DT
            </div>
            <div>{row.withdrawals.find((w) => w.monthIndex === i).method}</div>
            <div className={styles.edit}>
              <div className={styles.icon}>
                <EditIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setWithdrawal(
                      row.withdrawals.find((w) => w.monthIndex === i)
                    );
                    setAction("EDIT_WITHDRAWAL");
                  }}
                  sx={{ width: "15px", height: "15px", color: "orange" }}
                />
              </div>
            </div>
            <div className={styles.delete}>
              <div className={styles.icon}>
                <DeleteOutlineIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setWithdrawal(
                      row.withdrawals.find((w) => w.monthIndex === i)
                    );
                    setAction("DELETE_WITHDRAWAL");
                  }}
                  sx={{ width: "15px", height: "15px", color: "red" }}
                />
              </div>
            </div>
          </td>,
        ];
      } else {
        block = [
          ...block,
          <td key={i} value={i}>
            <div className={styles.cellContainer}>
              <div className={styles.add}>
                <div className={styles.icon}>
                  <AddCircleOutlineIcon
                    onClick={() => {
                      setWithdrawal({
                        ...withdrawal,
                        season: currentSeason._id,
                        label: row.label,
                        monthIndex: i,
                      });
                      setAction("ADD_WITHDRAWAL");
                    }}
                    sx={{ width: "15px", height: "15px", color: "green" }}
                  />
                </div>
              </div>
            </div>
          </td>,
        ];
      }
    }
    return block;
  };

  const renderFournitures = (fournitures) => {
    var block = [];
    for (let i = 0; i < 12; i++) {
      if (fournitures.find((row) => row.monthIndex === i)) {
        block = [
          ...block,
          <tr key={i}>
            <td style={{ width: "20%" }}>{getMonth(i)}</td>
            <td style={{ padding: "2px", position: "relative" }}>
              <div className={styles.fournituresContainer}>
                {fournitures
                  .filter((fourniture) => fourniture.monthIndex === i)
                  .map((f) => {
                    return (
                      <div key={f._id} className={styles.fournitureCell}>
                        <p>
                          objet: <a>{f.object}</a>
                        </p>

                        <p>
                          montant: <a>{f.amount}&nbsp;DT</a>
                        </p>
                        <p>
                          date:{" "}
                          <a>{getMonth(i) + " " + (f.date ? f.date : "")}</a>
                        </p>
                        <div className={styles.editFourniture}>
                          <div className={styles.icon}>
                            <EditIcon
                              onClick={(e) => {
                                e.stopPropagation();
                                setWithdrawal(f);
                                setAction("EDIT_WITHDRAWAL");
                              }}
                              sx={{
                                width: "15px",
                                height: "15px",
                                color: "orange",
                              }}
                            />
                          </div>
                        </div>
                        <div className={styles.deleteFourniture}>
                          <div className={styles.icon}>
                            <DeleteOutlineIcon
                              onClick={(e) => {
                                e.stopPropagation();
                                setWithdrawal(f);
                                setAction("DELETE_WITHDRAWAL");
                              }}
                              sx={{
                                width: "15px",
                                height: "15px",
                                color: "red",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className={styles.addFourniture}>
                <div className={styles.icon}>
                  <AddCircleOutlineIcon
                    onClick={() => {
                      setWithdrawal({
                        ...withdrawal,
                        season: currentSeason._id,
                        label: "fourniture",
                        monthIndex: i,
                      });
                      setAction("ADD_WITHDRAWAL");
                    }}
                    sx={{ width: "15px", height: "15px", color: "green" }}
                  />
                </div>
              </div>
            </td>
          </tr>,
        ];
      } else {
        block = [
          ...block,
          <tr key={i}>
            <td style={{ width: "20%" }}>{getMonth(i)}</td>
            <td>
              <div className={styles.cellContainer}>
                <div className={styles.add}>
                  <div className={styles.icon}>
                    <AddCircleOutlineIcon
                      onClick={() => {
                        setWithdrawal({
                          ...withdrawal,
                          season: currentSeason._id,
                          label: "fourniture",
                          monthIndex: i,
                        });
                        setAction("ADD_WITHDRAWAL");
                      }}
                      sx={{ width: "15px", height: "15px", color: "green" }}
                    />
                  </div>
                </div>
              </div>
            </td>
          </tr>,
        ];
      }
    }
    return block;
  };

  useEffect(() => {
    if (currentSeason) {
      fetchWithdrawals();
      fetchFournitures();
    }
  }, [currentSeason]);

  return (
    <>
      <Dialog
        open={action !== ""}
        onClose={() => {
          setAction("");
          setLabel("");
          resetWithdrawal();
        }}
      >
        <div
          className={action === "SHOW_RECEIPT" ? "modal medium" : "modal small"}
          style={
            action === "ADD_WITHDRAWAL" || action === "EDIT_WITHDRAWAL"
              ? withdrawal.label === "fourniture"
                ? { maxHeight: "100%", top: "10vh" }
                : { maxHeight: "100%" }
              : null
          }
        >
          {action === "ADD_ROW" ? (
            <AddRow
              setAction={setAction}
              label={label}
              setLabel={setLabel}
              addRow={addRow}
              loadingForm={loadingForm}
            />
          ) : action === "ADD_WITHDRAWAL" ? (
            <AddWithdrawal
              setAction={setAction}
              withdrawal={withdrawal}
              setWithdrawal={setWithdrawal}
              resetWithdrawal={resetWithdrawal}
              addWithdrawal={addWithdrawal}
              loadingForm={loadingForm}
            />
          ) : action === "EDIT_WITHDRAWAL" ? (
            <EditWithdrawal
              setAction={setAction}
              withdrawal={withdrawal}
              setWithdrawal={setWithdrawal}
              resetWithdrawal={resetWithdrawal}
              editWithdrawal={editWithdrawal}
              loadingForm={loadingForm}
            />
          ) : action === "DELETE_WITHDRAWAL" ? (
            <DeleteWithdrawal
              setAction={setAction}
              withdrawal={withdrawal}
              setWithdrawal={setWithdrawal}
              resetWithdrawal={resetWithdrawal}
              deleteWithdrawal={deleteWithdrawal}
              loadingForm={loadingForm}
            />
          ) : null}
        </div>
      </Dialog>
      <Layout>
        {currentSeason ? (
          <>
            <section className={styles.header}>
              <div className={styles.col60}>
                <Wallet
                  refresh={{
                    withdrawals: withdrawals,
                    fournitures: fournitures,
                  }}
                />
              </div>
              <div className={styles.col40}>
                <PieChart
                  refresh={{
                    withdrawals: withdrawals,
                    fournitures: fournitures,
                  }}
                />
              </div>
            </section>
            <section className={styles.container}>
              <h2>Prestation</h2>
              {loadingWithdrawals ? (
                <div className="spinner">
                  <CircularProgress size="30px" />
                </div>
              ) : (
                <>
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Jan</th>
                        <th>Fév</th>
                        <th>Mar</th>
                        <th>Avr</th>
                        <th>Mai</th>
                        <th>Juin</th>
                        <th>Juil</th>
                        <th>Août</th>
                        <th>Sept</th>
                        <th>Oct</th>
                        <th>Nov</th>
                        <th>Déc</th>
                      </tr>
                    </thead>
                    <tbody>
                      {withdrawals.map((row, i) => {
                        return (
                          <tr key={i}>
                            <td
                              style={{
                                backgroundColor: "#fdcf1b",
                                color: "#000000",
                              }}
                            >
                              {row.label}
                            </td>
                            {renderWithdrawals(row)}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <IconButton
                    color="secondary"
                    onClick={() => setAction("ADD_ROW")}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </>
              )}
            </section>
            <br />
            <div className={styles.hr} />
            <br />
            <section className={styles.container}>
              <h2>Fourniture</h2>
              {loadingFournitures ? (
                <div className="spinner">
                  <CircularProgress size="30px" />
                </div>
              ) : (
                <>
                  <table>
                    <thead>
                      <tr>
                        <th>Mois</th>
                        <th>Fournitures</th>
                      </tr>
                    </thead>
                    <tbody>{renderFournitures(fournitures)}</tbody>
                  </table>
                </>
              )}
            </section>
            <br />
            <br />
            <br />
          </>
        ) : (
          <div className="spinner">
            <span>
              Vous devez choisir une année scolaire pour voir le contenu de
              cette page !
            </span>
          </div>
        )}
      </Layout>
    </>
  );
}

export default Finance;
