import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import React from "react";

function AddExam(props) {
  const { addExam, setAction, exam, setExam, rooms, subjects, loadingForm } =
    props;

  return (
    <form onSubmit={addExam}>
      <h1>Ajouter un examen</h1>
      <div className="row">
        <div className="labeledInput">
          <label>Heure de début</label>
          <TimePicker
            inputFormat="hh:mm"
            ampm={false}
            value={exam.start}
            onChange={(val) => {
              setExam({ ...exam, start: moment(val, "hh:mm") });
            }}
            renderInput={(params) => (
              <TextField
                required
                size="small"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "5px !important",
                    border: "1px solid #1d255e !important",
                  },
                }}
                {...params}
              />
            )}
          />
        </div>
        &nbsp; &nbsp; &nbsp;
        <div className="labeledInput">
          <label>Heure de fin</label>
          <TimePicker
            inputFormat="hh:mm"
            ampm={false}
            value={exam.end}
            onChange={(val) => {
              setExam({ ...exam, end: moment(val, "hh:mm") });
            }}
            renderInput={(params) => (
              <TextField
                required
                size="small"
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "5px !important",
                    border: "1px solid #1d255e !important",
                  },
                }}
                {...params}
              />
            )}
          />
        </div>
      </div>
      <div className="labeledInput">
        <label>Matière</label>
        <Autocomplete
          size="small"
          style={{
            width: "100%",
            padding: "0px",
          }}
          value={subjects.find((s) => s._id === exam.subject)}
          options={subjects.map((s) => {
            return { ...s, label: s.name };
          })}
          onChange={(e, val) => {
            setExam({
              ...exam,
              subject: val !== null ? val._id : "",
            });
          }}
          renderInput={(params) => (
            <TextField
              className="defaultInput"
              size="small"
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "5px !important",
                  border: "1px solid #283487 !important",
                },
              }}
              {...params}
              required={true}
            />
          )}
        />
      </div>
      <br />
      <div className="labeledInput">
        <label>Salle</label>
        <Autocomplete
          size="small"
          style={{
            width: "100%",
            padding: "0px",
          }}
          value={rooms.find((r) => r._id === exam.room)}
          options={rooms.map((r) => {
            return { ...r, label: r.name };
          })}
          onChange={(e, val) => {
            setExam({
              ...exam,
              room: val !== null ? val._id : "",
            });
          }}
          renderInput={(params) => (
            <TextField
              className="defaultInput"
              size="small"
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "5px !important",
                  border: "1px solid #283487 !important",
                },
              }}
              {...params}
              required={true}
            />
          )}
        />
      </div>
      <br />
      {loadingForm ? (
        <CircularProgress size="32px" />
      ) : (
        <div className="btnRow">
          <Button variant="contained" type="submit">
            ajouter
          </Button>
          &nbsp;
          <Button
            variant="outlined"
            type="button"
            onClick={() => {
              setAction("");
              setExam({ start: "", end: "" });
            }}
          >
            annuler
          </Button>
        </div>
      )}
    </form>
  );
}

export default AddExam;
