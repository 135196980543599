import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styles from "../../../styles/admin/Messenger.module.css";
import { Button, IconButton, Skeleton, TextField } from "@mui/material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import moment from "moment";
import SendIcon from "@mui/icons-material/Send";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import { io } from "socket.io-client";
import { getError } from "../../../config/config";
import Layout from "../components/Layout";

function Messenger() {
  const [loading, setLoading] = useState(true);
  const [recipients, setRecipients] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [userName, setUserName] = useState("");
  const { userInfo } = useSelector((state) => state.auth);
  const [chat, setChat] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [limit, setLimit] = useState(10);
  const [more, setMore] = useState(false);
  const [text, setText] = useState("");
  const [loadingRoom, setLoadingRoom] = useState(false);
  const [recipient, setRecipient] = useState(null);
  console.log('chat', chat)
  const bottomRef = useRef(null);
  const dispatch = useDispatch();

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView();
  };


  const fetchRecipients = async () => {
    try {
      const { data } = await axios.get(`/recipient/get/${userInfo?.establishment?._id}`);
      setRecipients(data);
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoading(false);
    }
  };

  const fetchRooms = async () => {
    try {
      const { data } = await axios.get("/messages/get", {
        params: {
          establishment: userInfo?.establishment?._id,
          userName: userName,
          limit: limit,
        }
      });
      setRooms(data);
      setMore(data.length > limit);
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoading(false);
    }
  };
  console.log('rooms', rooms)
  const fetchRoom = async (id) => {
    setLoadingRoom(true);
    setRecipient(id);
    try {
      const { data } = await axios.get(`/messages/get/${id}`);
      setRooms(rooms.map((room) => (room._id === id ? data : room)));
      setChat(data);
      setLoadingRoom(false);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingRoom(false);
    }
  };

  const send = async (e) => {
    e.preventDefault();
    try {
      await axios.post("/message/send", {
        sender: userInfo?.establishment?._id,
        senderModel: "Establishment",
        recipient,
        recipientModel: "User",
        message: text,
      });
      setText("");
      setChat((prevChat) => [
        ...prevChat,
        {
          sender: userInfo?.establishment?._id,
          senderModel: "Establishment",
          recipient,
          recipientModel: "User",
          message: text,
        },
      ]);
      
    } catch (error) {
      enqueueSnackbar(error.response.data.error || "Error sending message", { variant: "error" });
    }
  };

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SERVER_URL);

    socket.on('connect', () => {
      console.log('Connected to WebSocket server');
      socket.emit('JOIN', { id: userInfo?.establishment._id, type: 'Establishment' });
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from WebSocket server');
    });

    socket.on('NEW_MESSAGE', (newMessage) => {
      console.log('New message received:', newMessage);
      if (newMessage.recipient === userInfo?.establishment._id) {
        setChat((prevChat) => [...prevChat, newMessage]);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [userInfo]);

  useEffect(() => {
    fetchRecipients();
  }, []);
  
 //scrollToBottom 
 useEffect(() => {
  scrollToBottom();
}, [chat]);
  
  return (
    <Layout>
      <div className={styles.row}>
        <div className={styles.sideMenu}>
          <div className={styles.filter}>
            <input
              type="text"
              placeholder="Recherche par nom..."
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              className="grayInput"
            />
            {userName !== "" && (
              <Button
                onClick={() => {
                  setUserName("");
                }}
                size="large"
                variant="outlined"
              >
                <RotateLeftIcon />
              </Button>
            )}
          </div>
          {loading ? (
            <section className={styles.rooms}>
              {[...Array(3)].map((_, index) => (
                <Skeleton
                  key={index}
                  className={styles.room}
                  variant="rectangular"
                  sx={{ height: "75px" }}
                />
              ))}
            </section>
          ) : (
            <section className={styles.rooms}>
              {recipients?.map((recipient) => (
                <div
                  key={recipient._id}
                  className={styles.room}
                  onClick={() => fetchRoom(recipient?.user?._id)}
                >
                  <div className={styles.avatar}>
                    <img
                      alt={recipient?.user?.name}
                      src={recipient?.user?.avatar || "/icons/profile2.svg"}
                    />
                  </div>
                  <div className={styles.text}>
                    <p>{recipient?.user?.name}</p>
                  </div>
                </div>
              ))}
              {more && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <p
                    onClick={() => {
                      setLimit(limit + 10);
                    }}
                  >
                    voir plus
                  </p>
                </div>
              )}
            </section>
          )}
        </div>
        <div className={styles.chat}>
          {!loadingRoom && chat === null ? (
            <div className={styles.placeholder}>
              <img
                alt={userInfo?.user?.name}
                src={
                  userInfo?.user?.avatar || "/icons/profile2.svg"
                }
              />
              <p>Bienvenue, {userInfo?.user?.name} !</p>
              <p>Veuillez sélectionner un chat pour démarrer la messagerie.</p>
            </div>
          ) : (
            <>
              <div className={styles.chatHeader}>
                <div className={styles.avatar}>
                  <img
                    alt={chat?.recipient?.name}
                    src={
                      chat?.recipient?.avatar || "/icons/profile2.svg"
                    }
                  />
                  &nbsp;<p>{chat?.recipient?.name}</p>
                </div>
                <a href={`tel:${chat?.recipient?.phone}`}>
                  <IconButton sx={{ color: "#73EB9C" }}>
                  <div className={styles.avatar}>
                  <img
                    alt="phone"
                    src="/icons/phone.svg"
                    
                  />
                  </div>
                  </IconButton>
                </a>
              </div>
              <div className={styles.body}>
                {chat?.map((message) => (
                  <div key={message._id}>
                    {message?.senderModel === "Establishment" ? (
                      <div
                        key={message._id}
                        className={`${styles.messageRow} ${styles.right}`}
                      >
                        <div className={`${styles.message} ${styles.me}`}>
                          {message?.message}
                        </div>
                      </div>
                    ) : (
                      <div
                        key={message._id}
                        className={`${styles.messageRow} ${styles.left}`}
                      >
                        <div className={`${styles.message} ${styles.him}`}>
                          {message?.message}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                <div ref={bottomRef} />
              </div>

              <div className={styles.chatFooter}>
                <form onSubmit={send}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >

                  <TextField id="outlined-basic" label="Écrire un message..." variant="outlined" value={text}
                    style={{ width: "100%" }}
                    onChange={(e) => setText(e.target.value)} />
                  <IconButton type="submit">
                    <SendIcon />
                  </IconButton>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default Messenger;
