import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import React from "react";

function Edit(props) {
  const {
    formData,
    setFormData,
    resetForm,
    setAction,
    addSubject,
    teachers,
    loadingForm,
  } = props;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <form onSubmit={addSubject}>
      <h1>Modifier la matière:&nbsp;{formData.name}</h1>
      <div className="labeledInput">
        <label>Nom</label>
        <input
          required
          value={formData.name}
          type="text"
          name="name"
          onChange={onChange}
          className="defaultInput"
        />
      </div>
      <br />
      <div className="labeledInput">
        <label>Coefficient</label>
        <input
          required
          value={formData.coefficient}
          type="number"
          step="any"
          min={1}
          name="coefficient"
          onChange={onChange}
          className="defaultInput"
        />
      </div>
      <br />
      <div className="labeledInput">
        <label>Enseignant</label>
        <Autocomplete
          size="small"
          style={{
            width: "100%",
            padding: "0px",
          }}
          value={{
            ...formData.teacher,
            label: formData?.teacher?.user?.name
              ? formData?.teacher?.user?.name
              : "",
          }}
          options={teachers.map((t) => {
            return { ...t, label: t.user.name };
          })}
          onChange={(e, val) => {
            setFormData({
              ...formData,
              teacher: val !== null ? val : "",
            });
          }}
          renderInput={(params) => (
            <TextField
              required={true}
              className="defaultInput"
              size="small"
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "5px !important",
                  border: "1px solid #283487 !important",
                },
              }}
              {...params}
            />
          )}
        />
      </div>
      <br />
      {loadingForm ? (
        <CircularProgress size="32px" />
      ) : (
        <div className="btnRow">
          <Button type="submit" variant="contained">
            Modifier
          </Button>
          &nbsp;
          <Button
            variant="outlined"
            type="button"
            onClick={() => {
              setAction("");
              resetForm();
            }}
          >
            Annuler
          </Button>
        </div>
      )}
    </form>
  );
}

export default Edit;
