import { CircularProgress, Tooltip, Modal } from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../../styles/admin/StudentCard.module.css"
import Layout from "../components/Layout";
import Receipt from "../../admin/schooling/classroom/students/components/Receipt";
import { getError } from "../../../config/config";

function StudentFinance(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentSeason } = useSelector((state) => state.dashboard);
  const [student, setStudent] = useState({});
  const [deposits, setDeposits] = useState([]);
  const [loadingDeposits, setLoadingDeposits] = useState(true);
  const [action, setAction] = useState("");
  const dispatch = useDispatch();
  const [deposit, setDeposit] = useState({
    establishment: userInfo?.establishment?._id,
    season: "",
    student: userInfo?._id,
    label: "",
    monthIndex: 0,
    amount: 0,
    method: "",
    multiple: 0,
  });

  const fetchStudent = async () => {
    try {
      const { data } = await axios.get(`/student/getbyid/${userInfo?._id}`);
      setStudent(data);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const fetchDeposits = async () => {
    try {
      const { data } = await axios.get(
        `/deposit/getbystudent/${userInfo?.establishment?._id}/${currentSeason?._id}/${userInfo?._id}`
      );
      setDeposits(data);
      setLoadingDeposits(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setLoadingDeposits(false);
    }
  };

  const fetchNotifications = async () => {
    try {
      const { data } = await axios.get(`/notification/get/${userInfo?._id}`);
      dispatch({ type: "UPDATE_NOTIFICATIONS", payload: data.count });
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);
  const resetDeposit = () => {
    setDeposit({
      establishment: userInfo?.establishment?._id,
      season: currentSeason?._id,
      student: userInfo?._id,
      label: "",
      monthIndex: 0,
      amount: 0,
      method: "",
      multiple: 0,
    });
  };

  const renderDeposits = (row) => {
    var block = [];
    for (let i = 0; i < 12; i++) {
      if (row.deposits.find((d) => d.monthIndex === i)?.amount) {
        block = [
          ...block,
          <Tooltip key={i} title="imprimer reçu">
            <td
              onClick={() => {
                setDeposit(row.deposits.find((d) => d.monthIndex === i));
                setAction("SHOW_RECEIPT");
              }}
              className={styles.cell}
            >
              <div>
                {row.deposits.find((d) => d.monthIndex === i).amount} DT
              </div>
              <div>{row.deposits.find((d) => d.monthIndex === i).method}</div>
            </td>
          </Tooltip>,
        ];
      } else {
        block = [
          ...block,
          <td key={i} value={i}>
            <div className={styles.cellContainer}></div>
          </td>,
        ];
      }
    }
    return block;
  };

  useEffect(() => {
    fetchStudent();
    fetchDeposits();
  }, [currentSeason]);

  return (
    <>
      <Modal
        open={action !== ""}
        onClose={() => {
          setAction("");
          resetDeposit();
        }}
      >
        <div
          className={action === "SHOW_RECEIPT" ? "modal medium" : "modal small"}
        >
          {action === "SHOW_RECEIPT" ? (
            <Receipt
              setAction={setAction}
              deposit={deposit}
              resetDeposit={resetDeposit}
              student={student}
            />
          ) : null}
        </div>
      </Modal>
      <Layout>
        <div className={styles.container}>
          <h2>Paiements</h2>
          {loadingDeposits ? (
            <div className="spinner">
              <CircularProgress size="30px" />
            </div>
          ) : (
            <>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>jan</th>
                    <th>fév</th>
                    <th>mar</th>
                    <th>avr</th>
                    <th>mai</th>
                    <th>juin</th>
                    <th>juil</th>
                    <th>août</th>
                    <th>sept</th>
                    <th>oct</th>
                    <th>nov</th>
                    <th>déc</th>
                  </tr>
                </thead>
                <tbody>
                  {deposits.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td
                          style={{ backgroundColor: "#283487", color: "#fff" }}
                        >
                          {row.label}
                        </td>
                        {renderDeposits(row)}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </div>
      </Layout>
    </>
  );
}

export default StudentFinance;
