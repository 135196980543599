import { IconButton, Skeleton } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { getError } from "../../../../../config/config";
import styles from "../../../../../styles/admin/ExamSchedule.module.css";
import Layout from "../../../components/Layout";
import Menu from "../../components/Menu";
import PrintIcon from "@mui/icons-material/Print";

function ExamScheduleStudent(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const [exams, setExams] = useState([]);
  const printRef = useRef(null);
  const dispatch = useDispatch();

  const fetchExams = async () => {
    try {
      const { data } = await axios.get(
        `/exam/get/${userInfo.classroom}/${userInfo.user._id} `
      );
      setExams(data);
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoading(false);
    }
  };
  const fetchNotifications = async () => {
    try {
      const { data } = await axios.get(`/notification/get/${userInfo._id}`);
      dispatch({ type: "UPDATE_NOTIFICATIONS", payload: data.count });
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    fetchExams();
  }, []);

  return (
    <Layout>
      <Menu />
      <div className={styles.container}>
        {loading ? (
          <Skeleton variant="rectangular" width="100%">
            <div style={{ height: "calc(100vh - 200px)" }} />
          </Skeleton>
        ) : (
          <div className={styles.tableContainer}>
            <div ref={printRef} className="printBody">
              <table className="scheduleTable">
                <thead>
                  <tr>
                    <th
                      style={{
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "-2px",
                          left: "0px",
                        }}
                        className="dontprint"
                      >
                        <ReactToPrint
                          trigger={() => (
                            <IconButton variant="contained">
                              <PrintIcon color="primary" />
                            </IconButton>
                          )}
                          content={() => printRef.current}
                        />
                      </div>
                      date
                    </th>
                    <th>Examens</th>
                  </tr>
                </thead>
                <tbody>
                  {exams.map((row) => {
                    return (
                      <tr key={row.date}>
                        <td
                          style={{ width: "20%", backgroundColor: "#f5f3f3" }}
                        >
                          <p>{moment(row.date).format("dddd")}</p>
                          <p>{moment(row.date).format("DD-MM-yyyy")}</p>
                        </td>
                        <td style={{ width: "80%" }}>
                          {row.exams.map((ex, i) => {
                            return (
                              <div key={i} className={styles.row}>
                                <div className={styles.col}>
                                  <p>
                                    {ex?.timing ? ex?.timing : "Non défini"}
                                  </p>
                                </div>
                                <div className={styles.col}>
                                  <p>
                                    Matière:{" "}
                                    {ex?.subject?.name
                                      ? ex?.subject?.name
                                      : "Non défini"}
                                  </p>

                                  <p>
                                    Salle:{" "}
                                    {ex?.room?.name
                                      ? ex?.room?.name
                                      : "Non défini"}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default ExamScheduleStudent;
