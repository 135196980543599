import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Button,
  CircularProgress,
  IconButton,
  Dialog,
  Pagination,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getError } from "../../../config/config";
import styles from "../../../styles/admin/Dashboard.module.css";
import Layout from "../components/Layout";
import ActivateStudent from "./components/ActivateStudent";
import AddStudent from "./components/AddStudent";
import BlockStudent from "./components/BlockStudent";
import EditStudent from "./components/EditStudent";

function Students(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentSeason } = useSelector((state) => state.dashboard);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [classrooms, setClassrooms] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    address: "",
    classroom: "",
    sex: "",
    birthday: "",
    establishment: userInfo.establishment._id,
  });
  const [students, setStudents] = useState([]);
  const [student, setStudent] = useState({});
  const [action, setAction] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);

  const fetchStudents = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(`/student/get`, {
        id: userInfo.establishment._id,
        page: page,
        phone: phone,
        name: name,
      });
      setStudents(data.students);
      setCount(data.count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const fetchClassrooms = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(
        `/classroom/get/${userInfo.establishment._id}/${currentSeason?._id}`
      );
      setClassrooms(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const addStudent = async (e) => {
    setLoadingForm(true);
    e.preventDefault();
    try {
      const { data } = await axios.post("/student/add", formData);
      enqueueSnackbar(data.message, { variant: "success" });
      setFormData({
        name: "",
        email: "",
        password: "",
        phone: "",
        address: "",
        classroom: "",
        sex: "",
        birthday: "",
        establishment: userInfo.establishment._id,
      });
      fetchStudents();
      fetchClassrooms();
      setLoadingForm(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  const editStudent = async (e) => {
    setLoadingForm(true);
    e.preventDefault();
    try {
      const { data } = await axios.put("/student/edit", formData);
      enqueueSnackbar(data.message, { variant: "success" });
      setFormData({
        name: "",
        email: "",
        password: "",
        phone: "",
        address: "",
        classroom: "",
        sex: "",
        birthday: "",
        establishment: userInfo.establishment._id,
      });
      fetchStudents();
      fetchClassrooms();
      setLoadingForm(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  const activateStudent = async (e) => {
    setLoadingForm(true);
    e.preventDefault();
    try {
      const { data } = await axios.put(`/student/activate/${student._id}`);
      enqueueSnackbar(data.message, { variant: "success" });
      fetchStudents();
      setLoadingForm(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  const blockStudent = async (e) => {
    setLoadingForm(true);
    e.preventDefault();
    try {
      const { data } = await axios.put(`/student/block/${student._id}`);
      enqueueSnackbar(data.message, { variant: "success" });
      fetchStudents();
      setLoadingForm(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  useEffect(() => {
    if (userInfo && currentSeason) fetchStudents();
  }, [userInfo, page, name, phone]);

  useEffect(() => {
    if (userInfo && currentSeason) fetchClassrooms();
  }, [userInfo, currentSeason]);

  return (
    <Layout>
      <Dialog
        maxWidth="md"
        open={action !== ""}
        onClose={() => {
          setFormData({
            name: "",
            email: "",
            password: "",
            phone: "",
            address: "",
            classroom: "",
            sex: "",
            birthday: "",
            establishment: userInfo.establishment._id,
          });
          setAction("");
        }}
      >
        <div
          className={
            action === "BLOCK" || action === "ACTIVATE"
              ? "modal small"
              : "modal medium"
          }
        >
          {action === "ADD" ? (
            <AddStudent
              loadingForm={loadingForm}
              formData={formData}
              setFormData={setFormData}
              setAction={setAction}
              addStudent={addStudent}
              establishment={userInfo.establishment}
              classrooms={classrooms}
            />
          ) : action === "EDIT" ? (
            <EditStudent
              loadingForm={loadingForm}
              formData={formData}
              setFormData={setFormData}
              setAction={setAction}
              student={student}
              editStudent={editStudent}
              establishment={userInfo.establishment}
              classrooms={classrooms}
              currentSeason={currentSeason}
            />
          ) : action === "BLOCK" ? (
            <BlockStudent
              loadingForm={loadingForm}
              setAction={setAction}
              blockStudent={blockStudent}
              student={student}
            />
          ) : action === "ACTIVATE" ? (
            <ActivateStudent
              loadingForm={loadingForm}
              setAction={setAction}
              activateStudent={activateStudent}
              student={student}
            />
          ) : null}
        </div>
      </Dialog>
      <div className={styles.container}>
        <section className={styles.header}>
          <h1> Liste des élèves</h1>
          {currentSeason && (
            <Button
              onClick={() => {
                setAction("ADD");
              }}
              variant="contained"
            >
              ajouter
            </Button>
          )}
        </section>
        {currentSeason && (
          <section className={styles.filter}>
            <div
              style={{ width: "20%", marginRight: "10px" }}
              className="labeledInput"
            >
              <label>Nom / prénom</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="grayInput"
              />
            </div>
            <div
              style={{ width: "20%", marginRight: "10px" }}
              className="labeledInput"
            >
              <label>Téléphone</label>
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="grayInput"
              />
            </div>
            {name !== "" || phone !== "" ? (
              <Button
                onClick={() => {
                  setName("");
                  setPhone("");
                }}
                size="large"
                variant="outlined"
              >
                <RotateLeftIcon />
              </Button>
            ) : null}
          </section>
        )}
        {loading ? (
          <div className="spinner">
            <CircularProgress />
          </div>
        ) : (
          <div className={styles.tableContainer}>
            <table className="defaultTable">
              <thead>
                <tr>
                  <th>Nom et prénom</th>
                  <th>Email</th>
                  <th>Téléphone</th>
                  <th>Classe</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {students.map((student) => {
                  {
                    return (
                      <tr key={student._id}>
                        <td>{student.user.name}</td>
                        <td>{student.user.email}</td>
                        <td>{student.user.phone}</td>
                        <td>
                          {student.classroom
                            ? student.classroom.level +
                              " " +
                              student.classroom.group
                            : "Non Attribué"}
                        </td>
                        <td>
                          <Tooltip title="modifier">
                            <IconButton
                              onClick={() => {
                                setStudent(student);
                                setFormData({
                                  ...student,
                                  ...student.user,
                                  _id: student._id,
                                });
                                setAction("EDIT");
                              }}
                              sx={{ color: "#283487" }}
                              color="primary"
                            >
                              <SettingsIcon />
                            </IconButton>
                          </Tooltip>
                          &nbsp;
                          <Tooltip
                            title={student.isActive ? "bloquer" : "activer"}
                          >
                            <IconButton
                              onClick={() => {
                                setStudent(student);

                                student.isActive
                                  ? setAction("BLOCK")
                                  : setAction("ACTIVATE");
                              }}
                              sx={{ color: "#0058A5" }}
                            >
                              {student.isActive ? <BlockIcon /> : <CheckIcon />}
                            </IconButton>
                          </Tooltip>
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </div>
        )}
        {currentSeason && (
          <section className={styles.pagination}>
            <Pagination
              onChange={(e, page) => setPage(page)}
              page={page}
              count={count}
            />
          </section>
        )}
      </div>
    </Layout>
  );
}

export default Students;
