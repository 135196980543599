import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { CircularProgress, IconButton, Modal } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import styles from "../../../styles/admin/TeacherCard.module.css";
import Layout from "../components/Layout";
import Receipt from "../../admin/teachers/components/Receipt";

const Profile = () => {
  const { enqueueSnackbar } = useSnackbar();
  const printRef = useRef();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentSeason } = useSelector((state) => state.dashboard);
  const [teacher, setTeacher] = useState({});
  const [loadingCard, setLoadingCard] = useState(true);
  const [label, setLabel] = useState("");
  const [action, setAction] = useState("");
  const [withdrawal, setWithdrawal] = useState({
    establishment: userInfo.establishment._id,
    season: "",
    admin: userInfo._id,
    teacher: userInfo._id,
    label: "",
    monthIndex: 0,
    amount: 0,
    method: "",
    multiple: 0,
  });

  const fetchTeacher = async () => {
    try {
      const { data } = await axios.get(`/teacher/getbyid/${userInfo._id}`);
      setTeacher(data);
      setLoadingCard(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setLoadingCard(false);
    }
  };

  const resetWithdrawal = () => {
    setWithdrawal({
      establishment: userInfo.establishment._id,
      season: currentSeason._id,
      teacher: userInfo._id,
      label: "",
      monthIndex: 0,
      amount: 0,
      method: "",
      multiple: 0,
    });
  };

  useEffect(() => {
    fetchTeacher();
  }, []);

  return (
    <>
      <Layout>
        <Modal
          open={action !== ""}
          onClose={() => {
            setAction("");
            setLabel("");
          }}
        >
          <div
            className={
              action === "SHOW_RECEIPT" ? "modal medium" : "modal small"
            }
          >
            {action === "SHOW_RECEIPT" ? (
              <Receipt
                setAction={setAction}
                setWithdrawal={setWithdrawal}
                withdrawal={withdrawal}
                resetWithdrawal={resetWithdrawal}
                teacher={teacher}
              />
            ) : null}
          </div>
        </Modal>
        <div className={styles.container}>
          {loadingCard ? (
            <div className="spinner">
              <CircularProgress size="30px" />
            </div>
          ) : (
            <section ref={printRef} className={styles.card}>
              <div className={`${styles.print} dontprint`}>
                <IconButton sx={{ color: "#ff9484" }}>
                  <ReactToPrint
                    trigger={() => (
                      <LocalPrintshopIcon
                        onClick={() => window.print()}
                        style={{ cursor: "pointer" }}
                      />
                    )}
                    content={() => printRef.current}
                  />
                </IconButton>
              </div>
              <div
                style={{ justifyContent: "flex-start" }}
                className={styles.row}
              >
                <img
                  style={{ cursor: "default" }}
                  alt="avatar"
                  src={
                    userInfo.user.avatar !== ""
                      ? userInfo.user.avatar
                      : "/" + "./icons/profile2.svg"
                  }
                />
              </div>
              <div className={styles.row}>
                <div className={styles.col50}>
                  <h1>
                    nom et prénom:&nbsp;<a>{userInfo.user.name}</a>
                  </h1>
                  <h1>
                    email:&nbsp;<a>{userInfo.user.email}</a>
                  </h1>
                  <h1>
                    téléphone:&nbsp;<a>{userInfo.user.phone}</a>
                  </h1>
                  <h1>
                    sexe:&nbsp;<a>{userInfo.sex}</a>
                  </h1>
                </div>
                <div className={styles.col50}>
                  <h1>
                    Date de prise de mission :&nbsp;
                    <a>{moment(userInfo.createdAt).format("YYYY-MM-DD")}</a>
                  </h1>
                  <h1>
                    Taux horaire :&nbsp;<a>{userInfo.hourlyRate} DT</a>
                  </h1>
                </div>
              </div>
            </section>
          )}
        </div>
      </Layout>
    </>
  );
};

export default Profile;
