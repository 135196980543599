import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import React from "react";

function Add(props) {
  const {
    formData,
    setFormData,
    resetForm,
    setAction,
    addSubject,
    teachers,
    loadingForm,
  } = props;
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          color: "#283487",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        Ajouter une matière
      </Typography>

      <form onSubmit={addSubject}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Nom"
              variant="outlined"
              name="name"
              fullWidth
              required
              value={formData.name}
              onChange={onChange}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Coefficient"
              required
              value={formData.coefficient}
              type="number"
              name="coefficient"
              onChange={onChange}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Autocomplete
              value={formData.teacher}
              options={teachers.map((t) => {
                return { ...t, label: t.user.name };
              })}
              onChange={(e, val) => {
                setFormData({
                  ...formData,
                  teacher: val !== null ? val : "",
                });
              }}
              renderInput={(params) => (
                <TextField
                  label="Enseignant"
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: "5px !important",
                      border: "1px solid #283487 !important",
                    },
                  }}
                  {...params}
                  required={true}
                />
              )}
            />
          </Grid>

          <Grid
            item
            xs={12}
            container
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Grid item>
              <Button type="submit" variant="contained" color="primary">
                {loadingForm ? (
                  <CircularProgress size="20px" sx={{ color: "#fff" }} />
                ) : (
                  "Ajouter"
                )}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  setAction("");
                  resetForm();
                }}
                variant="outlined"
                color="primary"
              >
                Annuler
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default Add;
