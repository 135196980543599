import {
  Autocomplete,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Grid,
  InputLabel,
  FormControl,
  InputAdornment,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { MuiTelInput } from "mui-tel-input";

function EditStudent(props) {
  const {
    formData,
    student,
    setFormData,
    loadingForm,
    setAction,
    editStudent,
    establishment,
    classrooms,
    currentSeason,
  } = props;
  const [passwordType, setPasswordType] = useState("password");

  return (
    <>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          color: "#283487",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        Modifier l&apos;étudiant :&nbsp;{student.user.name}
      </Typography>

      <form onSubmit={editStudent}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Nom et prénom"
              required
              fullWidth
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              type="text"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DesktopDatePicker
              label="Date de naissance"
              required
              value={moment(formData.birthday, "yyyy-MM-DD")}
              inputFormat="yyyy-MM-DD"
              renderInput={(params) => (
                <TextField required fullWidth {...params} />
              )}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  birthday: moment(e).format("yyyy-MM-DD"),
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Adresse"
              required
              fullWidth
              value={formData.address}
              onChange={(e) =>
                setFormData({ ...formData, address: e.target.value })
              }
              type="text"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Sexe</InputLabel>
              <Select
                label="Sexe"
                value={formData.sex}
                required
                onChange={(e) => {
                  setFormData({ ...formData, sex: e.target.value });
                }}
                fullWidth
              >
                <MenuItem value="Masculin">Masculin</MenuItem>
                <MenuItem value="Féminin">Féminin</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth variant="outlined">
              <MuiTelInput
                id="phone"
                label="Téléphone"
                name="phone"
                className="fullwidth"
                focusOnSelectCountry={true}
                defaultCountry="TN"
                value={formData.phone}
                onChange={(value) => setFormData({ ...formData, phone: value })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Email"
              autoComplete="new-password"
              required
              fullWidth
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              type="email"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Mot de passe"
              variant="outlined"
              fullWidth
              value={formData.password}
              type={passwordType}
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setPasswordType((prevType) =>
                          prevType === "password" ? "text" : "password"
                        );
                      }}
                      edge="end"
                    >
                      {passwordType === "password" ? (
                        <RemoveRedEyeIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                value={
                  formData.classroom &&
                  formData.classroom.season &&
                  formData.classroom.season._id !== currentSeason._id
                    ? ""
                    : formData.classroom
                    ? {
                        label:
                          classrooms.find(
                            (c) =>
                              c._id === formData.classroom._id ||
                              c._id === formData.classroom
                          )?.level +
                          " " +
                          classrooms.find(
                            (c) =>
                              c._id === formData.classroom._id ||
                              c._id === formData.classroom
                          )?.group,
                      }
                    : ""
                }
                options={classrooms
                  .filter((c) => c.students < c.capacity)
                  .map((c) => {
                    return { ...c, label: c.level + " " + c.group };
                  })}
                onChange={(e, val) => {
                  setFormData({
                    ...formData,
                    classroom: val !== null ? val._id : "",
                  });
                }}
                renderInput={(params) => (
                  <TextField label="Classe" required fullWidth {...params} />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end">
            {loadingForm ? (
              <CircularProgress size="35px" />
            ) : (
              <>
                <Button type="submit" variant="contained">
                  Modifier
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    setFormData({
                      name: "",
                      email: "",
                      password: "",
                      phone: "",
                      address: "",
                      classroom: "",
                      sex: "",
                      birthday: "",
                      establishment: establishment._id,
                    });
                    setAction("");
                  }}
                  variant="outlined"
                  style={{ marginLeft: "10px" }}
                >
                  Annuler
                </Button>
              </>
            )}
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default EditStudent;
