import {
  Button,
  CircularProgress,
  IconButton,
  Dialog,
  Pagination,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getError } from "../../../config/config";
import styles from "../../../styles/admin/Dashboard.module.css";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import Layout from "../components/Layout";
import AddTeacher from "./components/AddTeacher";
import EditTeacher from "./components/EditTeacher";
import ActivateTeacher from "./components/ActivateTeacher";
import BlockTeacher from "./components/BlockTeacher";
import NotifyTeacher from "./components/NotifyTeacher";
import SettingsIcon from "@mui/icons-material/Settings";
import CheckIcon from "@mui/icons-material/Check";
import BlockIcon from "@mui/icons-material/Block";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { useNavigate } from "react-router-dom";
import Messenger from "../components/Messenger";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

function Students(props) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [teachers, setTeachers] = useState([]);
  const [teacher, setTeacher] = useState({});
  const [selected, setSelected] = useState("");
  const [action, setAction] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const { currentSeason } = useSelector((state) => state.dashboard);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    sex: "",
    hourlyRate: "",
    establishment: userInfo.establishment._id,
  });

  const [notification, setNotification] = useState({
    sender:  userInfo.establishment._id,
    senderAccount:  userInfo.user._id,
    receiver: teacher._id,
    subject: "",
    message: "",
    documents: [],
  });

  const resetNotif = () => {
    setNotification({
      sender:  userInfo.establishment._id,
      senderAccount:  userInfo.user._id,
      receiver: teacher._id,
      subject: "",
      message: "",
      documents: [],
    });
  };

  const resetTeacher = () => {
    setFormData({
      name: "",
      email: "",
      password: "",
      phone: "",
      sex: "",
      hourlyRate: "",
      establishment: userInfo.establishment._id,
    });
  };

  const fetchTeachers = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(`/teacher/get`, {
        id: userInfo.establishment._id,
        page: page,
        phone: phone,
        name: name,
      });
      setTeachers(data.teachers);
      setCount(data.count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  const addTeacher = async (e) => {
    setLoadingForm(true);
    e.preventDefault();
    try {
      const { data } = await axios.post("/teacher/add", formData);
      enqueueSnackbar(data.message, { variant: "success" });
      setFormData({
        name: "",
        email: "",
        password: "",
        phone: "",
        sex: "",
        hourlyRate: "",
        establishment: userInfo.establishment._id,
      });
      fetchTeachers();
      setLoadingForm(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  const editTeacher = async (e) => {
    setLoadingForm(true);
    e.preventDefault();
    try {
      const { data } = await axios.put("/teacher/edit", formData);
      enqueueSnackbar(data.message, { variant: "success" });
      setFormData({
        name: "",
        email: "",
        password: "",
        phone: "",
        sex: "",
        hourlyRate: "",
        establishment: userInfo.establishment._id,
      });
      fetchTeachers();
      setLoadingForm(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  const activateTeacher = async (e) => {
    setLoadingForm(true);
    e.preventDefault();
    try {
      const { data } = await axios.put(`/teacher/activate/${teacher._id}`);
      enqueueSnackbar(data.message, { variant: "success" });
      fetchTeachers();
      setLoadingForm(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  const blockTeacher = async (e) => {
    setLoadingForm(true);
    e.preventDefault();
    try {
      const { data } = await axios.put(`/teacher/block/${teacher._id}`);
      enqueueSnackbar(data.message, { variant: "success" });
      fetchTeachers();
      setLoadingForm(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  const notifyTeacher = async (e) => {
    setLoadingForm(true);
    e.preventDefault();
    try {
      const { data } = await axios.post("/notification/send", notification);
      enqueueSnackbar(data.message, { variant: "success" });
      setNotification({
        sender:  userInfo.establishment._id,
        senderAccount:  userInfo.user._id,
        receiver: "",
        subject: "",
        message: "",
        documents: [],
      });
      setSelected("");
      setLoadingForm(false);
      setAction("");
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoadingForm(false);
    }
  };

  useEffect(() => {
    if (userInfo) fetchTeachers();
  }, [userInfo, page, name, phone]);

  return (
    <Layout>
      <Dialog
        maxWidth="md"
        open={action !== ""}
        onClose={() => {
          setAction("");
          resetTeacher();
          resetNotif();
        }}
      >
        <div
          className={
            action === "BLOCK" ||
            action === "ACTIVATE" ||
            action === "MESSAGE" ||
            action === "NOTIFY"
              ? "modal small"
              : "modal medium"
          }
          style={
            action === "NOTIFY" ? { top: "10vh", maxHeight: "100%" } : null
          }
        >
          {action === "ADD" ? (
            <AddTeacher
              loadingForm={loadingForm}
              formData={formData}
              setFormData={setFormData}
              setAction={setAction}
              addTeacher={addTeacher}
              establishment={userInfo.establishment}
              resetTeacher={resetTeacher}
            />
          ) : action === "EDIT" ? (
            <EditTeacher
              loadingForm={loadingForm}
              formData={formData}
              setFormData={setFormData}
              setAction={setAction}
              editTeacher={editTeacher}
              teacher={teacher}
              establishment={userInfo.establishment}
              resetTeacher={resetTeacher}
            />
          ) : action === "ACTIVATE" ? (
            <ActivateTeacher
              loadingForm={loadingForm}
              setAction={setAction}
              activateTeacher={activateTeacher}
              teacher={teacher}
            />
          ) : action === "BLOCK" ? (
            <BlockTeacher
              loadingForm={loadingForm}
              setAction={setAction}
              blockTeacher={blockTeacher}
              teacher={teacher}
            />
          ) : action === "NOTIFY" ? (
            <NotifyTeacher
              loadingForm={loadingForm}
              setAction={setAction}
              notifyTeacher={notifyTeacher}
              teacher={teacher}
              notification={notification}
              setNotification={setNotification}
              selected={selected}
              setSelected={setSelected}
              resetNotif={resetNotif}
            />
          ) : action === "MESSAGE" ? (
            <Messenger setAction={setAction} receiver={teacher.user} />
          ) : null}
        </div>
      </Dialog>
      <div className={styles.container}>
        <section className={styles.header}>
          <h1>Liste des enseignants</h1>
          {currentSeason && (
            <Button
              onClick={() => {
                setAction("ADD");
              }}
              variant="contained"
            >
              ajouter
            </Button>
          )}
        </section>
        {currentSeason && (
          <section className={styles.filter}>
            <div
              style={{ width: "20%", marginRight: "10px" }}
              className="labeledInput"
            >
              <label>Nom / Prénom</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="grayInput"
              />
            </div>
            <div
              style={{ width: "20%", marginRight: "10px" }}
              className="labeledInput"
            >
              <label>Téléphone</label>
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="grayInput"
              />
            </div>
            {name !== "" || phone !== "" ? (
              <Button
                onClick={() => {
                  setName("");
                  setPhone("");
                }}
                size="large"
                variant="outlined"
              >
                <RotateLeftIcon />
              </Button>
            ) : null}
          </section>
        )}
        {loading ? (
          <div className="spinner">
            <CircularProgress />
          </div>
        ) : (
          <div className={styles.tableContainer}>
            <table className="defaultTable">
              <thead>
                <tr>
                  <th>Nom et prénom</th>
                  <th>Email</th>
                  <th>Téléphone</th>
                  <th>Taux horaire</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {teachers.map((teacher) => {
                  {
                    return (
                      <tr key={teacher._id}>
                        <td>{teacher.user.name}</td>
                        <td>{teacher.user.email}</td>
                        <td>{teacher.user.phone}</td>
                        <td>{teacher.hourlyRate} DT</td>
                        <td style={{ width: "25%" }}>
                          <Tooltip title="modifier">
                            <IconButton
                              onClick={() => {
                                setTeacher(teacher);
                                setFormData({
                                  ...teacher,
                                  ...teacher.user,
                                  _id: teacher._id,
                                });
                                setAction("EDIT");
                              }}
                              sx={{ color: "#283487" }}
                            >
                              <SettingsIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="envoyer message">
                            <IconButton
                              onClick={() => {
                                setTeacher(teacher);
                                setAction("MESSAGE");
                              }}
                              sx={{ color: "#0058A5" }}
                            >
                              <LocalPostOfficeIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="fiche">
                            <IconButton
                              onClick={() => {
                                navigate(`/teachers/card/${teacher._id}`);
                              }}
                              sx={{ color: "#0077B4" }}
                            >
                              <FindInPageIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="emploi de travail">
                            <IconButton
                              onClick={() => {
                                navigate(
                                  `/teachers/workschedule/${teacher._id}`
                                );
                              }}
                              sx={{ color: "#0077B4" }}
                            >
                              <CalendarMonthIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="notifier">
                            <IconButton
                              onClick={() => {
                                setTeacher(teacher);
                                setNotification({
                                  ...notification,
                                  receiver: teacher._id,
                                });
                                setAction("NOTIFY");
                              }}
                              sx={{ color: "#0095B6" }}
                            >
                              <NotificationsActiveIcon />
                            </IconButton>
                          </Tooltip>
                          {teacher.isActive ? (
                            <Tooltip title="bloquer">
                              <IconButton
                                onClick={() => {
                                  setTeacher(teacher);
                                  setAction("BLOCK");
                                }}
                                sx={{ color: "#00B0B0" }}
                              >
                                <BlockIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="activer">
                              <IconButton
                                onClick={() => {
                                  setTeacher(teacher);
                                  setAction("ACTIVATE");
                                }}
                                sx={{ color: "#00B0B0" }}
                              >
                                <CheckIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          </div>
        )}
        {currentSeason && (
          <section className={styles.pagination}>
            <Pagination
              onChange={(e, page) => setPage(page)}
              page={page}
              count={count}
            />
          </section>
        )}
      </div>
    </Layout>
  );
}

export default Students;
