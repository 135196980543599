import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import styles from "../../../styles/admin/TeacherCard.module.css";
import { IconButton, Tooltip } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getError } from "../../../config/config";
import { CircularProgress } from "@mui/material";
import moment from "moment";
const TeacherNotifications = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [notifications, setNotifications] = useState([]);
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [loadingNotif, setLoadingNotif] = useState(true);
  const fetchNotifications = async () => {
    try {
      const { data } = await axios.get(`/notification/get/${userInfo._id}`);
      dispatch({ type: "UPDATE_NOTIFICATIONS", payload: data.count });
      const { notifications } = data;
      setNotifications(notifications);
      setLoadingNotif(false);
    } catch (error) {
      setLoadingNotif(false);
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  // const deleteNotification = async (id) => {
  //   try {
  //     const { data } = await axios.delete(`/notification/delete/${id}`);
  //     fetchNotifications();
  //     dispatch({ type: "UPDATE_NOTIFICATIONS", payload: data.count});
  //     enqueueSnackbar(data.message, { variant: "success" });
  //   } catch (error) {
  //     enqueueSnackbar(getError(error), { variant: "error" });
  //   }
  // }

  function download(notification) {
    const type = notification.documents[0].split(",")[0];
    const file = notification.documents[0].split(",")[1];
    const linkSource = `${type},${file}`;
    const downloadLink = document.createElement("a");
    const fileName = file;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  useEffect(() => {
    fetchNotifications();
  }, []);
  return (
    <Layout>
      {/* add a table of notifications  */}

      <div className={styles.container}>
        <h2>Notifications</h2>
        {loadingNotif ? (
          <div className="spinner">
            <CircularProgress size="30px" />
          </div>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Sujet</th>
                  <th>Message</th>
                  <th>Document</th>
                </tr>
              </thead>
              <tbody>
                {notifications.length > 0 ? (
                  notifications.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>{moment(row?.createdAt).format("DD-MM-yyyy")}</td>
                        <td>{row?.subject}</td>
                        <td>{row?.message}</td>

                        {row?.documents.length > 0 ? (
                          <td>
                            {row.documents[0].split(",")[0] ===
                              "data:application/pdf;base64" && (
                              <>
                                <span>Télecharger le PDF</span>
                                <Tooltip title="Télecharger le PDF">
                                  <IconButton
                                    onClick={() => download(row)}
                                    style={{ color: "#1E90FF" }}
                                  >
                                    <DownloadIcon />
                                  </IconButton>
                                </Tooltip>
                              </>
                            )}
                            {(row.documents[0].split(",")[0] ===
                              "data:image/png;base64" ||
                              row.documents[0].split(",")[0] ===
                                "data:image/jpeg;base64" ||
                              row.documents[0].split(",")[0] ===
                                "data:image/jpg;base64") && (
                              <>
                                <span>Télecharger l'image</span>
                                <Tooltip title="Télecharger l'image ">
                                  <IconButton
                                    onClick={() => download(row)}
                                    style={{ color: "#1E90FF" }}
                                  >
                                    <DownloadIcon />
                                  </IconButton>
                                </Tooltip>
                              </>
                            )}
                          </td>
                        ) : (
                          <td>_</td>
                        )}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="4">Aucune notification</td>
                  </tr>
                )}
              </tbody>
            </table>
          </>
        )}
      </div>
    </Layout>
  );
};

export default TeacherNotifications;
