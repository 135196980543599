import { Button, CircularProgress } from "@mui/material";
import React from "react";

function DeleteNote(props) {
  const { deleteNote, setAction, note, setNote, loadingForm } = props;

  return (
    <form onSubmit={deleteNote}>
      <h1>Supprimer la note ({note.type}) ?</h1>
      <br />
      {loadingForm ? (
        <CircularProgress size="32px" />
      ) : (
        <div className="btnRow">
          <Button variant="contained" type="submit">
            supprimer
          </Button>
          &nbsp;
          <Button
            variant="outlined"
            type="button"
            onClick={() => {
              setAction("");
              setNote({});
            }}
          >
            annuler
          </Button>
        </div>
      )}
    </form>
  );
}

export default DeleteNote;
