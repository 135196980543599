import { Button, CircularProgress } from "@mui/material";
import React from "react";

function BlockTeacher(props) {
  const { loadingForm, setAction, blockTeacher, teacher } = props;

  return (
    <form onSubmit={blockTeacher}>
      <h1>Bloquer l&apos;enseignant:&nbsp;{teacher.user.name}</h1>
      <div className="row">
        {loadingForm ? (
          <CircularProgress size="35px" />
        ) : (
          <>
            <Button type="submit" variant="contained">
              bloquer
            </Button>
            &nbsp;
            <Button
              type="button"
              onClick={() => {
                setAction("");
              }}
              variant="outlined"
            >
              Annuler
            </Button>
          </>
        )}
      </div>
    </form>
  );
}

export default BlockTeacher;
