import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import React from "react";
import { getDay } from "../../../../../../config/config";

function AddSession(props) {
  const {
    addSession,
    setAction,
    session,
    setSession,
    resetSession,
    subjects,
    rooms,
    loadingForm,
  } = props;
  return (
    <form onSubmit={addSession}>
      <h1>
        Ajouter une séance le {getDay(session.dayIndex)} de{" "}
        {session.timing.split("-")[0]} à {session.timing.split("-")[1]}
      </h1>
      <div className="labeledInput">
        <label>Matière</label>
        <Autocomplete
          size="small"
          style={{
            width: "100%",
            padding: "0px",
          }}
          value={subjects.find((s) => s._id === session?.subject)}
          options={subjects.map((s) => {
            return { ...s, label: s?.name };
          })}
          onChange={(e, val) => {
            setSession({
              ...session,
              teacher: subjects.find((s) => s._id === val._id).teacher._id,
              subject: val !== null ? val._id : "",
            });
          }}
          renderInput={(params) => (
            <TextField
              required={true}
              className="defaultInput"
              size="small"
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "5px !important",
                  border: "1px solid #283487 !important",
                },
              }}
              {...params}
            />
          )}
        />
      </div>
      <br />
      <div className="labeledInput">
        <label>Salle</label>
        <Autocomplete
          size="small"
          style={{
            width: "100%",
            padding: "0px",
          }}
          value={rooms.find((r) => r._id === session?.room)}
          options={rooms.map((r) => {
            return { ...r, label: r?.name };
          })}
          onChange={(e, val) => {
            setSession({
              ...session,
              room: val !== null ? val._id : "",
            });
          }}
          renderInput={(params) => (
            <TextField
              required={true}
              className="defaultInput"
              size="small"
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderRadius: "5px !important",
                  border: "1px solid #283487 !important",
                },
              }}
              {...params}
            />
          )}
        />
      </div>
      <br />
      {loadingForm ? (
        <CircularProgress size="32px" />
      ) : (
        <div className="btnRow">
          <Button variant="contained" type="submit">
            Ajouter
          </Button>
          &nbsp;
          <Button
            variant="outlined"
            type="button"
            onClick={() => {
              setAction("");
              resetSession();
            }}
          >
            Annuler
          </Button>
        </div>
      )}
    </form>
  );
}

export default AddSession;
