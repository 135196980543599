import { CircularProgress, Pagination } from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getError } from "../../../../../config/config";
import styles from "../../../../../styles/admin/Dashboard.module.css";
import Layout from "../../../components/Layout";
import Menu from "../../components/Menu";

function ClassroomSubjectsStudent(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentClassroom } = useSelector((state) => state.dashboard);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [name, setName] = useState("");
  const [action, setAction] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const dispatch = useDispatch();

  const fetchSubjects = async () => {
    try {
      const { data } = await axios.post(`/subject/getbyclassroom`, {
        classroom: userInfo.classroom,
        page: page,
        name: name,
      });
      setSubjects(data.subjects);
      setCount(data.count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  // const fetchMyClassRoom = async () => {
  //   try {
  //     const { data } = await axios.get(`/student/getmyclassroom/${userInfo.user._id}`);
  //     dispatch({ type: "SET_CURRENT_CLASSROOM", payload: data });
  //   } catch (error) {
  //     enqueueSnackbar(getError(error), { variant: "error" });
  //   }
  // }

  // const fetchNotifications = async () => {
  //   try {
  //     const { data } = await axios.get(`/notification/get/${userInfo._id}`);
  //     dispatch({ type: "UPDATE_NOTIFICATIONS", payload: data.count });
  //   } catch (error) {
  //     enqueueSnackbar(getError(error), { variant: "error" });
  //   }
  // };

  // useEffect(() => {
  //   fetchMyClassRoom();
  //   fetchNotifications();
  // }, []);

  const fetchTeachers = async () => {
    try {
      const { data } = await axios.get(
        `/teacher/getbyestablishment/${userInfo.establishment._id}`
      );
      setTeachers(data);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  useEffect(() => {
    fetchSubjects();
    fetchTeachers();
  }, [page, name]);

  return (
    <Layout>
      <Menu />
      <div style={{ marginTop: "50px" }} className={styles.container}>
        <section className={styles.header}>
          <h1>Liste des matières</h1>
        </section>
        {loading ? (
          <div className="spinner">
            <CircularProgress />
          </div>
        ) : (
          <div
            style={{ maxHeight: "calc(100vh - 401px)" }}
            className={styles.tableContainer}
          >
            <table className="defaultTable">
              <thead>
                <tr>
                  <th>Nom</th>
                  <th>Coefficient</th>
                  <th>Enseignant</th>
                </tr>
              </thead>
              <tbody>
                {subjects.map((subject) => {
                  return (
                    <tr key={subject._id}>
                      <td>{subject.name}</td>
                      <td>{subject.coefficient}</td>
                      <td>{subject.teacher.user.name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        <section className={styles.pagination}>
          <Pagination
            onChange={(e, page) => setPage(page)}
            page={page}
            count={count}
          />
        </section>
      </div>
    </Layout>
  );
}

export default ClassroomSubjectsStudent;
