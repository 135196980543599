import { CircularProgress,  Modal, Tooltip } from "@mui/material";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "../../../styles/admin/TeacherCard.module.css";
import Layout from "../components/Layout";
import Receipt from "../../admin/teachers/components/Receipt";

const TeacherFinance = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useSelector((state) => state.auth);
  const { currentSeason } = useSelector((state) => state.dashboard);
  const [teacher, setTeacher] = useState({});
  const [withdrawals, setWithdrawals] = useState([]);
  const [loadingCard, setLoadingCard] = useState(true);
  const [label, setLabel] = useState("");
  const [loadingWithdrawals, setLoadingWithdrawals] = useState(true);
  const [action, setAction] = useState("");
  const [withdrawal, setWithdrawal] = useState({
    establishment: userInfo.establishment._id,
    season: "",
    admin: userInfo._id,
    teacher: userInfo._id,
    label: "",
    monthIndex: 0,
    amount: 0,
    method: "",
    multiple: 0,
  });

  const fetchTeacher = async () => {
    try {
      const { data } = await axios.get(`/teacher/getbyid/${userInfo._id}`);
      setTeacher(data);
      setLoadingCard(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setLoadingCard(false);
    }
  };

  const fetchWithdrawals = async () => {
    try {
      const { data } = await axios.get(
        `/withdrawal/getbyteacher/${userInfo?.establishment._id}/${currentSeason?._id}/${userInfo._id}`
      );
      setWithdrawals(data);
      setLoadingWithdrawals(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setLoadingWithdrawals(false);
    }
  };
  const resetWithdrawal = () => {
    setWithdrawal({
      establishment: userInfo.establishment._id,
      season: currentSeason._id,
      teacher: userInfo._id,
      label: "",
      monthIndex: 0,
      amount: 0,
      method: "",
      multiple: 0,
    });
  };

  const renderWithdrawals = (row) => {
    var block = [];
    for (let i = 0; i < 12; i++) {
      if (row.withdrawals.find((w) => w.monthIndex === i)?.amount) {
        block = [
          ...block,
          <Tooltip key={i} title="imprimer reçu">
            <td
              onClick={() => {
                setWithdrawal(row.withdrawals.find((w) => w.monthIndex === i));
                setAction("SHOW_RECEIPT");
              }}
              className={styles.cell}
            >
              <div>
                {row.withdrawals.find((w) => w.monthIndex === i).amount} DT
              </div>
              <div>
                {row.withdrawals.find((w) => w.monthIndex === i).method}
              </div>
            </td>
          </Tooltip>,
        ];
      } else {
        block = [
          ...block,
          <td key={i} value={i}>
          </td>,
        ];
      }
    }
    return block;
  };
  useEffect(() => {
    fetchTeacher();
  }, []);

  useEffect(() => {
    fetchWithdrawals();
  }, [currentSeason]);
  return (
    <>
      <Layout>
        <Modal
          open={action !== ""}
          onClose={() => {
            setAction("");
            setLabel("");
          }}
        >
          <div
            className={
              action === "SHOW_RECEIPT" ? "modal medium" : "modal small"
            }
          >
            {action === "SHOW_RECEIPT" ? (
              <Receipt
                setAction={setAction}
                setWithdrawal={setWithdrawal}
                withdrawal={withdrawal}
                resetWithdrawal={resetWithdrawal}
                teacher={teacher}
              />
            ) : null}
          </div>
        </Modal>
        <div className={styles.container}>
          <h2>Prestation</h2>
          {loadingWithdrawals ? (
            <div className="spinner">
              <CircularProgress size="30px" />
            </div>
          ) : (
            <>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>jan</th>
                    <th>fév</th>
                    <th>mar</th>
                    <th>avr</th>
                    <th>mai</th>
                    <th>juin</th>
                    <th>juil</th>
                    <th>août</th>
                    <th>sept</th>
                    <th>oct</th>
                    <th>nov</th>
                    <th>déc</th>
                  </tr>
                </thead>
                <tbody>
                  {withdrawals.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td
                          style={{
                            backgroundColor: "#fdcf1b",
                            color: "#000000",
                          }}
                        >
                          {row.label}
                        </td>
                        {renderWithdrawals(row)}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          )}
        </div>
      </Layout>
    </>
  );
};

export default TeacherFinance;
