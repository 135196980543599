import React, { useState } from "react";
import styles from "../../../styles/teacher/Dashboard.module.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import axios from "axios";
import { getError } from "../../../config/config";

function Menu(props) {
  const { currentClassroom, sidemenu } = useSelector(
    (state) => state.dashboard
  );
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("");
  const [formData, setFormData] = useState({
    sender:  userInfo.establishment._id,
    senderAccount:  userInfo.user._id,
    receiver: currentClassroom?._id,
    subject: "",
    message: "",
    documents: [],
  });
  const { subject, message } = formData;

  const send = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const { data } = await axios.post("/notification/send", formData);
      enqueueSnackbar(data.message, { variant: "success" });
      setFormData({
        sender:  userInfo.establishment._id,
        senderAccount:  userInfo.user._id,
        receiver: currentClassroom?._id,
        subject: "",
        message: "",
        documents: [],
      });
      setSelected("");
      setLoading(false);
      setOpen(false);
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
      setLoading(false);
    }
  };

  return (
    <>
      <div
        style={{
          transition: "all 0.3s",
          width: sidemenu ? "calc(80% - 40px)" : "calc(100% - 94px)",
        }}
        className={styles.menu}
      >
        <Link
          className={
            window.location.pathname === "/gestion-scolaire"
              ? styles.active
              : null
          }
          to="/gestion-scolaire"
        >
          <CalendarMonthIcon />
          &nbsp;<p className={styles.option}>emploi de temps</p>
        </Link>
      
      </div>
    </>
  );
}

export default Menu;
