import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Modal, Skeleton, Tooltip } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { getError } from "../../../../../config/config";
import styles from "../../../../../styles/admin/Schedule.module.css";
import Layout from "../../../components/Layout";
import Menu from "../../components/Menu";
import PrintIcon from "@mui/icons-material/Print";

function StudyScheduleStudent(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState([]);
  const printRef = useRef(null);
  const dispatch = useDispatch();

  const fetchNotifications = async () => {
    try {
      const { data } = await axios.get(`/notification/get/${userInfo._id}`);
      dispatch({ type: "UPDATE_NOTIFICATIONS", payload: data.count });
    } catch (error) {
      enqueueSnackbar(getError(error), { variant: "error" });
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const renderRow = (row) => {
    let block = [
      <td key={row.timing} style={{ backgroundColor: "#F5F3F3" }}>
        {row.timing}
      </td>,
    ];
    for (let i = 0; i < 6; i++) {
      if (row.sessions.find((s) => s.dayIndex === i)?.room) {
        block = [
          ...block,
          <Tooltip
            key={row.timing + i}
            title={
              !row.sessions.find((s) => s.dayIndex === i)?.room.available
                ? "salle non disponible"
                : null
            }
          >
            <td
              style={
                !row.sessions.find((s) => s.dayIndex === i)?.room.available
                  ? { backgroundColor: "#fff0f0" }
                  : null
              }
              className={styles.cell}
            >
              <p>
                matière:&nbsp;
                {row.sessions.find((s) => s.dayIndex === i)?.subject?.name}
              </p>
              <p>
                salle:&nbsp;
                {row.sessions.find((s) => s.dayIndex === i)?.room?.name}
              </p>
              <p>
                enseignant
                {row.sessions.find((s) => s.dayIndex === i)?.subject?.teacher
                  .sex === "Féminin"
                  ? "e"
                  : ""}
                :&nbsp;
                {
                  row.sessions.find((s) => s.dayIndex === i)?.subject?.teacher
                    .user.name
                }
              </p>
            </td>
          </Tooltip>,
        ];
      } else {
        block = [
          ...block,
          <td key={i}>
            <div className={styles.cellContainer}></div>
          </td>,
        ];
      }
    }

    return block;
  };

  const fetchSessions = async () => {
    try {
      const { data } = await axios.get(
        `session/get/${userInfo.classroom}/${userInfo.user._id}`
      );
      if (data !== null) setSessions(data);
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSessions();
  }, []);

  return (
    <Layout>
      <Menu />
      <div className={styles.container}>
        {loading ? (
          <Skeleton variant="rectangular" width="100%">
            <div style={{ height: "calc(100vh - 200px)" }} />
          </Skeleton>
        ) : (
          <div className={styles.tableContainer}>
            <div ref={printRef} className="printBody">
              <table className="scheduleTable">
                <thead>
                  <tr>
                    <th>
                      <div className="dontprint">
                        <ReactToPrint
                          trigger={() => (
                            <IconButton variant="contained">
                              <PrintIcon color="primary" />
                            </IconButton>
                          )}
                          content={() => printRef.current}
                        />
                      </div>
                    </th>
                    <th>lundi</th>
                    <th>mardi</th>
                    <th>mercredi</th>
                    <th>jeudi</th>
                    <th>vendredi</th>
                    <th>samedi</th>
                  </tr>
                </thead>
                <tbody>
                  {sessions.map((row, index) => {
                    return <tr key={index}>{renderRow(row)}</tr>;
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default StudyScheduleStudent;
